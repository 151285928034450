<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="donut" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        labels: Object.entries(this.data).sort((a, b) => {
          const keyA = a[0].toLowerCase();
          const keyB = b[0].toLowerCase();
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }).map(([dataSet, d]) => this.comparisonNames[dataSet]),
        colors: Object.values(this.comparisonColors),
        dataLabels: {
          enabled: true,
          dropshadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 0,
            opacity: 0
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: true
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      };
    },

    series() {
      let series = [];
      if (!isEmpty(this.data)) {
        series = Object.entries(this.data).sort((a, b) => {
          const keyA = a[0].toLowerCase();
          const keyB = b[0].toLowerCase();
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }).map(([dataSet, d]) => d);
      }
      return series;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
