<template>
  <v-card outlined>
    <v-card-title class="headline">{{ poi.name }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <choropleth-map @level="setActiveLevel" @iso="setActiveIso" />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <data-fetcher height="350" report="getPoiSummary" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <poi-summary-totals :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <data-fetcher height="350" report="getDailyPoiTrend" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <trend-daily height="350" title="Daily Visitation Pattern" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <data-fetcher height="350" report="getWeekdayHoursByPoi" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <weekday-heatmap height="350" title="Hourly Visitation Heatmap" :data="data" />
          </data-fetcher>
        </v-col>
        <v-col sm="6">
          <data-fetcher height="350" report="getPoiVisitDuration" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <visit-duration-chart height="350" title="Visit Duration" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <data-fetcher height="350" report="getPoiTripMatrix" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <trip-chart title="Visitor Flow" :width="1200" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6">
          <data-fetcher height="350" report="getEthnicityByPoi" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <ethnicity-chart title="Ethnicity" height="250" :data="data" />
          </data-fetcher>
        </v-col>
        <v-col sm="6">
          <data-fetcher height="350" report="getGenderAgeByPoi" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <gender-age-chart title="Gender & Age" height="250" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <data-fetcher height="350" report="getIncomeByPoi" :filter="{ poiIds: [poi.id], studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <income-chart title="Income" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DataFetcher from '@/components/charts/DataFetcher';
import TrendDaily from '@/components/charts/TrendDaily';
import WeekdayHeatmap from '@/components/charts/WeekdayHeatmap';
import EthnicityChart from '@/components/charts/EthnicityChart';
import GenderAgeChart from '@/components/charts/GenderAgeChart';
import IncomeChart from '@/components/charts/IncomeChart';
import VisitDurationChart from '@/components/charts/VisitDurationChart';
import ChoroplethMap from '@/components/charts/ChoroplethMap';
import TripChart from '@/components/charts/TripChart';
import PoiSummaryTotals from '@/components/studies/tla/reports/PoiSummaryTotals';

export default {
  props: {
    poi: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    DataFetcher,
    TrendDaily,
    WeekdayHeatmap,
    EthnicityChart,
    GenderAgeChart,
    IncomeChart,
    VisitDurationChart,
    TripChart,
    ChoroplethMap,
    PoiSummaryTotals
  },

  computed: {
    ...mapState('visualize', ['dates', 'study', 'activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['pois', 'labels', 'filteredDateRange', 'filteredHomeLocations'])
  },

  methods: mapActions('visualize', ['setActiveIso', 'setActiveLevel'])
};
</script>
