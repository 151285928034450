<template>
  <div class="map-container" :style="{ width, height }" @keydown.esc="clear()">
    <div id="map"></div>
    <v-fab-transition>
      <v-btn class="clear-map" color="white" absolute right top fab small elevation="2" @click="clear()" v-show="!readOnly && hasDrawn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-scale-transition>
      <div id="geocoder" v-show="!readOnly && !hasDrawn"></div>
    </v-scale-transition>
  </div>
</template>

<script>
import MapboxGL from 'mapbox-gl';
import { MapboxLayer } from '@deck.gl/mapbox';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { bbox, featureCollection } from '@turf/turf';
import isEmpty from 'lodash/isEmpty';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { DrawPolygonMode, ModifyMode, ViewMode } from '@nebula.gl/edit-modes';

const INITIAL_VIEW_STATE = {
  latitude: 39.94,
  longitude: -95.52,
  zoom: 3.5,
  bearing: 0,
  pitch: 0
};

MapboxGL.accessToken = 'pk.eyJ1Ijoiamhhd2x3dXQiLCJhIjoiY2prMno4Mjl5MGkwZDNxcXFxNHgyY2ZiNSJ9.s4lvKHjuqpsTHyN323qOAQ';

let layer;

export default {
  name: 'Johannes',

  props: {
    width: {
      type: [String, Number],
      default: '100%'
    },

    height: {
      type: [String, Number],
      default: '100%'
    },

    value: {
      type: Object,
      default: () => {}
    },

    modifying: {
      type: Boolean,
      default: false
    },

    readOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ready: false,
      map: null,
      hasDrawn: false,
      geojson: {
        type: 'FeatureCollection',
        features: []
      }
    };
  },

  watch: {
    value() {
      if (!this.ready && this.modifying) {
        if (!isEmpty(this.value) && this.value.type === 'Feature') {
          this.geojson.features.push(this.value);
          this.init();
        }
      } else if (!this.ready) {
        this.init();
      }
    }
  },

  mounted() {
    if (!this.ready && this.modifying) {
      if (!isEmpty(this.value) && this.value.type === 'Feature') {
        this.geojson.features.push(this.value);
        this.init();
      }
    } else if (!this.ready) {
      this.init();
    }
  },

  methods: {
    async init() {
      const { latitude, longitude, zoom, bearing, pitch } = INITIAL_VIEW_STATE;
      await this.$nextTick();
      layer = this.makeLayer();

      this.map = new MapboxGL.Map({
        container: 'map',
        style: 'https://api.maptiler.com/maps/daf8b0da-58d2-4f37-9563-1f59182f279b/style.json?key=Pt2TXgCtJ5ziGcCMTIfn',
        center: [longitude, latitude],
        zoom,
        bearing,
        pitch
      });

      this.map.on('load', () => {
        this.map.removeLayer('building-3d');
        this.map.addLayer(layer);
        if (this.geojson.features.length) {
          this.map.fitBounds(bbox(this.geojson), { padding: 25, easing: () => 1 });
          if (this.modifying && !this.readOnly) {
            this.startModing();
          }
        }
        this.map.__deck.props.getCursor = () => {
          const layer = this.map.__deck.props.layers.find(l => l.id === 'edit-geojson');
          if (layer) {
            const { state } = this.map.__deck.props.layers[0];
            if (state) {
              return state.cursor;
            }
          }
          return 'grab';
        };
        this.ready = true;
      });

      this.geocoder = new MapboxGeocoder({
        accessToken: MapboxGL.accessToken,
        mapboxgl: MapboxGL,
        countries: 'us, ca'
      }).onAdd(this.map);
      document.getElementById('geocoder').appendChild(this.geocoder);
    },

    makeLayer() {
      return new MapboxLayer({
        id: 'edit-geojson',
        type: EditableGeoJsonLayer,
        data: this.geojson,
        selectedFeatureIndexes: [],
        mode: this.readOnly ? ViewMode : DrawPolygonMode,
        onEdit: ({ updatedData, editType }) => {
          this.geojson = updatedData;
          if (editType === 'addFeature') {
            layer.setProps({
              data: updatedData,
              mode: ViewMode
            });
            this.startModing();
          } else {
            if (editType === 'movePosition') {
              this.map.dragPan.disable();
            }
            if (editType === 'finishMovePosition') {
              this.map.dragPan.enable();
            }
            layer.setProps({
              data: updatedData
            });
            this.hasDrawn = true;
          }
          if (this.geojson.features.length) {
            this.$emit('input', this.geojson.features[0]);
          }
        }
      });
    },

    clear(geojson) {
      if (this.map && this.map.getLayer('edit-geojson')) {
        this.map.removeLayer('edit-geojson');
        if (geojson) {
          this.geojson = geojson;
          if (this.geojson.features.length) {
            this.$emit('input', this.geojson.features[0]);
          }
        } else if (this.geojson.features.length) {
          this.$emit('clear');
          this.geojson = featureCollection([]);
        }
        layer = this.makeLayer();
        this.map.addLayer(layer);
        this.hasDrawn = false;
        if (this.modifying) {
          this.startModing();
        }
      }
    },

    reload(feature) {
      let initGeojson = this.geojson;
      if (feature) {
        initGeojson = featureCollection([feature]);
      }
      this.clear(initGeojson);
      if (this.geojson.features.length) {
        this.map.fitBounds(bbox(this.geojson), { padding: 25, easing: () => 1 });
      }
    },

    startModing() {
      if (this.geojson.features.length) {
        layer.setProps({
          selectedFeatureIndexes: [0],
          getFillColor: [0, 0, 0, 120],
          mode: ModifyMode
        });
        this.hasDrawn = true;
      }
    }
  }
};
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
}

.clear-map {
  transform: translate(7px, 30px);
}

#geocoder {
  position: absolute;
  top: 10px;
  left: 10px;
}

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
