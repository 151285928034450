import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { circle, getCoord, center, featureCollection, union } from '@turf/turf';
import { Popup, Marker } from 'mapbox-gl';
import { round25 } from '@/lib/utils';

class TradeDistanceWinnerManager {
  map;

  markers = [];

  constructor(map) {
    this.map = map;
  }

  removeLayer() {
    let layerId = 'tradeDistanceShapes';
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    layerId = 'tradeDistanceLines';
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource('POIS')) {
      this.map.removeSource('POIS');
    }
    this.markers.forEach((m) => m.remove());
    this.markers = [];
  }

  renderLayer(distances, pois, comparisonColors) {
    this.removeLayer();
    if (!isEmpty(distances)) {
      const circles = [];
      Object.entries(distances).forEach(([group, poisInGroup]) => {
        let groupCircles = [];
        Object.entries(poisInGroup).forEach(([poiId, distance]) => {
          const poi = pois.find((p) => p.id === poiId);
          if (poi) {
            const { geom, name, label } = poi;
            const centerOfPoi = getCoord(center(geom));
            groupCircles.push(circle(centerOfPoi, distance, { steps: 256, units: 'miles', properties: { group } }));
            const popup = new Popup({
              closeButton: false,
              closeOnClick: true
            }).setHTML(`
              <div class="v-card v-card--flat v-sheet">
                <div class="v-card__text pa-0">
                  <div class="headline">${name}</div>
                  <div class="subheading mb-1">${label}</div>
                  <div class="caption mb-1">Average of ${round25(distance)} mile${distance !== 1 ? 's' : ''} traveled to visit</div>
                </div>
              </div>
            `);
            this.markers.push(new Marker({ color: comparisonColors[group] }).setLngLat(centerOfPoi).setPopup(popup));
            if (groupCircles.length > 1) {
              groupCircles = [union(...groupCircles, { properties: { group } })];
            }
          }
        });
        circles.push(groupCircles);
      });

      const features = featureCollection(flatten(circles));
      this.map.addSource('POIS', {
        type: 'geojson',
        data: features,
        promoteId: 'id'
      });

      const expression = ['coalesce', ['get', ['get', 'group'], ['literal', comparisonColors]], '#FFFFFF'];
      const shapes = {
        id: 'tradeDistanceShapes',
        type: 'fill',
        source: 'POIS',
        paint: {
          'fill-color': expression,
          'fill-opacity': 0.25
        }
      };
      const lines = {
        id: 'tradeDistanceLines',
        type: 'line',
        source: 'POIS',
        paint: {
          'line-color': expression,
          'line-width': 1,
          'line-opacity': 0.8
        }
      };
      this.map.addLayer(shapes);
      this.map.addLayer(lines);
      this.markers.forEach(m => m.addTo(this.map));
      this.map.centerOn(features);
    }
  }
}

export default TradeDistanceWinnerManager;
