<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" eager max-height="550" offset-y offset-overflow>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="mr-3">
        <v-icon left>mdi-map</v-icon>
        <span>{{ selectedHomeLocationNames }}</span>
      </v-btn>
    </template>
    <div id="home-location-filter" class="home-location-select-list-container">
      <template v-if="activeIso === 'us'">
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('msa')">MSA</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('states')">States</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('counties')">Counties</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('tracts')">Tracts</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('blockGroups')">Block Groups</div>
        <study-home-location-select-list v-model="values.us.msa" :items="usMsaHl" v-show="visibleFilters.includes('msa')"/>
        <study-home-location-select-list v-model="values.us.state" :items="usStatesHl" :indeterminate="indeterminate.us.state" @activate="active.us.state = $event"  v-show="visibleFilters.includes('states')"/>
        <study-home-location-select-list v-model="values.us.county" :items="usCountiesHl" :indeterminate="indeterminate.us.county" @activate="active.us.county = $event" v-show="visibleFilters.includes('counties')"/>
        <study-home-location-select-list v-model="values.us.tract" :items="usTractsHl" :indeterminate="indeterminate.us.tract" @activate="active.us.tract = $event" v-show="visibleFilters.includes('tracts')"/>
        <study-home-location-select-list v-model="values.us.blockgroup" :items="usBlockGroupsHl" v-show="visibleFilters.includes('blockGroups')"/>
      </template>
      <template v-if="activeIso === 'ca'">
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('cma')">CMA</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('provinces')">Provinces</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('counties')">Counties</div>
        <div class="text-overline font-weight-bold px-5 py-1" v-show="visibleFilters.includes('disseminationAreas')">Dissemination Areas</div>
        <study-home-location-select-list v-model="values.ca.cma" :items="caCmaHl" v-show="visibleFilters.includes('cma')"/>
        <study-home-location-select-list v-model="values.ca.province" :items="caProvincesHl" :indeterminate="indeterminate.ca.province" @activate="active.ca.province = $event" v-show="visibleFilters.includes('provinces')"/>
        <study-home-location-select-list v-model="values.ca.county" :items="caCountiesHl" :indeterminate="indeterminate.ca.county" @activate="active.ca.county = $event" v-show="visibleFilters.includes('counties')"/>
        <study-home-location-select-list v-model="values.ca.disseminationarea" :items="caDisseminationAreasHl" v-show="visibleFilters.includes('disseminationAreas')"/>
      </template>
    </div>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import StudyHomeLocationSelectList from '@/components/StudyHomeLocationSelectList';

const pushUnique = (arr, values) => {
  const arr2 = arr.slice(0);
  arr2.push(...values);
  return uniq(arr2);
};

export default {
  components: {
    StudyHomeLocationSelectList
  },

  data() {
    return {
      menu: false,
      active: {
        us: {
          state: 0,
          county: 0,
          tract: 0
        },
        ca: {
          province: 0,
          county: 0
        }
      },
      values: {
        us: {
          state: [],
          county: [],
          tract: [],
          blockgroup: [],
          msa: []
        },
        ca: {
          province: [],
          county: [],
          disseminationarea: [],
          cma: []
        }
      },
      indeterminate: {
        us: {
          state: [],
          county: [],
          tract: []
        },
        ca: {
          province: [],
          county: []
        }
      }
    };
  },

  /* eslint-disable func-names */
  watch: {
    'values.us.state': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new state
          this.values.us.msa = [];
          const addedStates = difference(newValue, oldValue);
          addedStates.forEach((addedState) => {
            addedState = get(this.homeLocations, 'us.states', []).find(s => s.fips === addedState);
            this.values.us.county = pushUnique(this.values.us.county, (addedState.counties || []).map(c => c.fips));
            if (this.study.product === 'tla') {
              (addedState.counties || []).forEach((county) => {
                this.values.us.tract = pushUnique(this.values.us.tract, (county.tracts || []).map(t => t.fips));
                (county.tracts || []).forEach((tract) => {
                  this.values.us.blockgroup = pushUnique(this.values.us.blockgroup, (tract.blockGroups || []).map(bg => bg.fips));
                });
              });
            }
          });
        } else if (newValue.length < oldValue.length) {
          // removed a state
          const removedStates = difference(oldValue, newValue);
          removedStates.forEach((removedState) => {
            if (this.study.product === 'tla') {
              this.values.us.blockgroup = this.values.us.blockgroup.filter(bg => bg.slice(0, 2) !== removedState);
              this.values.us.tract = this.values.us.tract.filter(t => t.slice(0, 2) !== removedState);
            }
            this.values.us.county = this.values.us.county.filter(c => c.slice(0, 2) !== removedState);
          });
        }
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.us.county': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new county
          this.values.us.msa = [];
          if (this.study.product === 'tla') {
            const addedCounties = difference(newValue, oldValue);
            addedCounties.forEach((addedCounty) => {
              const state = get(this.homeLocations, 'us.states', []).find(s => s.fips === addedCounty.slice(0, 2));
              addedCounty = state.counties.find(c => c.fips === addedCounty);
              this.values.us.tract = pushUnique(this.values.us.tract, (addedCounty.tracts || []).map(t => t.fips));
              (addedCounty.tracts || []).forEach((tract) => {
                this.values.us.blockgroup = pushUnique(this.values.us.blockgroup, (tract.blockGroups || []).map(bg => bg.fips));
              });
            });
          }
        } else if (newValue.length < oldValue.length) {
          // removed a county
          const removedCounties = difference(oldValue, newValue);
          removedCounties.forEach((removedCounty) => {
            if (this.study.product === 'tla') {
              this.values.us.blockgroup = this.values.us.blockgroup.filter(bg => bg.slice(0, 5) !== removedCounty);
              this.values.us.tract = this.values.us.tract.filter(t => t.slice(0, 5) !== removedCounty);
            }
          });
        }
        this.checkUsStates();
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.us.tract': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new tract
          this.values.us.msa = [];
          const addedTracts = difference(newValue, oldValue);
          addedTracts.forEach((addedTract) => {
            const state = get(this.homeLocations, 'us.states', []).find(s => s.fips === addedTract.slice(0, 2));
            const county = state.counties.find(c => c.fips === addedTract.slice(0, 5));
            addedTract = county.tracts.find(t => t.fips === addedTract);
            this.values.us.blockgroup = pushUnique(this.values.us.blockgroup, (addedTract.blockGroups || []).map(bg => bg.fips));
          });
        } else if (newValue.length < oldValue.length) {
          // removed a tract
          const removedTracts = difference(oldValue, newValue);
          removedTracts.forEach((removedTract) => {
            this.values.us.blockgroup = this.values.us.blockgroup.filter(bg => bg.slice(0, 11) !== removedTract);
          });
        }
        this.checkUsCounties();
        this.checkUsStates();
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.us.blockgroup': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new blockgroup
          this.values.us.msa = [];
        }
        this.checkUsTracts();
        this.checkUsCounties();
        this.checkUsStates();
      }
    },

    'values.us.msa': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new MSA, unselect everything else
          if (this.study.product === 'tla') {
            this.values.us.blockgroup = [];
            this.values.us.tract = [];
          }
          this.values.us.county = [];
          this.values.us.state = [];
        }
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.ca.province': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new province
          this.values.ca.cma = [];
          const addedProvinces = difference(newValue, oldValue);
          addedProvinces.forEach((addedProvince) => {
            addedProvince = get(this.homeLocations, 'us.states', []).find(s => s.fips === addedProvince);
            this.values.ca.county = pushUnique(this.values.ca.county, (addedProvince.counties || []).map(c => c.fips));
            if (this.study.product === 'tla') {
              (addedProvince.counties || []).forEach((county) => {
                this.values.ca.disseminationarea = pushUnique(this.values.ca.disseminationarea, (county.disseminationAreas || []).map(da => da.fips));
              });
            }
          });
        } else if (newValue.length < oldValue.length) {
          // removed a province
          const removedProvinces = difference(oldValue, newValue);
          removedProvinces.forEach((removedProvince) => {
            if (this.study.product === 'tla') {
              this.values.ca.disseminationarea = this.values.ca.disseminationarea.filter(da => da.slice(0, 5) !== removedProvince);
            }
            this.values.ca.county = this.values.ca.county.filter(c => c.slice(0, 5) !== removedProvince);
          });
        }
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.ca.county': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new county
          this.values.ca.cma = [];
          if (this.study.product === 'tla') {
            const addedCounties = difference(newValue, oldValue);
            addedCounties.forEach((addedCounty) => {
              const province = get(this.homeLocations, 'ca.provinces', []).find(p => p.fips === addedCounty.slice(0, 5));
              addedCounty = province.counties.find(c => c.fips === addedCounty);
              this.values.ca.disseminationarea = pushUnique(this.values.ca.disseminationarea, (addedCounty.disseminationAreas || []).map(da => da.fips));
            });
          }
        } else if (newValue.length < oldValue.length) {
          // removed a county
          if (this.study.product === 'tla') {
            const removedCounties = difference(oldValue, newValue);
            removedCounties.forEach((removedCounty) => {
              this.values.ca.disseminationarea = this.values.ca.disseminationarea.filter(da => da.slice(0, 7) !== removedCounty);
            });
          }
        }
        this.checkCaProvinces();
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.ca.disseminationarea': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new dissemination area
          this.values.ca.cma = [];
        }
        this.checkCaProvinces();
        this.checkCaCounties();
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    'values.ca.cma': function (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        if (newValue.length > oldValue.length) {
          // added a new CMA, unselect everything else
          if (this.study.product === 'tla') {
            this.values.ca.disseminationarea = [];
          }
          this.values.ca.county = [];
          this.values.ca.province = [];
        }
        this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
      }
    },

    activeLevel() {
      this.selectedHomeLocations = this.values[this.activeIso][this.activeLevel];
    },

    visibleFilters(value) {
      document.getElementById('home-location-filter').style.setProperty('--numCol', value.length);
    }
  },
  /* eslint-enable func-names */

  computed: {
    activeIsoTabs() {
      if (!isEmpty(this.homeLocations)) {
        return Object.keys(this.homeLocations[this.activeIso]);
      }
      return [];
    },

    selectedHomeLocations: {
      get() {
        return this.filteredHomeLocations;
      },

      set(value) {
        this.setHomeLocationFilter(value);
      }
    },

    selectedHomeLocationNames() {
      if (this.filteredHomeLocations.length) {
        return `${this.filteredHomeLocations.length} Home Locations`;
      }
      return 'All Home Locations';
    },

    visibleFilters() {
      let levels = [];
      if (this.activeIso === 'us') {
        if (this.activeLevel === 'msa') {
          levels = ['msa'];
        } else if (this.activeLevel === 'state') {
          levels = ['states'];
        } else if (this.activeLevel === 'county') {
          levels = ['states', 'counties'];
        } else if (this.activeLevel === 'tract') {
          levels = ['states', 'counties', 'tracts'];
        } else if (this.activeLevel === 'blockgroup') {
          levels = ['states', 'counties', 'tracts', 'blockGroups'];
        }
      } else if (this.activeIso === 'ca') {
        if (this.activeLevel === 'cma') {
          levels = ['cma'];
        } else if (this.activeLevel === 'province') {
          levels = ['provinces'];
        } else if (this.activeLevel === 'county') {
          levels = ['provinces', 'counties'];
        } else if (this.activeLevel === 'disseminationarea') {
          levels = ['provinces', 'counties', 'disseminationAreas'];
        }
      }
      return levels;
    },

    usMsaHl() {
      return get(this.homeLocations, 'us.msa', []);
    },

    usStatesHl() {
      return get(this.homeLocations, 'us.states', []);
    },

    usCountiesHl() {
      return get(this.homeLocations, `us.states[${this.active.us.state}].counties`, []);
    },

    usTractsHl() {
      return get(this.homeLocations, `us.states[${this.active.us.state}].counties[${this.active.us.county}].tracts`, []);
    },

    usBlockGroupsHl() {
      return get(this.homeLocations, `us.states[${this.active.us.state}].counties[${this.active.us.county}].tracts[${this.active.us.tract}].blockGroups`, []);
    },

    caCmaHl() {
      return get(this.homeLocations, 'ca.cma', []);
    },

    caProvincesHl() {
      return get(this.homeLocations, 'ca.provinces', []);
    },

    caCountiesHl() {
      return get(this.homeLocations, `ca.provinces[${this.active.ca.province}].counties`, []);
    },

    caDisseminationAreasHl() {
      return get(this.homeLocations, `ca.provinces[${this.active.ca.province}].counties[${this.active.ca.county}].disseminationAreas`, []);
    },

    ...mapState('visualize', ['study', 'homeLocations', 'activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['filteredHomeLocations'])
  },

  methods: {
    closeSelect() {
      this.menu = false;
    },

    checkUsStates() {
      let states = uniq(this.values.us.county.map(c => c.slice(0, 2)));
      states = get(this.homeLocations, 'us.states', []).filter(s => states.includes(s.fips));
      const complete = states.filter(state => state.counties.map(c => c.fips).every(fips => this.values.us.county.includes(fips))).map(s => s.fips);
      let indeterminate = states.filter(state => state.counties.map(c => c.fips).some(fips => this.values.us.county.includes(fips))).map(s => s.fips);
      indeterminate = difference(indeterminate, complete);
      this.values.us.state = uniq(uniq(concat(this.values.us.state, complete)));
      this.indeterminate.us.state = uniq(concat(indeterminate, this.indeterminate.us.county.map(c => c.slice(0, 2))));
    },

    checkUsCounties() {
      let counties = uniq(this.values.us.tract.map(t => t.slice(0, 5)));
      counties = compact(concat(...this.homeLocations.us.states.map(s => s.counties))).filter(c => counties.includes(c.fips));
      const complete = counties.filter(county => county.tracts.map(t => t.fips).every(fips => this.values.us.tract.includes(fips))).map(c => c.fips);
      let indeterminate = counties.filter(county => county.tracts.map(t => t.fips).some(fips => this.values.us.tract.includes(fips))).map(c => c.fips);
      indeterminate = difference(indeterminate, complete);
      this.values.us.county = uniq(concat(this.values.us.county, complete));
      this.indeterminate.us.county = uniq(concat(indeterminate, this.indeterminate.us.tract.map(t => t.slice(0, 5))));
    },

    checkUsTracts() {
      let tracts = uniq(this.values.us.blockgroup.map(bg => bg.slice(0, 11)));
      tracts = compact(concat(...concat(...this.homeLocations.us.states.map(s => s.counties)).map(c => c.tracts))).filter(t => tracts.includes(t.fips));
      const complete = tracts.filter(tract => tract.blockGroups.map(bg => bg.fips).every(fips => this.values.us.blockgroup.includes(fips))).map(t => t.fips);
      let indeterminate = tracts.filter(tract => tract.blockGroups.map(bg => bg.fips).some(fips => this.values.us.blockgroup.includes(fips))).map(t => t.fips);
      indeterminate = difference(indeterminate, complete);
      this.values.us.tract = uniq(concat(this.values.us.tract, complete));
      this.indeterminate.us.tract = indeterminate;
    },

    checkCaProvinces() {
      let provinces = uniq(this.values.ca.counties.map(c => c.slice(0, 5)));
      provinces = get(this.homeLocations, 'ca.provinces', []).filter(p => provinces.includes(p.fips));
      const complete = provinces.filter(province => province.counties.map(c => c.fips).every(fips => this.values.ca.county.includes(fips))).map(p => p.fips);
      let indeterminate = provinces.filter(province => province.counties.map(c => c.fips).some(fips => this.values.ca.county.includes(fips))).map(p => p.fips);
      indeterminate = difference(indeterminate, complete);
      this.values.ca.province = uniq(concat(this.values.ca.province, complete));
      this.indeterminate.ca.province = uniq(concat(indeterminate, this.indeterminate.ca.county.map(t => t.slice(0, 5))));
    },

    checkCaCounties() {
      let counties = uniq(this.values.ca.disseminationarea.map(t => t.slice(0, 7)));
      counties = compact(concat(...this.homeLocations.ca.provinces.map(p => p.counties))).filter(c => counties.includes(c.fips));
      const complete = counties.filter(county => county.disseminationAreas.map(da => da.fips).every(fips => this.values.ca.disseminationarea.includes(fips))).map(c => c.fips);
      let indeterminate = counties.filter(county => county.map.map(da => da.fips).some(fips => this.values.ca.disseminationarea.includes(fips))).map(c => c.fips);
      indeterminate = difference(indeterminate, complete);
      this.values.ca.county = uniq(concat(this.values.ca.county, complete));
      this.indeterminate.ca.county = indeterminate;
    },

    ...mapActions('visualize', ['setHomeLocationFilter'])
  }
};
</script>

<style lang="scss" scoped>
.home-location-select-list-container {
  --numCol: 1;
  background: #FFFFFF;
  display: grid;
  grid-template-columns: repeat(var(--numCol), max-content);

  &>div:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .text-overline {
    background: #2F3B44;
    color: #FFFFFF;
  }
}
</style>
