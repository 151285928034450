<template>
   <v-select ref="poiSelect" v-model="proxy" prepend-icon="mdi-map-marker" :menu-props="{ maxHeight: 400 }" :items="filteredPoiOptions" label="POIs" item-text="name" item-value="id" multiple :disabled="disabled" @change="poiHandler" @focus="focusSearch" @blur="clearSearch">
    <template v-slot:prepend-item>
      <!-- POI Search -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="poiFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 0)" />
        </v-list-item-content>
        <v-list-item-action>
          <v-row>
            <v-btn icon dark @click="createPoi">
              <v-icon>mdi-map-marker-plus-outline</v-icon>
            </v-btn>
            <v-btn icon dark @click="closeSelect">
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-list-item @click="toggleFiltered" v-if="filteredPoiOptions.length">
        <v-list-item-action>
          <v-checkbox color="primary darken-2" :value="allFilteredSelected()" :value-comparator="allFilteredSelected" :indeterminate="someFilteredSelected()" @toggle="toggleFiltered" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="title font-weight-regular">Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <div class="mb-4 text-center font-italic font-weight-light">No POIs Found</div>
          <v-btn text @click="createPoi">
            <v-icon left>mdi-map-marker-plus-outline</v-icon>
            <span>Create POI</span>
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, index }">
      <!-- Selection -->
      <v-chip v-if="index <= 1">
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-if="index === 1" class="grey--text caption">(+{{ value.length - 2 }} others)</span>
    </template>
    <template v-slot:item="data">
      <!-- POI Item -->
      <v-list-item-action>
        <v-checkbox color="primary" v-model="data.attrs.inputValue" @toggle="data.on" v-if="!data.item.disabled"/>
        <v-checkbox color="primary" value="true" disabled v-else/>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle class="caption">{{ data.item.label }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: Array,
      default: () => []
    },

    limit: {
      type: Number,
      default: 0
    },

    organization: {
      type: [String, Object],
      default: ''
    },

    existingPois: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      proxy: [],
      poiFilter: '',
      pois: []
    };
  },

  computed: {
    filteredPoiOptions() {
      if (!isEmpty(this.poiFilter)) {
        return this.pois.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.label.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.tags.map((t) => t.toLowerCase()).includes(this.poiFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.pois;
    },

    organizationId() {
      let oid = null;
      if (!isEmpty(this.organization)) {
        oid = this.organization;
        if (isObject(this.organization)) {
          oid = this.organization.id;
        }
      }
      return oid;
    },

    includedPois() {
      return this.existingPois.map((poi) => {
        if (isObject(poi)) {
          return poi.id;
        }
        return poi;
      });
    }
  },

  watch: {
    proxy() {
      if (this.value !== this.proxy) {
        this.$emit('input', this.proxy);
      }
    },

    value() {
      if (this.value !== this.proxy) {
        this.proxy = this.value;
      }
    },

    includedPois: {
      immediate: true,
      handler() {
        this.pois = sortBy(this.pois.map((poi) => {
          poi.disabled = this.isAlreadyIncluded(poi);
          return poi;
        }), ['name']);
      }
    },

    organization: {
      immediate: true,
      handler() {
        this.fetchPois();
      }
    }
  },

  methods: {
    poiHandler(value) {
      // apply limit
      if (this.limit > 0 && value.length > this.limit) {
        this.proxy = value.slice(0, this.limit);
      }
    },

    isAlreadyIncluded(poi) {
      if (isObject(poi)) {
        poi = get(poi, 'id', null);
      }
      if (!isEmpty(this.includedPois)) {
        return this.includedPois.includes(poi);
      }
      return false;
    },

    async fetchPois() {
      try {
        if (this.organizationId) {
          const { data: pois } = await this.$services.organizations.listPois(this.organizationId);
          this.pois = sortBy(pois.map((poi) => {
            poi.disabled = this.isAlreadyIncluded(poi);
            return poi;
          }), ['name']);
        }
      } catch (error) {
        this.alertError(error, false);
      }
    },

    createPoi() {
      this.$emit('createPoi');
      this.$refs.poiSelect.blur();
    },

    allFilteredSelected() {
      return this.filteredPoiOptions.filter((fo) => !fo.disabled).length > 0 && this.filteredPoiOptions.filter((fo) => !fo.disabled).every((fo) => this.proxy.includes(fo.id));
    },

    someFilteredSelected() {
      return !this.allFilteredSelected() && this.filteredPoiOptions.some((fo) => !fo.disabled && this.proxy.includes(fo.id));
    },

    async toggleFiltered() {
      let selection = this.proxy;
      if (!this.allFilteredSelected()) {
        selection = selection.concat(this.filteredPoiOptions.filter((fo) => !fo.disabled).map((p) => p.id));
      } else {
        selection = difference(selection, this.filteredPoiOptions.filter((fo) => !fo.disabled).map((p) => p.id));
      }
      selection = uniq(selection);
      this.proxy = selection;
    },

    clearSearch() {
      this.poiFilter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      if (this.$refs.poiSelect) {
        this.$refs.poiSelect.blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
