<template>
  <v-dialog v-model="dialog" width="600" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :label="label" :value="selectText" prepend-icon="mdi-map" append-icon="mdi-checkbox-multiple-blank-outline" v-bind="attrs" v-on="on" readonly />
    </template>
    <v-card>
      <v-card-title>{{ label }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-row>
                <county-select v-model="selected.counties" no-icon />
              </v-row>
              <v-row>
                <tract-select v-model="selected.tracts" :items="tractOptions" :counties="selected.counties" />
              </v-row>
              <v-row>
                <blockgroup-select v-model="selected.blockgroups" :items="blockgroupOptions" :tracts="tractMeta" />
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import alert from '@/mixins/alert';
import counties from '@/data/counties';
import CountySelect from '@/components/CountySelect';
import TractSelect from '@/components/TractSelect';
import BlockgroupSelect from '@/components/BlockgroupSelect';

export default {
  mixins: [alert],

  components: {
    CountySelect,
    TractSelect,
    BlockgroupSelect
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: 'Select Locations'
    }
  },

  data() {
    return {
      proxy: [],
      tractOptions: [],
      blockgroupOptions: [],
      selected: {
        counties: [],
        tracts: [],
        blockgroups: []
      },
      map: null,
      dialog: false,
      hoverStateId: null
    };
  },

  computed: {
    selectText() {
      return `${this.value.length} locations selected`;
    },

    tractMeta() {
      return this.tractOptions.filter((t) => this.selected.tracts.includes(t.geoId)).map((t) => {
        const { geoId: fips, name } = t;
        let county;
        const stateInfo = counties.find((s) => s.fips === t.stateFips);
        if (stateInfo) {
          const countyInfo = stateInfo.counties.find((c) => c.fips === `${t.stateFips}${t.countyFips}`);
          if (countyInfo) {
            county = countyInfo.name;
          }
        }
        return { fips, name, county };
      });
    }
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

  watch: {
    // eslint-disable-next-line
    'selected.counties': async function () {
      const tracts = await Promise.all(this.selected.counties.map(async (c) => {
        const stateFips = c.slice(0, 2);
        const countyFips = c.slice(2);
        const { data } = await this.$services.geometry.getCollection({ params: { type: 'tract', summary: true, stateFips, countyFips } });
        return data;
      }));
      this.tractOptions = flatten(tracts);
    },

    // eslint-disable-next-line
    'selected.tracts': async function () {
      const blockgroups = await Promise.all(this.selected.tracts.map(async (t) => {
        const stateFips = t.slice(0, 2);
        const countyFips = t.slice(2, 5);
        const tractCe = t.slice(5);
        const { data } = await this.$services.geometry.getCollection({ params: { type: 'blockgroup', summary: true, stateFips, countyFips, tractCe } });
        return data;
      }));
      this.blockgroupOptions = flatten(blockgroups);
    },

    selected: {
      immediate: true,
      deep: true,
      handler() {
        const { counties = [], tracts = [], blockgroups = [] } = this.selected;
        this.proxy = blockgroups.length ? blockgroups : tracts.length ? tracts : counties;
      }
    },

    proxy() {
      if (this.value !== this.proxy) {
        this.$emit('input', this.proxy);
        this.setSelections(this.proxy);
      }
    },

    value() {
      if (this.value !== this.proxy) {
        this.proxy = this.value;
        this.setSelections(this.value);
      }
    }
  },

  methods: {
    close() {
      this.dialog = false;
    },

    setSelections(selections) {
      const { counties = [], tracts = [] } = this.selected;
      const locLength = selections[0].length;
      if (locLength === 5) {
        this.selected = {
          counties: selections,
          tracts: [],
          blockgroups: []
        };
      } else if (locLength === 11) {
        this.selected = {
          counties: counties.length ? counties : uniq(selections.map((p) => p.slice(0, 5))),
          tracts: selections,
          blockgroups: []
        };
      } else if (locLength === 12) {
        this.selected = {
          counties: counties.length ? counties : uniq(selections.map((p) => p.slice(0, 5))),
          tracts: tracts.length ? tracts : uniq(selections.map((p) => p.slice(0, 11))),
          blockgroups: selections
        };
      } else {
        this.selected = {
          counties: [],
          tracts: [],
          blockgroups: []
        };
      }
    }
  }
};
</script>
