<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="donut" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import sum from 'lodash/sum';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: [Array, Object],
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        labels: Object.keys((omit(Array.isArray(this.data) ? first(this.data) : this.data, ['poi']))).map((d) => startCase(d)),
        colors: ['#264653', '#2A9D8F', '#E9C46A', '#F4A261', '#E76F51', '#FFD670', '#E9FF70', '#2D7DD2'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                value: {
                  formatter: (v, { globals: { series } }) => {
                    return `${(v / sum(series) * 100).toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}%`;
                  }
                }
              }
            }
          }
        },
        legend: {
          show: true
        },
        tooltip: {
          enabled: false
        }
      };
    },

    series() {
      let series = [];
      if (!isEmpty(this.data)) {
        if (Array.isArray(this.data)) {
          series = Object.values(omit(first(this.data), ['poi'])).map((d) => parseInt(d, 10));
        } else {
          series = Object.values(omit(this.data, ['poi'])).map((d) => parseInt(d, 10));
        }
      }
      return series;
    }
  }
};
</script>
