<template>
  <v-card class="mb-5" outlined>
    <v-card-text>
      <v-row>
        <v-col>
          <data-fetcher height="350" report="getDayTripOvernight" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <day-trip title="Day Trip Vs Passthru" height="350" :limit="10" :data="data" />
          </data-fetcher>
        </v-col>
        <v-col>
          <data-fetcher height="350" report="getDayTripOvernight" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <overnight-stay title="Overnight Stay" height="350" :limit="10" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DataFetcher from '@/components/charts/DataFetcher';
import DayTrip from '@/components/charts/DayTrip';
import OvernightStay from '@/components/charts/OvernightStay';

export default {
  components: {
    DataFetcher,
    DayTrip,
    OvernightStay
  },

  data() {
    return {
      totals: {}
    };
  },

  computed: {
    ...mapState('visualize', ['activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredHomeLocations'])
  }
};
</script>
