<template>
  <v-app>
    <div class="main-viewport">
      <template v-if="ready">
        <v-app-bar app color="dark" flat :collapse-on-scroll="isSmall" key="app-bar">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-app-bar-nav-icon dark v-on="on">
                <v-img src="~@/assets/img/logo_white.png" alt="AirSage Logo" height="32" contain v-if="isSmall"/>
              </v-app-bar-nav-icon>
            </template>
            <v-list>
              <v-list-item two-line>
                <v-list-item-avatar>
                  <v-icon x-large>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ userName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item @click="goTo('account')">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $tc('account') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goTo('main')">
                <v-list-item-icon>
                  <v-icon>mdi-text-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $tc('studies') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="goTo('pois')">
                <v-list-item-icon>
                  <v-icon>mdi-map-marker-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $tc('pointsOfInterest') }}</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item @click="signOut">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $tc('logOut') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-toolbar-title class="pl-1">
            <v-img src="~@/assets/img/logo_words.png" alt="AirSage Logo" width="124" contain v-if="isSmall" />
            <v-img src="~@/assets/img/logo_long_white.png" alt="AirSage Logo" width="158" contain v-else />
          </v-toolbar-title>
          <v-spacer />
          <v-btn :icon="isSmall" :text="!isSmall" dark @click="showCreatePoiForm = true" v-if="['pois','poi'].includes($route.name)">
            <v-icon :left="!isSmall" color="white">mdi-map-marker-plus-outline</v-icon>
            <span v-if="!isSmall">{{ $tc('createPoi') }}</span>
          </v-btn>
          <!-- <v-btn :icon="isSmall" :text="!isSmall" dark @click="showHelpBackdrop = !showHelpBackdrop" v-else-if="['study'].includes($route.name)">
            <v-icon :left="!isSmall" color="white">mdi-help-circle-outline</v-icon>
            <span v-if="!isSmall">{{ $tc('help') }}</span>
          </v-btn> -->
          <v-btn :icon="isSmall" :text="!isSmall" dark @click="showCreateStudy = true" v-else>
            <v-icon :left="!isSmall" color="white">mdi-text-box-plus-outline</v-icon>
            <span v-if="!isSmall">{{ $tc('createStudy') }}</span>
          </v-btn>
        </v-app-bar>
        <v-main key="app-content">
          <router-view />
        </v-main>
        <v-snackbar v-model="snackbar" :color="alert.color" :timeout="alert.timeout" key="app-snackbar">
          <span>{{ alert.message }}</span>
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">{{ $tc('close') }}</v-btn>
          </template>
        </v-snackbar>
      </template>
      <template v-else>
        <v-container dark fluid fill-height key="app-loading">
          <v-row justify="center" fill-height>
            <v-progress-circular color="primary" size="64" indeterminate />
          </v-row>
        </v-container>
      </template>
      <shop :show.sync="showCreateStudy" />
      <create-poi :show.sync="showCreatePoiForm" />
      <!-- <help-backdrop /> -->
    </div>
  </v-app>
</template>

<script>
import Router from 'vue-router';
import { mapState, mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import alert from '@/mixins/alert';
import Shop from '@/components/Shop';
import CreatePoi from '@/components/CreatePoi';
// import HelpBackdrop from '@/components/HelpBackdrop';

export default {
  name: 'App',

  mixins: [alert],

  components: {
    Shop,
    CreatePoi
    // HelpBackdrop
  },

  data() {
    return {
      pending: false,
      snackbar: false,
      ready: false
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    showCreateStudy: {
      get() {
        return this.shopVisible;
      },

      set(show) {
        if (show) {
          this.showShop();
        } else {
          this.hideShop();
        }
      }
    },

    showCreatePoiForm: {
      get() {
        return this.createPoiVisible;
      },

      set(show) {
        if (show) {
          this.showCreatePoi();
        } else {
          this.hideCreatePoi();
        }
      }
    },

    showHelpBackdrop: {
      get() {
        return this.helpVisible;
      },

      set(show) {
        if (show) {
          this.showHelp();
        } else {
          this.hideHelp();
        }
      }
    },

    canShop() {
      return this.isAdmin || this.organizations.reduce((canShop, { products }) => {
        return canShop || Object.values(products).reduce((write, p) => write || p.write, false);
      }, false);
    },

    ...mapState(['alert', 'organizations', 'shopVisible', 'createPoiVisible', 'helpVisible']),
    ...mapGetters(['userEmail', 'userId', 'userName', 'isAdmin', 'hasScrolled'])
  },

  async created() {
    try {
      await this.$auth.renewTokens();
    } catch (error) {
      if (error.code && error.code === 'login_required') {
        this.$auth.login();
      } else {
        this.alertError(error);
      }
    }
  },

  watch: {
    alert: {
      deep: true,
      handler() {
        if (this.alert.color && this.alert.message && this.alert.message.length > 0) {
          this.snackbar = true;
        }
      }
    }
  },

  methods: {
    async handleLoginEvent(data) {
      try {
        this.setUser(data.profile);
        let organizations = [];
        if (this.isAdmin) {
          const { data } = await this.$services.organizations.all({ params: { deep: true } });
          organizations = data;
        } else {
          const { data } = await this.$services.users.listOrganizations(this.userId, { params: { deep: true } });
          organizations = data;
        }
        if (isEmpty(organizations)) {
          this.alertError('User does not belong to any organizations');
        }

        organizations = organizations.map((d) => {
          const { orders, tags, pois, poiLabels, poiTags, ...org } = d;
          this.setOrders({ oid: org.id, orders });
          this.setPois({ oid: org.id, pois });
          this.setTags({ oid: org.id, tags });
          this.setPoiLabels({ oid: org.id, labels: poiLabels });
          this.setPoiTags({ oid: org.id, tags: poiTags });
          return org;
        });
        this.setOrganizations(organizations);
        this.ready = true;
      } catch (error) {
        this.alertError(error);
      }
    },

    async signOut() {
      this.pending = true;
      this.$auth.logOut();
    },

    goTo(name) {
      this.$router.push({ name }).catch((error) => {
        if (!Router.isNavigationFailure(error, Router.NavigationFailureType.duplicated)) {
          throw error;
        }
      });
    },

    ...mapActions(['setUser', 'setOrganizations', 'setOrders', 'setPois', 'setTags', 'setPoiTags', 'setPoiLabels', 'setFilter', 'showShop', 'hideShop', 'showCreatePoi', 'hideCreatePoi', 'showHelp', 'hideHelp'])
  }
};
</script>

<style lang="scss">
.main-viewport {
  height: 100%;
}

.v-main {
  background: #FFFFFF;
}
</style>
