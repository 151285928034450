import { Popup, Marker } from 'mapbox-gl';
import { feature, getCoord, center } from '@turf/turf';

class PoiLayerManager {
  hoverStateId;

  map;

  pois;

  constructor(map, poi) {
    this.map = map;
    this.poi = poi;
    this.renderLayer();
  }

  renderLayer() {
    const { geom, ...properties } = this.poi;
    this.map.addSource('POIS', {
      type: 'geojson',
      data: feature(geom, properties),
      promoteId: 'id'
    });

    this.map.addLayer({
      id: 'poiShape',
      type: 'fill',
      source: 'POIS',
      paint: {
        'fill-antialias': true,
        'fill-color': '#FF8606',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5]
      }
    });

    this.map.addLayer({
      id: 'poiLines',
      type: 'line',
      source: 'POIS',
      paint: {
        'line-color': '#DE7200',
        'line-width': 1,
        'line-opacity': 0.75
      }
    });

    const { id, name, label } = properties;
    const centerOfPoi = getCoord(center(geom));
    const popup = new Popup({
      closeButton: false,
      closeOnClick: true
    }).setHTML(`
      <div class="v-card v-card--flat v-sheet">
        <div class="v-card__text pa-0">
          <div class="headline">${name}</div>
          <div class="subheading mb-1">${label}</div>
        </div>
      </div>
    `);
    const marker = new Marker({ color: '#0DAADD' }).setLngLat(centerOfPoi).setPopup(popup).addTo(this.map);
    const markerDiv = marker.getElement();
    markerDiv.addEventListener('mouseenter', () => {
      if (this.hoveredStateId) {
        this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
      }
      this.map.setFeatureState({ source: 'POIS', id }, { hover: true });
      this.hoveredStateId = id;
    });
    markerDiv.addEventListener('mouseleave', () => {
      if (this.hoveredStateId) {
        this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
      }
      this.hoveredStateId = null;
    });
  }
}

export default PoiLayerManager;
