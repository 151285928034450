import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    // App
    account: 'Account',
    studies: 'Studies',
    pointsOfInterest: 'Points of Interest',
    logOut: 'Log Out',
    createPoi: 'Create POI',
    createStudy: 'Create Study',
    close: 'Close',
    // Main
    noStudies: 'You have no studies available',
    filter: 'Filter',
    trial: 'Freemium',
    // Account
    resetPassword: 'Reset Password',
    // POIs
    noPois: 'You have no points of interest available',
    createPoiLong: 'Create Point of Interest',
    // POI
    revert: 'revert',
    includedInStudies: 'Included In These Studies',
    rerunStudies: 'Rerun Studies',
    rerunPrompt1: 'Some coordinates were changed, this could result in different study results.',
    rerunPrompt2: 'Would you like to re-scan this POI in studies that include it?',
    // Study
    download: 'download',
    downloadShapes: 'export POIs',
    downloadShape: 'export POI',
    addDate: 'Add Date',
    addPoi: 'Add POI',
    // Shop
    trialStudy: 'Freemium Study',
    createAdStudy: 'Create Activity Density Study',
    createDlaStudy: 'Create Destination Location Analysis Study',
    createTlaStudy: 'Create Target Location Analysis Study',
    createNwtmStudy: 'Create Nationwide Trip Matrix Study',
    createPpStudy: 'Create Point Plot Study',
    choose: 'choose',
    next: 'next',
    cancel: 'cancel',
    submit: 'submit',
    cost: 'cost',
    credits: 'credit | credits',
    advancedOptions: 'Advanced Options',
    basicOptions: 'Basic Options',
    tlaName: 'Target Location Analysis',
    dlaName: 'Destination Location Analysis',
    adName: 'Activity Density',
    ppName: 'Point Plot',
    nwtmName: 'Nationwide Trip Matrix',
    tlaDescription: 'Get daily footfall estimates, demographic information, and more for one or more points of interest.',
    dlaDescription: 'Get monthly footfall estimates, home locations, and demographic information of visitors to a destination (MSA, county, city, or a custom study area).',
    adDescription: 'See all the locations of activity inside a study area. Useful for populating a heatmap of population density.',
    nwtmDescription: 'Get all block group to block group trips for selected counties.',
    ppDescription: 'Get all the raw points inside a study area.',
    // Help
    help: 'Help',
    // Misc
    back: 'back',
    save: 'save'
  }
};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

export default i18n;
