import axios from 'axios';
import qs from 'qs';
import Service from '@/services/service';

export default class OrderService extends Service {
  constructor() {
    super('orders');
    this.CancelToken = axios.CancelToken;
    this.isCancel = axios.isCancel;
  }

  expand(id, config, options) {
    return this.service.post(`${id}/expand`, config, options);
  }

  getStatus(id, options) {
    return this.service.get(`${id}/status`, options);
  }

  getDates(id, options) {
    return this.service.get(`${id}/dates`, options);
  }

  getPois(id, options) {
    return this.service.get(`${id}/pois`, options);
  }

  getHomeLocations(id, options) {
    return this.service.get(`${id}/homelocations`, options);
  }

  visualize(id, options) {
    options.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'indices' });
    return this.service.get(`${id}/visualize`, options);
  }

  export(id, options) {
    return this.service.get(`${id}/export`, options);
  }

  rerun(id, options) {
    return this.service.get(`/${id}/rerun`, options);
  }
}
