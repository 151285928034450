<template>
  <v-dialog v-model="show" :fullscreen="isSmall" width="800" persistent>
    <v-card flat :tile="isSmall">
      <v-card-title>
        <div class="headline">Add Months</div>
        <v-spacer />
        <div>Cost: <span :class="costTextColor">{{ cost }}</span>/{{ creditsAvailable }} credits</div>
      </v-card-title>
      <v-card-text>
        <v-alert border="left" type="warning" dense text outlined>This study will unavailable while new dates are calculated.</v-alert>
        <date-select v-model="report.dates" :existing-dates="studyDates" study-product="dla" :organization="studyOrganizationId" can-select-future />
      </v-card-text>
      <v-card-actions>
        <v-btn id="dla-add-date-cancel" color="error" text large @click="cancel">Cancel</v-btn>
        <v-spacer />
        <v-btn id="dla-add-date-submit" color="primary" depressed large :loading="pending" @click="checkout" :disabled="!canCheckout">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import get from 'lodash/get';
import { mapState, mapGetters, mapActions } from 'vuex';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import alert from '@/mixins/alert';
import DateSelect from '@/components/DateSelect';
import { unfurlNestedDates } from '@/lib/utils';

export default {
  mixins: [alert],

  components: {
    DateSelect
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pending: false,
      report: {
        dates: []
      }
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    studyDates() {
      return get(this.study, 'config.dates', []);
    },

    studyOrganizationId() {
      return get(this.study, 'organization', '');
    },

    canCheckout() {
      const { dates = [] } = this.report;
      return Boolean(this.isAuthenticated && dates.length && this.canAfford);
    },

    creditsAvailable() {
      let creditsAvailable = 0;
      const orgInfo = this.organizations.find((o) => o.id === this.studyOrganizationId);
      if (orgInfo) {
        creditsAvailable = orgInfo.credits || 0;
      }
      return creditsAvailable;
    },

    cost() {
      let { dates = [] } = this.report;
      const { geolevels = [] } = this.study.config;
      dates = unfurlNestedDates(dates);
      return dates.length * geolevels.length * (Math.ceil(this.pois.length / 20) || 1);
    },

    costTextColor() {
      let textColor = 'black';
      if (this.cost === 0) {
        textColor = 'black';
      } else if (this.cost < this.creditsAvailable) {
        textColor = 'success';
      } else if (this.cost === this.creditsAvailable) {
        textColor = 'warning';
      } else if (this.cost > this.creditsAvailable) {
        textColor = 'error';
      }
      return `${textColor}--text`;
    },

    canAfford() {
      return this.cost <= this.creditsAvailable;
    },

    ...mapState(['organizations']),
    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['pois'])
  },

  methods: {
    async checkout() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        let { dates } = this.report;

        const config = {
          dates: dates.map((date) => date.map((d) => formatDate(parseISO(d), 'yyyyMM')))
        };

        await this.$services.orders.expand(id, config);
        this.alertSuccess(`Successfully added dates to ${name}`);
        const { data: orders } = await this.$services.organizations.listOrders(this.studyOrganizationId);
        this.setOrders({ oid: this.studyOrganizationId, orders });
        this.cancel();
        this.$router.push({ name: 'main' });
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.report = {
        dates: []
      };
    },

    cancel() {
      this.reset();
      this.$emit('update:show', false);
    },

    ...mapActions(['setOrders'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
