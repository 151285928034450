<template>
  <v-card class="mb-5" outlined>
    <v-card-title class="mb-4 study-name">
      <v-text-field class="rename-study-input text-body-1" v-model="studyName" v-if="renaming" append-icon="mdi-check"
        clearable outlined hide-details dense @click:clear="cancelRename" @click:append="rename" />
      <div class="display-1" v-else>{{ study.name }}</div>
      <v-btn class="rename-icon" icon @click="startRenaming" v-show="!renaming">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer />
      <study-county-select v-model="selectedCounties" limit="10" />
      <study-aggregation-select v-model="selectedAggregation" />
      <study-date-select />
    </v-card-title>
    <v-card-text>
      <trip-map />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import alert from '@/mixins/alert';
import StudyDateSelect from '@/components/StudyDateSelect';
import StudyCountySelect from '@/components/StudyCountySelect';
import StudyAggregationSelect from '@/components/StudyAggregationSelect';
import TripMap from '@/components/studies/nwtm/reports/TripMap';

export default {
  mixins: [alert],

  components: {
    StudyCountySelect,
    StudyDateSelect,
    StudyAggregationSelect,
    TripMap
  },

  data() {
    return {
      pending: false,
      studyName: '',
      renaming: false
    };
  },

  computed: {
    selectedCounties: {
      get() {
        return this.filteredPois;
      },

      set(value) {
        this.setPoiFilter(value);
      }
    },

    selectedAggregation: {
      get() {
        return this.filteredAggregation;
      },

      set(value) {
        this.setAggregationFilter(value);
      }
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['filteredPois', 'filteredAggregation'])
  },

  methods: {
    startRenaming() {
      this.studyName = this.study.name;
      this.renaming = true;
    },

    async rename() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.studyName });
        this.alertSuccess(`${name} renamed to ${this.studyName}`);
        this.setStudyName(this.studyName);
        this.setOrderProperty({ id, key: 'name', value: this.studyName });
        this.cancelRename();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    cancelRename() {
      this.renaming = false;
      this.studyName = this.study.name;
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['setPoiFilter', 'setAggregationFilter', 'setStudyName'])
  }
};
</script>

<style lang="scss">
.rename-icon {
  visibility: hidden;
}
.study-name:hover {
  .rename-icon {
    visibility: visible;
  }
}
.rename-study-input {
  max-width: 33%;
}
</style>
