import zipObject from 'lodash/zipObject';
import cloneDeep from 'lodash/cloneDeep';
import { scaleQuantile } from 'd3-scale';
import UsLayerManager from '@/lib/layerManagers/us';
import CanadaLayerManager from '@/lib/layerManagers/ca';

class ChoroplethLayerManager {
  layers = [];

  colors = ['#F2F6FA', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B', '#04193A'];

  constructor(map) {
    this.layers.push(new UsLayerManager(map));
    this.layers.push(new CanadaLayerManager(map));
    this.loadTileSets();
  }

  loadTileSets() {
    this.layers.forEach((l) => l.loadTileSets());
  }

  generateColorMap(studyData, property = 'extrapolated_devices') {
    const data = cloneDeep(studyData);
    const initialScalescale = scaleQuantile().domain(data.map((d) => d[property])).range(this.colors);
    const colorMap = zipObject([0].concat(initialScalescale.quantiles().map((q) => Math.trunc(q))), this.colors);
    return colorMap;
  }

  renderLayer(iso, level, data, { previousLayer, property = 'extrapolated_devices' } = {}) {
    const colorMap = this.generateColorMap(data, property);
    this.layers.forEach((l) => l.renderLayer(iso, level, data, { previousLayer, colorMap, property }));
    return colorMap;
  }
}

export default ChoroplethLayerManager;
