<template>
  <v-card class="mb-5" outlined>
    <v-card-title class="study-name">
      <v-text-field class="rename-study-input text-body-1" v-model="studyName" v-if="renaming" append-icon="mdi-check"
        clearable outlined hide-details dense @click:clear="cancelRename" @click:append="rename" />
      <div class="display-1" v-else>{{ study.name }}</div>
      <v-btn class="rename-icon" icon @click="startRenaming" v-show="!renaming">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer />
      <poi-select v-model="selectedPoi" />
      <study-home-location-select />
      <study-date-select />
    </v-card-title>
    <div ref="map" id="tla-pois-map" class="map" style="height: 250px;"></div>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Marker, Popup } from 'mapbox-gl';
import { featureCollection, feature, center, getCoord } from '@turf/turf';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Map from '@/lib/map';
import alert from '@/mixins/alert';
import PoiSelect from '@/components/StudyPoiSelect';
import StudyHomeLocationSelect from '@/components/StudyHomeLocationSelect';
import StudyDateSelect from '@/components/StudyDateSelect';

export default {
  mixins: [alert],

  components: {
    StudyHomeLocationSelect,
    StudyDateSelect,
    PoiSelect
  },

  data() {
    return {
      map: null,
      ready: false,
      loading: false,
      hoveredStateId: null,
      activePoiStateId: null,
      studyName: '',
      renaming: false
    };
  },

  computed: {
    selectedPoi: {
      get() {
        return get(this.activePoi, 'id', '');
      },

      set(value) {
        if (this.map) {
          if (this.activePoiStateId) {
            this.map.setFeatureState({ source: 'POIS', id: this.activePoiStateId }, { activePoi: false });
          }
          this.map.setFeatureState({ source: 'POIS', id: value }, { activePoi: true });
          this.activePoiStateId = value;
        }
        this.setActivePoi(value);
      }
    },

    ...mapState('visualize', ['study', 'activePoi']),
    ...mapGetters('visualize', ['pois'])
  },

  watch: {
    pois() {
      if (this.ready && !isEmpty(this.pois)) {
        this.loadPointsOfInterest();
      }
    },

    ready() {
      if (this.ready && !isEmpty(this.pois)) {
        this.loadPointsOfInterest();
      }
    }
  },

  async mounted() {
    this.init();
  },

  methods: {
    init() {
      this.map = new Map(this.$refs.map, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        eventHandlers: {
          'style.load': async () => {
            this.ready = true;
          }
        }
      });
    },

    async loadPointsOfInterest() {
      if (this.map) {
        try {
          this.loading = true;

          // remove existing shapes from the map
          ['poiShapes', 'poiLines'].forEach((layerId) => {
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
          });
          if (this.map.getSource('POIS')) {
            this.map.removeSource('POIS');
          }

          const pois = featureCollection(this.pois.map(({ geom, id, name, label }) => feature(geom, { id, name, label })));
          this.map.addSource('POIS', {
            type: 'geojson',
            data: pois,
            promoteId: 'id'
          });

          this.map.addLayer({
            id: 'poiShapes',
            type: 'fill',
            source: 'POIS',
            paint: {
              'fill-antialias': true,
              'fill-color': '#FF8606',
              'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], ['feature-state', 'activePoi'], false], 1, 0.5]
            }
          });

          this.map.addLayer({
            id: 'poiLines',
            type: 'line',
            source: 'POIS',
            paint: {
              'line-color': '#DE7200',
              'line-width': 1,
              'line-opacity': 0.75
            }
          });

          pois.features.forEach((poi) => {
            const { geometry, properties: { id, name, label } } = poi;
            const centerOfPoi = getCoord(center(geometry));
            const popup = new Popup({
              closeButton: false,
              closeOnClick: true
            }).setHTML(`
              <div class="v-card v-card--flat v-sheet">
                <div class="v-card__text pa-0">
                  <div class="headline">${name}</div>
                  <div class="subheading mb-1">${label}</div>
                </div>
              </div>
            `);
            const marker = new Marker({ color: '#0DAADD' }).setLngLat(centerOfPoi).setPopup(popup).addTo(this.map);
            const markerDiv = marker.getElement();
            markerDiv.addEventListener('mouseenter', () => {
              if (this.hoveredStateId) {
                this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
              }
              this.map.setFeatureState({ source: 'POIS', id }, { hover: true });
              this.hoveredStateId = id;
            });
            markerDiv.addEventListener('mouseleave', () => {
              if (this.hoveredStateId) {
                this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
              }
              this.hoveredStateId = null;
            });
            markerDiv.addEventListener('click', () => {
              if (this.activePoiStateId) {
                this.map.setFeatureState({ source: 'POIS', id: this.activePoiStateId }, { activePoi: false });
              }
              this.map.setFeatureState({ source: 'POIS', id }, { activePoi: true });
              this.activePoiStateId = id;
              this.setActivePoi(id);
            });
          });

          this.map.centerOn(pois);
        } catch (error) {
          this.alertError(error);
        } finally {
          this.loading = false;
        }
      }
    },

    startRenaming() {
      this.studyName = this.study.name;
      this.renaming = true;
    },

    async rename() {
      this.loading = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.studyName });
        this.alertSuccess(`${name} renamed to ${this.studyName}`);
        this.setStudyName(this.studyName);
        this.setOrderProperty({ id, key: 'name', value: this.studyName });
        this.cancelRename();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.loading = false;
      }
    },

    cancelRename() {
      this.renaming = false;
      this.studyName = this.study.name;
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['setActivePoi', 'setStudyName'])
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
  height: 250px;
}

.rename-icon {
  visibility: hidden;
}

.study-name:hover {
  .rename-icon {
    visibility: visible;
  }
}

.rename-study-input {
  max-width: 33%;
}
</style>
