<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1
        },
        grid: {
          show: false
        },
        colors: Object.values(this.comparisonColors),
        legend: {
          show: false
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: false
        },
        xaxis: {
          categories: ['15min - 2hr', '2hr - 3hr', '3hr - 4hr', '4hr - 5hr', '5hr+'],
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: [{
          labels: {
            show: false,
            formatter: (value) => `${value}%`
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }]
      };
    },

    series() {
      let series = [];
      if (!isEmpty(this.data)) {
        Object.entries(this.data).sort((a, b) => {
          const keyA = a[0].toLowerCase();
          const keyB = b[0].toLowerCase();
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }).forEach(([dataSet, d]) => {
          let [one, two, three, four, ...fivePlus] = d.map((d) => Number(d.count));
          fivePlus = sum(fivePlus);
          const total = sum([one, two, three, four, fivePlus]);
          [one, two, three, four, fivePlus] = [one, two, three, four, fivePlus].map((x) => Math.round(x / total * 100));
          series.push({
            name: this.comparisonNames[dataSet],
            data: [one, two, three, four, fivePlus]
          });
        });
      }
      return series;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
