<template>
  <v-container pb-0>
    <v-row>
      <div class="headline pl-1 pr-1">{{ title }}</div>
      <v-spacer />
      <v-btn-toggle v-model="currentView" color="primary" group mandatory>
        <v-btn value="MatrixChart">
          <v-icon left>mdi-data-matrix</v-icon>
          <span class="hidden-sm-and-down">Matrix</span>
        </v-btn>
        <v-btn value="ChordChart">
          <v-icon left>mdi-checkbox-blank-circle-outline</v-icon>
          <span class="hidden-sm-and-down">Chord</span>
        </v-btn>
      </v-btn-toggle>
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-row>
    <v-row>
      <v-col>
        <v-window v-model="currentView">
          <v-window-item value="MatrixChart">
            <matrix-chart ref="matrix" :height="height" :data="data" />
          </v-window-item>
          <v-window-item value="ChordChart">
            <chord-chart :size="height" :data="data" />
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { parse } from 'json2csv';
import omitBy from 'lodash/omitBy';
import startsWith from 'lodash/startsWith';

import ChordChart from '@/components/charts/ChordChart';
import MatrixChart from '@/components/charts/MatrixChart';

export default {
  props: {
    title: {
      type: String,
      default: 'Trip Matrix'
    },

    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: Number,
      default: 600
    }
  },

  data() {
    return {
      fab: false,
      currentView: 'MatrixChart'
    };
  },

  components: {
    ChordChart,
    MatrixChart
  },

  methods: {
    async exportPng(title) {
      const { imgURI, blob } = await this.$refs.matrix.$refs.chart.dataURI();
      const filename = `${(this.title || title).replace(/\s/g, '')}.png`;
      if (blob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.href = imgURI;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },

    async exportCsv(title) {
      const filename = `${(this.title || title).replace(/\s/g, '')}.csv`;

      // convert data to csv
      const csvData = parse(this.data.map((d) => omitBy(d, (v, k) => startsWith(k.toLowerCase(), 'raw') || startsWith(k.toLowerCase(), 'sample'))));
      const blob = new Blob([csvData], { type: 'text/csv' });

      // download file
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        document.body.append(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }
};
</script>
