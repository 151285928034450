import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { app, auth0Config, sentry } from '@/config';
import App from '@/App.vue';
import Auth from '@/plugins/auth';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import services from '@/services';
import router from '@/router';
import store from '@/store';
import ShopNwtm from '@/components/shops/NWTM';
import ShopAd from '@/components/shops/AD';
import ShopDla from '@/components/shops/DLA';
import ShopTla from '@/components/shops/TLA';
import ShopPp from '@/components/shops/PP';
import ShopNull from '@/components/shops/Null';
import VisualizeDla from '@/components/studies/dla/Study';
import VisualizeTla from '@/components/studies/tla/Study';
import VisualizeAd from '@/components/studies/ad/Study';
import VisualizeNwtm from '@/components/studies/nwtm/Study';
import VisualizePp from '@/components/studies/pp/Study';
import VisualizePending from '@/components/studies/Pending';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

Vue.use(Auth, auth0Config);
Vue.use(services);
Vue.component('apexchart', VueApexCharts);
Vue.component('shop-nwtm', ShopNwtm);
Vue.component('shop-ad', ShopAd);
Vue.component('shop-dla', ShopDla);
Vue.component('shop-tla', ShopTla);
Vue.component('shop-pp', ShopPp);
Vue.component('shop-null', ShopNull);
Vue.component('visualize-dla', VisualizeDla);
Vue.component('visualize-tla', VisualizeTla);
Vue.component('visualize-ad', VisualizeAd);
Vue.component('visualize-nwtm', VisualizeNwtm);
Vue.component('visualize-pp', VisualizePp);
Vue.component('visualize-pending', VisualizePending);

const { NODE_ENV: env } = process.env;
Vue.config.debug = env !== 'production';
Vue.config.devtools = env !== 'production';
Vue.config.productionTip = env !== 'production';

if (sentry.dsn) {
  Sentry.init({
    debug: env !== 'production',
    environment: app.environment,
    dsn: sentry.dsn,
    release: `${app.name}@${app.version}`,
    beforeBreadcrumb(breadcrumb, hint) {
      // if a button was clicked, get the id of the button
      if (breadcrumb.category === 'ui.click' && hint) {
        const path = get(hint, 'event.path', []);
        if (path.length) {
          for (let index = 0; index < path.length; index++) {
            const { id } = path[index];
            if (id) {
              breadcrumb.message = `#${id}`;
              break;
            }
          }
        }
      }
      return breadcrumb;
    },
    integrations: [new Integrations.Vue({
      Vue,
      attachProps: true
    })],
    ignoreUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extensions:\/\//i
    ]
  });
}

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app');
