<template>
  <v-card flat :tile="isSmall">
    <v-card-title>
      <div class="headline">{{ $tc('createPpStudy') }}</div>
      <v-spacer />
      <div v-if="isTrial">{{ $tc('trialStudy') }}</div>
      <div v-else>{{ $tc('cost') }}: <span :class="costTextColor">{{ cost }}</span>/{{ creditsAvailable }} {{ $tc('credits') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form v-model="formValid">
        <v-container class="py-2">
          <v-row>
            <v-col class="py-0">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-container>
                    <v-row>
                      <v-select id="report-organization" v-model="report.organization" label="Organization" prepend-icon="mdi-domain" :items="selectableOrganizations" return-object item-text="name" item-value="id" :readonly="!isAdmin" />
                    </v-row>
                    <v-row>
                      <v-text-field id="report-name" v-model="report.name" label="Report Name" prepend-icon="mdi-text-box-outline" />
                    </v-row>
                    <v-row>
                      <v-text-field id="report-description" v-model="report.description" label="Description" prepend-icon="mdi-text" />
                    </v-row>
                    <v-row>
                      <date-select v-model="report.dates" study-product="pp" :organization="report.organization" />
                    </v-row>
                    <v-row>
                      <v-combobox id="report-tags" v-model="report.tags" :items="availableTags" label="Tags" prepend-icon="mdi-tag-outline" multiple chips />
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item :value="2">
                  <v-container>
                    <v-row class="mb-0">
                      <study-area-select v-model="report.studyArea" :organization="report.organization" />
                    </v-row>
                    <v-row class="mb-5">
                      <v-select id="report-groupby" v-model="report.groupBy" label="Group By" prepend-icon="mdi-chart-timeline" :items="groups" hide-details />
                    </v-row>
                    <v-row class="mb-1">
                      <v-select id="report-point-type" v-model="report.pointTypes" label="Point Types" prepend-icon="mdi-circle-double" :items="pointTypes" multiple hide-details />
                    </v-row>
                    <v-row>
                      <v-slider v-model="report.percentage" label="Sample Percentage" track-color="dark lighten-5" min="1" max="100" hide-details>
                        <template v-slot:append>
                          <div class="text-no-wrap">{{ report.percentage }}% of devices</div>
                        </template>
                      </v-slider>
                    </v-row>
                    <v-row>
                      <v-checkbox label="Unique Devices" v-model="report.uniqueDevices" />
                    </v-row>
                  </v-container>
                </v-window-item>
              </v-window>
            </v-col>
            <v-col class="py-0" v-if="!isSmall">
              <study-feature-map width="600px" height="450px" :study-area="report.studyArea" product="pp" key="pp-feature-map" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-0 small-actions" v-if="isSmall">
      <v-btn class="ma-0" color="error" depressed large tile width="50%" @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn class="ma-0" color="primary" depressed large tile width="50%" :loading="pending" @click="checkout" :disabled="!canCheckout">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn id="create-poi-cancel" color="error" text large @click="cancel" v-if="step === 1">{{ $tc('cancel') }}</v-btn>
      <v-btn id="create-poi-back" color="secondary" text large @click="step--" v-else>{{ $tc('back') }}</v-btn>
      <v-spacer />
      <v-btn id="create-poi-next" color="primary" text large @click="step++" v-if="step === 1">{{ $tc('next') }}</v-btn>
      <v-btn id="report-submit" color="primary" depressed large :loading="pending" @click="checkout" :disabled="!canCheckout" v-else>{{ $tc('submit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { area } from '@turf/turf';
import { mapState, mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import alert from '@/mixins/alert';
import StudyFeatureMap from '@/components/StudyFeatureMap';
import DateSelect from '@/components/DateSelect';
import StudyAreaSelect from '@/components/StudyAreaSelect';

export default {
  name: 'ShopAd',

  mixins: [alert],

  props: {
    organization: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    StudyFeatureMap,
    DateSelect,
    StudyAreaSelect
  },

  data() {
    return {
      formValid: false,
      pending: false,
      step: 1,
      report: {
        organization: {},
        name: '',
        description: '',
        tags: [],
        dates: [],
        studyArea: '',
        percentage: 100,
        pointTypes: ['EP', 'TP', 'HP', 'WP', 'HWP'],
        groupBy: 'day',
        uniqueDevices: true,
        visualize: true
      },
      groups: [{
        text: 'Hourly',
        value: 'hour'
      }, {
        text: 'Daily',
        value: 'day'
      }, {
        text: 'Monthly',
        value: 'month'
      }],
      pointTypes: [{
        text: 'End Point',
        value: 'EP'
      }, {
        text: 'Transit Point',
        value: 'TP'
      }, {
        text: 'Home Point',
        value: 'HP'
      }, {
        text: 'Work Point',
        value: 'WP'
      }, {
        text: 'Home/Work Point',
        value: 'HWP'
      }],
      studyAreaArea: 0
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    selectableOrganizations() {
      if (this.isAdmin) {
        return this.organizations;
      }
      return this.organizations.filter((org) => get(org, 'products.pp.write', false));
    },

    allow10() {
      const { organization } = this.report;
      return this.isAdmin || get(organization, 'products.pp.additional.override10m', false) || this.studyAreaArea <= 17000000;
    },

    canCheckout() {
      const { organization, name = '', dates = [], studyArea } = this.report;
      return Boolean(this.isAuthenticated && !isEmpty(organization) && name.length && dates.length && !isEmpty(studyArea) && (this.canAfford || (this.isTrial && !this.hasOrders)));
    },

    availableTags() {
      return !isEmpty(this.report.organization) ? this.tags[this.report.organization.id] : [];
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    cost() {
      return 0;
    },

    creditsAvailable() {
      let creditsAvailable = 0;
      if (!this.isTrial) {
        const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
        if (orgInfo) {
          creditsAvailable = orgInfo.credits || 0;
        }
      }
      return creditsAvailable;
    },

    isTrial() {
      let isTrial = false;
      const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
      if (orgInfo) {
        isTrial = orgInfo.trial === undefined ? true : orgInfo.trial;
      }
      return isTrial;
    },

    hasOrders() {
      let hasOrders = false;
      const orgId = get(this.report.organization, 'id', '');
      if (!isEmpty(orgId)) {
        hasOrders = !isEmpty(this.orders[orgId]);
      }
      return hasOrders;
    },

    costTextColor() {
      let textColor = 'black';
      if (this.cost === 0) {
        textColor = 'black';
      } else if (this.cost < this.creditsAvailable) {
        textColor = 'success';
      } else if (this.cost === this.creditsAvailable) {
        textColor = 'warning';
      } else if (this.cost > this.creditsAvailable) {
        textColor = 'error';
      }
      return `${textColor}--text`;
    },

    canAfford() {
      return this.cost <= this.creditsAvailable;
    },

    ...mapState(['organizations', 'tags', 'orders']),
    ...mapGetters(['userId', 'isAdmin'])
  },

  watch: {
    organizations: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        if (this.isTrial) {
          this.report.dates = this.report.dates[0] || '';
        } else if (!Array.isArray(this.report.dates) && this.report.dates.length) {
          this.report.dates = [this.report.dates];
        }
      }
    },

    /* eslint-disable-next-line */
    'report.studyArea': async function (studyAreaId) {
      if (!isEmpty(studyAreaId)) {
        const { data } = await this.$services.geometry.read(studyAreaId);
        this.studyAreaArea = area(data);
      } else {
        this.studyAreaArea = 0;
      }
    },

    /* eslint-disable-next-line */
    'report.pointTypes': async function () {
      if (isEmpty(this.report.pointTypes)) {
        this.report.pointTypes = ['EP'];
      }
    }
  },

  mounted() {
    this.report.organization = this.organization;
  },

  methods: {
    async checkout() {
      try {
        let { organization: { id: organization }, name, dates, studyArea, percentage, pointTypes, groupBy, uniqueDevices, description, tags, visualize } = this.report;

        if (!Array.isArray(dates)) {
          dates = [dates];
        }

        this.pending = true;
        const order = {
          name,
          description,
          tags,
          product: 'pp',
          config: {
            studyArea,
            dates: dates.map((date) => date.map((d) => formatDate(parseISO(d), 'yyyyMMdd'))),
            percentage,
            pointTypes,
            groupBy,
            uniqueDevices,
            visualize
          }
        };
        await this.$services.orders.create({ order, organization });
        this.alertSuccess(`${name} Submitted Successfully`);
        this.cancel();
        let organizations = [];
        if (this.isAdmin) {
          const { data } = await this.$services.organizations.all();
          organizations = data;
        } else {
          const { data } = await this.$services.users.listOrganizations(this.userId);
          organizations = data;
        }
        this.setOrganizations(organizations);
        const { data: orders } = await this.$services.organizations.listOrders(organization);
        this.setOrders({ oid: organization, orders });
        const { data: newTags } = await this.$services.organizations.listTags(organization);
        this.setTags({ oid: organization, tags: newTags });
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.report = {
        organization: this.organization,
        name: '',
        description: '',
        tags: [],
        dates: [],
        studyArea: '',
        percentage: 100,
        pointTypes: ['EP', 'TP', 'HP', 'WP', 'HWP'],
        groupBy: 'day',
        uniqueDevices: true,
        visualize: true
      };
      this.step = 1;
    },

    cancel() {
      this.reset();
      this.$emit('cancel');
    },

    async setReportPois(poiIds) {
      await this.$refs.poiSelect.fetchPois();
      this.report.pois = uniq(this.report.pois.concat(poiIds));
    },

    ...mapActions(['setOrders', 'setTags', 'setOrganizations'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
