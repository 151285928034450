<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="area" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { set, groupBy, isEmpty } from 'lodash';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: [String, Number],
      default: 250
    },

    x: {
      type: String,
      default: '',
      required: true
    },

    y: {
      type: String,
      default: 'count'
    },

    groupBy: {
      type: String,
      default: ''
    },

    xFormatter: {
      type: Function,
      default: (v) => v
    },

    formatLegend: {
      type: Boolean,
      default: false
    },

    horizontal: {
      type: Boolean,
      default: false
    },

    hideExport: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    options() {
      const options = {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          curve: 'smooth'
        },
        grid: {
          show: false
        },
        colors: ['#B80C09', '#622E3B', '#463773', '#0B4F6C', '#0DAADD', '#FCE056', '#257343', '#424A51', '#040F16'],
        legend: {
          show: true
        },
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          followCursor: true,
          y: {
            formatter: v => v ? mk(v) : ''
          }
        },
        xaxis: {
          categories: !isEmpty(this.x) ? this.data.map((d) => d[this.x]) : [],
          axisTicks: {
            show: this.horizontal
          },
          axisBorder: {
            show: this.horizontal
          },
          labels: {
            show: true,
            formatter: v => v ? mk(v) : ''
          }
        },
        yaxis: {
          labels: {
            show: this.horizontal,
            formatter: v => v ? mk(v) : ''
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
      if (typeof this.xFormatter === 'function') {
        if (this.formatLegend) {
          options.xaxis.categories = options.xaxis.categories.map((x) => this.xFormatter(x));
        } else {
          set(options, 'tooltip.x.formatter', this.xFormatter);
        }
      }
      return options;
    },

    series() {
      const series = [];
      if (!isEmpty(this.y)) {
        if (!isEmpty(this.groupBy)) {
          const groupedData = groupBy(this.data, this.groupBy);
          Object.entries(groupedData).forEach(([name, data]) => {
            series.push({ name, data: data.map(d => d[this.y]) });
          });
        } else {
          series.push({ name: !isEmpty(this.yName) ? this.yName : undefined, data: this.data.map(d => d[this.y]) });
        }
      }
      return series;
    }
  }
};
</script>
