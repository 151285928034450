<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="400" max-height="400" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="mr-3">
        <v-icon left>mdi-map</v-icon>
        <span>{{ selectedCountyNames }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="poiFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 0)" />
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <template v-if="filteredPoiOptions.length === 0">
        <v-list-item>
          <v-list-item-content>
            <div class="text-center font-italic font-weight-light">No Counties Found</div>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-for="item in filteredPoiOptions" :key="item.fips" @click="selectCounty(item.fips)">
          <v-list-item-action>
            <v-icon color="primary" v-if="value.includes(item.fips)">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: Array,
      default: () => []
    },

    limit: {
      type: [Number, String],
      default: 0
    }
  },

  data() {
    return {
      menu: false,
      poiFilter: ''
    };
  },

  computed: {
    selectedCountyNames() {
      const counties = this.pois.filter((p) => this.value.includes(p.fips));
      const [firstCounty, ...otherCounties] = counties;
      if (!isEmpty(firstCounty)) {
        if (!isEmpty(otherCounties)) {
          return `${firstCounty.name} +${otherCounties.length}`;
        }
        return firstCounty.name;
      }
      return '';
    },

    filteredPoiOptions() {
      if (this.poiFilter !== null && this.poiFilter.length) {
        return this.pois.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.label.toLowerCase().includes(this.poiFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.pois;
    },

    ...mapGetters('visualize', ['pois'])
  },

  methods: {
    clearSearch() {
      this.poiFilter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      this.poiFilter = '';
      this.menu = false;
    },

    selectCounty(fips) {
      const limit = parseInt(this.limit, 10);
      const index = this.value.indexOf(fips);
      let proxy = this.value.slice(0);
      if (~index) {
        proxy.splice(index, 1);
      } else {
        proxy.push(fips);
      }
      if (limit > 0) {
        proxy = proxy.slice(0, limit);
      }
      this.$emit('input', proxy);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
