<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="line" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import groupBy from 'lodash/groupBy';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    y: {
      type: String,
      default: 'extrapolated_devices'
    },

    group: {
      type: String,
      default: 'name'
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        grid: {
          show: false
        },
        tooltip: {
          marker: {
            show: true
          },
          onDatasetHover: {
            highlightDataSeries: false
          }
        },
        colors: [
          '#003F5C',
          '#F4EF88',
          '#2F4B7C',
          '#20A496',
          '#665191',
          '#A05195',
          '#2EC0F9',
          '#D45087',
          '#F95D6A',
          '#FF7C43',
          '#FFA600',
          '#14FFF7'
        ],
        yaxis: {
          min: 0,
          labels: {
            formatter: (v) => v ? mk(v) : '0'
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        xaxis: {
          categories: this.data.length ? this.data.map((d) => format(parseISO(d.study_date), 'MMM yyyy')) : [],
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true
          }
        }
      };
    },

    series() {
      return Object.entries(groupBy(this.data, this.group)).reduce((series, [name, datum]) => {
        series.push({
          name,
          data: datum.map((d) => d[this.y])
        });
        return series;
      }, []);
    }
  }
};
</script>
