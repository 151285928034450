var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('data-fetcher',{attrs:{"height":"350","report":"getSetSummaries","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('poi-summary-comparison',{attrs:{"data":data}})]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('data-fetcher',{attrs:{"height":"350","report":"getPoiVisitsBySet","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('trend-daily-comparison',{attrs:{"height":"350","title":"Daily Visitation Pattern","data":data}})]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('data-fetcher',{attrs:{"height":"350","report":"getSetVisitsByWeekday","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('weekday-visits-comparison',{attrs:{"height":"350","title":"Weekday Visitation","data":data}})]}}])})],1)],1),_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('data-fetcher',{attrs:{"height":"350","report":"getSetVisitsByHour","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('hourly-visits-comparison',{attrs:{"height":"350","title":"Hourly Visitation","data":data}})]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('trade-area-comparison',{attrs:{"height":"350","title":"Trade Area"}}),_c('v-card-text',[_c('data-fetcher',{attrs:{"height":"350","report":"getSetVisitPercentageByDistance","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('visit-percentage-comparison',{attrs:{"height":"350","title":"Visit Percentage by Distance","data":data}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('data-fetcher',{attrs:{"height":"350","report":"getSetMarketShares","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('market-share-comparison',{attrs:{"height":"350","title":"Market Share","data":data}})]}}])})],1)],1),_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('data-fetcher',{attrs:{"height":"350","report":"getSetVisitDuration","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('visit-duration-comparison',{attrs:{"height":"350","title":"Visit Duration","data":data}})]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Demographics")]),_c('data-fetcher',{attrs:{"height":"350","report":"getSetEthnicity","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('ethnicity-comparison',{attrs:{"height":"350","title":"Ethnicity","data":data}})]}}])}),_c('data-fetcher',{attrs:{"height":"350","report":"getSetGenderAge","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('gender-age-comparison',{attrs:{"height":"350","title":"Gender & Age","data":data}})]}}])}),_c('data-fetcher',{attrs:{"height":"350","report":"getSetIncomeLevels","filter":{ poiIds: _vm.poiIds, studyDates: _vm.studyDates },"default-data":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('income-comparison',{attrs:{"height":"350","title":"Income","data":data}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }