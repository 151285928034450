<template>
  <v-list>
    <v-divider />
    <v-list-item class="pb-0">
      <v-list-item-content>
        <v-text-field v-model="itemFilter" placeholder="Search" hide-details dense single-line filled rounded clearable />
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <div class="home-location-list-container">
      <v-list-item v-for="(item, index) in filteredItemsOptions" :key="item.fips" @click="$emit('activate', index)">
        <v-list-item-action>
          <v-icon color="primary" @click.stop="selectItem(item.fips)" v-if="indeterminate.includes(item.fips)">mdi-minus-box</v-icon>
          <v-icon color="primary" @click.stop="selectItem(item.fips)" v-else-if="value.includes(item.fips)">mdi-checkbox-marked</v-icon>
          <v-icon @click="selectItem(item.fips)" v-else>mdi-checkbox-blank-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },

    limit: {
      type: [Number, String],
      default: 0
    },

    items: {
      type: Array,
      default: () => []
    },

    activeItem: {
      type: Number,
      default: 0
    },

    indeterminate: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      itemFilter: ''
    };
  },

  computed: {
    filteredItemsOptions() {
      if (!isEmpty(this.itemFilter)) {
        return this.items.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.itemFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.items;
    }
  },

  methods: {
    clearSearch() {
      this.itemFilter = '';
    },

    selectItem(fips) {
      const limit = parseInt(this.limit, 10);
      const index = this.value.indexOf(fips);
      let proxy = this.value.slice(0);
      if (~index) {
        proxy.splice(index, 1);
      } else {
        proxy.push(fips);
      }
      if (limit > 0) {
        proxy = proxy.slice(0, limit);
      }
      this.$emit('input', proxy);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.home-location-list-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>
