<template>
  <apexchart ref="chart" type="heatmap" :height="height" :options="options" :series="series" />
</template>

<script>
import groupBy from 'lodash/groupBy';
import { scaleQuantile } from 'd3-scale';
import { mk } from '@/lib/utils';

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: [String, Number],
      default: 600
    }
  },

  computed: {
    options() {
      const colors = ['#33D0FF', '#0DAADD', '#0084B7', '#005E91', '#00376A', '#001144'];
      let ranges = [];
      if (this.data.length) {
        let thresholds = scaleQuantile().domain(this.data.map((d) => d.count)).range(colors);
        thresholds = [0].concat(thresholds.quantiles(), Math.max(...this.data.map((d) => d.count))).map((t) => Math.round(t));
        ranges = thresholds.reduce((ranges, threshold, index) => {
          if (index < thresholds.length - 1) {
            ranges.push({
              from: threshold,
              to: thresholds[index + 1] - (index === thresholds.length - 2 ? 0 : 1),
              color: colors[index]
            });
          }
          return ranges;
        }, []);
      }
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (v) => v ? mk(v) : ''
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges
            }
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          title: {
            text: 'Destination County',
            style: {
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
              fontWeight: 500
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          title: {
            text: 'Origin County',
            style: {
              fontSize: '16px',
              fontFamily: '"Roboto", sans-serif',
              fontWeight: 500
            }
          }
        }
      };
    },

    series() {
      const totals = [];
      if (this.data.length) {
        const groupedData = groupBy(this.data, 'origin');
        Object.entries(groupedData).reverse().forEach(([origin, data]) => {
          const trips = data.map((t) => {
            return { x: t.destination, y: t.count };
          });
          totals.push({
            name: origin,
            data: trips
          });
        });
      }
      return totals;
    }
  },

  filters: {
    withCommas(value) {
      return Number(value).toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 });
    }
  }
};
</script>
