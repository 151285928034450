var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getCountByTimeOfDay","filter":{ studyDates: _vm.filteredDateRange, poiIds: _vm.filteredPois, aggregation: _vm.filteredAggregation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-bar-chart',{attrs:{"title":"Trips By Time of Day","data":data,"x":"hour","x-formatter":_vm.formatHour,"format-legend":""}})]}}])})],1),_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getCountByTripPurpose","filter":{ studyDates: _vm.filteredDateRange, poiIds: _vm.filteredPois, aggregation: _vm.filteredAggregation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-bar-chart',{attrs:{"title":"Trips By Purpose","data":data,"x":"purpose","x-formatter":_vm.formatPurpose}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getTripPurposeByTimeOfDay","filter":{ studyDates: _vm.filteredDateRange, poiIds: _vm.filteredPois, aggregation: _vm.filteredAggregation }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-area-chart',{attrs:{"title":"Trip Purpose By Time of Day","data":data,"x":"hour","x-formatter":_vm.formatHour,"group-by":"purpose","format-legend":""}})]}}])})],1),_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getTripPurposeByDayOfWeek","filter":{ studyDates: _vm.filteredDateRange, poiIds: _vm.filteredPois }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-column-chart',{attrs:{"title":"Trip Purpose By Day of Week","data":data,"x":['Mon_Tue_Wed_Thu', 'Fri', 'Sat', 'Sun'],"group-by":"purpose","x-formatter":_vm.formatDayOfWeek,"format-legend":""}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }