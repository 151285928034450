<template>
  <v-container pt-0>
    <v-toolbar flat>
      <v-btn text @click="close">
        <v-icon left>mdi-arrow-left</v-icon>
        <span>{{ $tc('back') }}</span>
      </v-btn>
      <v-spacer />
      <v-btn text @click="comparing = true" v-if="!comparing">
        <v-icon left>mdi-select-compare</v-icon>
        <span>Compare</span>
      </v-btn>
      <v-btn text @click="comparing = false" v-if="comparing">
        <v-icon left>mdi-information-outline</v-icon>
        <span>Summary</span>
      </v-btn>
      <v-btn text :loading="exporting" @click="exportOrder">
        <v-icon left>mdi-download</v-icon>
        <span>{{ $tc('download') }}</span>
      </v-btn>
    </v-toolbar>
    <template v-if="comparing">
      <trip-comparison />
    </template>
    <template v-else>
      <study-summary />
      <study-matrix />
      <study-insights />
    </template>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import alert from '@/mixins/alert';
import StudyMatrix from '@/components/studies/nwtm/reports/StudyMatrix';
import StudySummary from '@/components/studies/nwtm/reports/StudySummary';
import StudyInsights from '@/components/studies/nwtm/reports/StudyInsights';
import TripComparison from '@/components/studies/nwtm/reports/TripComparison';

export default {
  mixins: [alert],

  components: {
    StudyMatrix,
    StudySummary,
    StudyInsights,
    TripComparison
  },

  data() {
    return {
      pending: false,
      exporting: false,
      comparing: false
    };
  },

  watch: {
    comparing() {
      if (!this.comparing) {
        this.selectedCounties = this.pois.map(f => f.fips).slice(0, 10);
      }
    }
  },

  computed: {
    selectedCounties: {
      get() {
        return this.filteredPois;
      },

      set(value) {
        this.setPoiFilter(value);
      }
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['pois', 'filteredPois'])
  },

  methods: {
    close() {
      this.reset();
      this.$router.push({ name: 'main' });
    },

    async exportOrder() {
      try {
        const { id, downloadUrl } = this.study;
        if (downloadUrl) {
          window.location = downloadUrl;
        } else {
          this.exporting = true;
          const { data } = await this.$services.orders.export(id);
          window.location = data;
          this.study.downloadUrl = data;
          this.setOrderProperty({ id, key: 'downloadUrl', value: data });
        }
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.exporting = false;
      }
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['reset', 'setPoiFilter'])
  }
};
</script>
