<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="area" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import uniq from 'lodash/uniq';
import concat from 'lodash/concat';
import mapValues from 'lodash/mapValues';
import findLastIndex from 'lodash/findLastIndex';
import cloneDeep from 'lodash/cloneDeep';
import chart from '@/mixins/chart';
import { formatPercent } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    distances() {
      return uniq(concat(...Object.values(this.data).map((resultSet) => resultSet.map((rs) => rs.distance)))).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    },

    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 4,
          curve: 'smooth'
        },
        grid: {
          show: false
        },
        colors: Object.values(this.comparisonColors),
        legend: {
          show: false
        },
        xaxis: {
          categories: this.distances,
          tickAmount: 20,
          labels: {
            show: true,
            formatter(v) {
              return `${v} mi`;
            }
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: true,
            formatter(v) {
              return `${formatPercent(v)}%`;
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    },

    series() {
      const totals = [];
      const data = mapValues(cloneDeep(this.data), (dataSet) => {
        this.distances.forEach((distance) => {
          if (!~dataSet.findIndex((r) => r.distance === distance)) {
            const lastIndex = findLastIndex(dataSet, (r) => r.distance < distance);
            if (~lastIndex) {
              const record = dataSet[lastIndex];
              if (record) {
                const { percentage } = record;
                dataSet.splice(lastIndex + 1, 0, { percentage, distance });
              } else {
                dataSet.splice(0, 0, { percentage: 0, distance: 0 });
              }
            } else {
              dataSet.splice(0, 0, { percentage: 0, distance: 0 });
            }
          }
        });
        return dataSet;
      });

      Object.entries(data).sort((a, b) => {
        const keyA = a[0].toLowerCase();
        const keyB = b[0].toLowerCase();
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }).forEach(([dataSet, d]) => {
        totals.push({
          name: this.comparisonNames[dataSet],
          data: d.map((r) => r.percentage)
        });
      });
      return totals;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
