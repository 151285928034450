<template>
  <v-sheet>
    <v-row>
      <v-col>
        <v-card outlined>
          <data-fetcher height="350" report="getSetSummaries" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <poi-summary-comparison :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <data-fetcher height="350" report="getPoiVisitsBySet" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <trend-daily-comparison height="350" title="Daily Visitation Pattern" :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <data-fetcher height="350" report="getSetVisitsByWeekday" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <weekday-visits-comparison height="350" title="Weekday Visitation" :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <data-fetcher height="350" report="getSetVisitsByHour" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <hourly-visits-comparison height="350" title="Hourly Visitation" :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <trade-area-comparison height="350" title="Trade Area" />
          <v-card-text>
            <data-fetcher height="350" report="getSetVisitPercentageByDistance" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
              <visit-percentage-comparison height="350" title="Visit Percentage by Distance" :data="data" />
            </data-fetcher>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <data-fetcher height="350" report="getSetMarketShares" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <market-share-comparison height="350" title="Market Share" :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <data-fetcher height="350" report="getSetVisitDuration" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <visit-duration-comparison height="350" title="Visit Duration" :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-title class="headline">Demographics</v-card-title>
          <data-fetcher height="350" report="getSetEthnicity" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <ethnicity-comparison height="350" title="Ethnicity" :data="data" />
          </data-fetcher>
          <data-fetcher height="350" report="getSetGenderAge" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <gender-age-comparison height="350" title="Gender & Age" :data="data" />
          </data-fetcher>
          <data-fetcher height="350" report="getSetIncomeLevels" :filter="{ poiIds, studyDates }" :default-data="{}" v-slot="{ data }">
            <income-comparison height="350" title="Income" :data="data" />
          </data-fetcher>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

import DataFetcher from '@/components/charts/DataFetcher';
import PoiSummaryComparison from '@/components/charts/PoiSummaryComparison';
import TrendDailyComparison from '@/components/charts/TrendDailyComparison';
import WeekdayVisitsComparison from '@/components/charts/WeekdayVisitsComparison';
import HourlyVisitsComparison from '@/components/charts/HourlyVisitsComparison';
import MarketShareComparison from '@/components/charts/MarketShareComparison';
import IncomeComparison from '@/components/charts/IncomeComparison';
import EthnicityComparison from '@/components/charts/EthnicityComparison';
import GenderAgeComparison from '@/components/charts/GenderAgeComparison';
import TradeAreaComparison from '@/components/charts/TradeAreaComparison';
import VisitPercentageComparison from '@/components/charts/VisitPercentageComparison';
import VisitDurationComparison from '@/components/charts/VisitDurationComparison';

export default {
  components: {
    DataFetcher,
    PoiSummaryComparison,
    TrendDailyComparison,
    WeekdayVisitsComparison,
    HourlyVisitsComparison,
    MarketShareComparison,
    IncomeComparison,
    EthnicityComparison,
    GenderAgeComparison,
    TradeAreaComparison,
    VisitPercentageComparison,
    VisitDurationComparison
  },

  computed: {
    poiIds() {
      return JSON.stringify(this.filteredPois);
    },

    studyDates() {
      return this.filteredDateRange;
    },

    ...mapGetters('visualize', ['filteredPois', 'filteredDateRange'])
  }
};
</script>
