<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    limit: {
      type: Number,
      default: undefined
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: 0
        },
        grid: {
          show: false
        },
        colors: ['#014E68', '#02698B', '#0DAADD'],
        legend: {
          show: true,
          position: 'top'
        },
        tooltip: {
          shared: false
        },
        xaxis: {
          categories: this.data.length ? this.data.filter((d) => d.percent_over_2_hours > 0).slice(0, this.limit).map((d) => d.state) : [],
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          }
        },
        yaxis: {
          labels: {
            show: false,
            formatter(v) {
              return v ? `${v.toFixed(0)}%` : '0%';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    },

    series() {
      return this.data.filter((d) => d.percent_over_2_hours > 0).slice(0, this.limit).reduce((series, d) => {
        series[0].data.push(((1 - Number(d.percent_over_2_hours).toFixed(2)) * 100));
        series[1].data.push(((Number(d.percent_over_2_hours).toFixed(2) - Number(d.percent_over_4_hours).toFixed(2)) * 100));
        series[2].data.push((Number(d.percent_over_4_hours).toFixed(2) * 100));
        return series;
      }, [{
        name: 'Passthrough',
        type: 'column',
        data: []
      }, {
        name: 'Quick Trip',
        type: 'column',
        data: []
      }, {
        name: 'Daytrip',
        type: 'column',
        data: []
      }]);
    }
  }
};
</script>
