const moveToMapPosition = (master, clones) => {
  const center = master.getCenter();
  const zoom = master.getZoom();
  const bearing = master.getBearing();
  const pitch = master.getPitch();

  clones.forEach((clone) => {
    clone.jumpTo({ center, zoom, bearing, pitch });
  });
};

const _default = (maps = []) => {
  let fns = [];

  const on = () => {
    maps.forEach((map, index) => {
      map.on('move', fns[index]);
    });
  };

  const off = () => {
    maps.forEach((map, index) => {
      map.off('move', fns[index]);
    });
  };

  const sync = (master, clones) => {
    off();
    moveToMapPosition(master, clones);
    on();
  };

  maps.forEach((map, index) => {
    fns[index] = sync.bind(null, map, maps.filter((o, i) => { return i !== index; }));
  });

  on();
  return () => { off(); fns = []; maps = []; };
};

export { _default as default };
