import { mapActions } from 'vuex';
import { captureException } from '@sentry/browser';

export default {
  methods: {
    alertError(error, bypass = false) {
      if (!bypass) {
        captureException(error);
      }
      this.setAlert({
        color: 'error',
        message: error.response ? error.response.data.message : error.message || error.description || error.errorDescription || error
      });
    },

    alertSuccess(message) {
      this.setAlert({
        color: 'success',
        message
      });
    },

    ...mapActions(['setAlert'])
  }
};
