import UserService from './users';
import OrganizationService from './organizations';
import GeometryService from './geometry';
import OrderService from './orders';
import IngestService from './ingest';

export default {
  install(Vue) {
    const services = {
      users: new UserService(),
      organizations: new OrganizationService(),
      geometry: new GeometryService(),
      orders: new OrderService(),
      ingest: new IngestService()
    };

    Vue.prototype.$services = services;
  }
};
