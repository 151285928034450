import upperFirst from 'lodash/upperFirst';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import max from 'lodash/max';
import omit from 'lodash/omit';
import { Popup } from 'mapbox-gl';
import { feature, featureCollection } from '@turf/turf';
import { mk } from '@/lib/utils';
import { tileSets, promoteIdMap } from '@/config';

class TradeAreaWinnerManager {
  hoverStateId;

  map;

  countries;

  level = 'blockgroup';

  constructor(map) {
    this.map = map;
    this.countries = Object.keys(tileSets);
    this.loadTileSets();
  }

  loadTileSets() {
    this.countries.forEach((country) => {
      const url = tileSets[country][this.level];
      const key = `${country}${upperFirst(this.level)}`;
      if (this.map && !this.map.getSource(key)) {
        let promoteId = JSON.parse(`{ "${this.level}": "${promoteIdMap[key]}" }`);
        this.map.addSource(key, { type: 'vector', url, promoteId });
      }
    });
  }

  removeLayer() {
    this.countries.forEach((country) => {
      const key = `${country}${upperFirst(this.level)}`;
      let layerId = `${key}TradeAreaShapes`;
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
      layerId = `${key}TradeAreaLines`;
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
    });
  }

  renderLayer(data, pois, comparisonColors, comparisonNames) {
    this.removeLayer();
    if (!isEmpty(data)) {
      this.countries.forEach((country) => {
        const key = `${country}${upperFirst(this.level)}`;
        const getColor = ({
          extrapolated_devices_a: extrapolatedDevicesA = 0,
          extrapolated_devices_b: extrapolatedDevicesB = 0,
          extrapolated_devices_c: extrapolatedDevicesC = 0,
          extrapolated_devices_d: extrapolatedDevicesD = 0
        }) => {
          let highest = 'a';
          if (extrapolatedDevicesB > max([extrapolatedDevicesA, extrapolatedDevicesC, extrapolatedDevicesD])) {
            highest = 'b';
          }
          if (extrapolatedDevicesC > max([extrapolatedDevicesA, extrapolatedDevicesB, extrapolatedDevicesD])) {
            highest = 'c';
          }
          if (extrapolatedDevicesD > max([extrapolatedDevicesA, extrapolatedDevicesB, extrapolatedDevicesC])) {
            highest = 'd';
          }
          return comparisonColors[highest] || '#FFFFFF';
        };
        const blockgroupInfo = data.reduce((info, d) => {
          const { fips, county, state, tract, ...extrapolatedDevices } = d;
          const dataSets = Object.keys(extrapolatedDevices).map((ed) => last(ed.split('_')));
          dataSets.forEach((dataSet) => {
            set(info, `${fips}.${dataSet}`, extrapolatedDevices[`extrapolated_devices_${dataSet}`]);
          });
          set(info, `${fips}.color`, getColor(extrapolatedDevices));
          set(info, `${fips}.tract`, tract);
          set(info, `${fips}.county`, county);
          set(info, `${fips}.state`, state);
          return info;
        }, {});

        const expression = ['coalesce', ['get', 'color', ['get', ['get', 'GEOID10'], ['literal', blockgroupInfo]]], '#FFFFFF'];
        const shapes = {
          id: `${key}TradeAreaShapes`,
          type: 'fill',
          source: key,
          'source-layer': this.level,
          paint: {
            'fill-color': expression,
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.92, 0.67]
          },
          filter: ['in', 'GEOID10', ...Object.keys(blockgroupInfo)]
        };
        const lines = {
          id: `${key}TradeAreaLines`,
          type: 'line',
          source: key,
          'source-layer': this.level,
          paint: {
            'line-color': expression,
            'line-width': 1,
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.8]
          },
          filter: ['in', 'GEOID10', ...Object.keys(blockgroupInfo)]
        };
        this.map.addLayer(shapes);
        this.map.addLayer(lines);

        const popUp = new Popup({
          closeButton: false,
          closeOnClick: false
        });
        this.map.on('click', `${key}TradeAreaShapes`, (e) => {
          const [feature] = e.features;
          if (feature) {
            const info = blockgroupInfo[feature.id];
            if (info) {
              const infoHTML = Object.entries(omit(info, ['tract', 'county', 'state', 'color'])).map(([dataSet, count]) => {
                return `<div><b><span style="color: ${comparisonColors[dataSet]};">${comparisonNames[dataSet]}</span>: </b>${mk(count)}</div>`;
              }).join('\n');
              popUp.setLngLat(e.lngLat).setHTML(`
                <div class="v-card v-card--flat v-sheet">
                  <div class="v-card__text pa-0">
                    <div class="headline">${feature.properties.NAMELSAD10}</div>
                    <div class="caption mb-0">${info.tract}</div>
                    <div class="caption mb-2">${info.county}, ${info.state}</div>
                    ${infoHTML}
                  </div>
                </div>
              `).addTo(this.map);
            }
          }
        });

        this.map.on('mousemove', `${key}TradeAreaShapes`, (e) => {
          const [feature] = e.features;
          if (feature) {
            const { id } = feature;
            if (id) {
              this.map.getCanvas().style.cursor = 'pointer';
              if (this.hoverStateId) {
                this.map.setFeatureState({ source: key, sourceLayer: this.level, id: this.hoverStateId }, { hover: false });
              }
              this.hoverStateId = id;
              this.map.setFeatureState({ source: key, sourceLayer: this.level, id: this.hoverStateId }, { hover: true });
            }
          }
        });

        this.map.on('mouseleave', `${key}TradeAreaShapes`, (e) => {
          popUp.remove();
          this.map.getCanvas().style.cursor = '';
          if (this.hoverStateId) {
            this.map.setFeatureState({ source: key, sourceLayer: this.level, id: this.hoverStateId }, { hover: false });
          }
          this.hoverStateId = null;
        });
      });
      const features = [];
      pois.forEach((poi) => {
        const { geom } = poi;
        features.push(feature(geom));
      });
      this.map.centerOn(featureCollection(features));
    }
  }
}

export default TradeAreaWinnerManager;
