<template>
   <v-select ref="studyAreaSelect" v-model="proxy" prepend-icon="mdi-map" :loading="searching" :menu-props="{ maxHeight: 400 }" :items="studyAreas" label="Study Area" no-data-text="No Areas Found" item-text="name" item-value="id" @focus="focusSearch" @blur="clearSearch">
    <template v-slot:prepend-item>
      <!-- POI Search -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="studyAreaFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 0)" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon dark @click="closeSelect">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
    </template>
    <template v-slot:selection="{ item }">
      <!-- Selection -->
      <span>{{ item.name }}</span>
      <span class="ml-1 font-italic font-weight-light grey--text">({{ item.class }})</span>
    </template>
    <template v-slot:item="data">
      <!-- Study Area Item -->
      <v-list-item-content>
        <v-list-item-title>
          <span>{{ data.item.name }}</span>
          <span class="ml-1 font-italic font-weight-light grey--text">({{ data.item.class }})</span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: String,
      default: ''
    },

    organization: {
      type: [String, Object],
      default: ''
    }
  },

  data() {
    return {
      proxy: [],
      studyAreaFilter: '',
      searching: false,
      call: null,
      studyAreas: []
    };
  },

  computed: {
    organizationId() {
      let oid = null;
      if (!isEmpty(this.organization)) {
        oid = this.organization;
        if (isObject(this.organization)) {
          oid = this.organization.id;
        }
      }
      return oid;
    }
  },

  watch: {
    proxy() {
      if (this.value !== this.proxy) {
        this.$emit('input', this.proxy);
      }
    },

    value() {
      if (this.value !== this.proxy) {
        this.proxy = this.value;
      }
    },

    studyAreaFilter(value) {
      if (value) {
        this.searchStudyAreas(value);
      }
    }
  },

  methods: {
    async searchStudyAreas(term) {
      const { CancelToken, isCancel } = this.$services.geometry;
      try {
        this.searching = true;
        if (this.call) {
          this.call.cancel();
        }
        this.call = CancelToken.source();
        const { data } = await this.$services.geometry.search({
          params: {
            term,
            studyArea: true,
            organization: this.organizationId
          },
          cancelToken: this.call.token
        });
        this.studyAreas = data;
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      } finally {
        this.searching = false;
      }
    },

    clearSearch() {
      this.studyAreaFilter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      if (this.$refs.studyAreaSelect) {
        this.$refs.studyAreaSelect.blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list.v-sheet.v-sheet--tile[role="listbox"] {
  padding-top: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
