<template>
  <v-card class="mb-5" outlined>
    <v-card-title class="study-name">
      <v-text-field class="rename-study-input text-body-1" v-model="studyName" v-if="renaming" append-icon="mdi-check"
        clearable outlined hide-details dense @click:clear="cancelRename" @click:append="rename" />
      <div class="display-1" v-else>{{ study.name }}</div>
      <v-btn class="rename-icon" icon @click="startRenaming" v-show="!renaming">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer />
      <study-date-select />
    </v-card-title>
    <v-card-title class="pt-0">
      <v-spacer />
      <div>
        <multi-poi-select v-for="(group, key, index) in comparisonGroups" :key="key" :value="poiGroups[key]"
          @input="setGroupPois(key, $event)" @rename="setGroupName({ group: key, name: $event })"
          :icon="`mdi-alpha-${key}`" :color="group.color"
          :is-last="index === Object.keys(comparisonGroups).length - 1" />
      </div>
    </v-card-title>
    <div ref="map" id="tla-compare-pois-map" class="map" style="height: 250px;"></div>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Marker, Popup } from 'mapbox-gl';
import { feature, center, getCoord, featureCollection } from '@turf/turf';
import Map from '@/lib/map';
import alert from '@/mixins/alert';

import MultiPoiSelect from '@/components/ComparisonMultiPoiSelect';
import StudyDateSelect from '@/components/StudyDateSelect';

export default {
  mixins: [alert],

  components: {
    MultiPoiSelect,
    StudyDateSelect
  },

  data() {
    return {
      map: null,
      ready: false,
      loading: false,
      markers: [],
      studyName: '',
      renaming: false
    };
  },

  computed: {
    poiGroups() {
      return this.filteredPois;
    },

    ...mapState('visualize', ['study', 'comparisonGroups']),
    ...mapGetters('visualize', ['pois', 'filteredPois'])
  },

  watch: {
    filteredPois: {
      deep: true,
      handler() {
        this.loadPoiMarkers();
      }
    }
  },

  async mounted() {
    this.init();
  },

  methods: {
    setGroupPois(group, pois) {
      this.setPoiFilter({ group, pois });
    },

    init() {
      this.map = new Map(this.$refs.map, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        dark: this.dark,
        maxZoom: 12,
        eventHandlers: {
          'style.load': async () => {
            this.ready = true;
            this.loadPoiMarkers();
          }
        }
      });
    },

    loadPoiMarkers() {
      try {
        if (this.map) {
          this.loading = true;
          const allPois = [];
          // remove all markers from the map
          this.markers.forEach((m) => m.remove());
          this.markers = [];
          // remove existing shapes from the map
          Object.keys(this.comparisonGroups).forEach((g) => {
            [`poiShapes${g.toUpperCase()}`, `poiLines${g.toUpperCase()}`].forEach((layerId) => {
              if (this.map.getLayer(layerId)) {
                this.map.removeLayer(layerId);
              }
            });
            const source = `POIS${g.toUpperCase()}`;
            if (this.map.getSource(source)) {
              this.map.removeSource(source);
            }

            // add markers
            this.pois.filter((poi) => this.filteredPois[g].includes(poi.id)).forEach((poi) => {
              const { color } = this.comparisonGroups[g];
              const { geom, ...properties } = poi;
              const poiFeature = feature(geom, properties);
              allPois.push(poiFeature);
              const centerOfPoi = getCoord(center(poiFeature));
              const popup = new Popup({
                closeButton: false,
                closeOnClick: true
              }).setHTML(`
                <div class="v-card v-card--flat v-sheet">
                  <div class="v-card__text pa-0">
                    <div class="headline">${properties.name}</div>
                    <div class="subheading mb-1">${properties.label}</div>
                  </div>
                </div>
              `);
              this.markers.push(new Marker({ color }).setLngLat(centerOfPoi).setPopup(popup));
            });
          });
          // add markers to map
          this.markers.forEach((m) => m.addTo(this.map));
          // center map on all POIs
          this.map.centerOn(featureCollection(allPois));
        }
      } catch (error) {
        this.alertError(error);
      } finally {
        this.loading = false;
      }
    },

    startRenaming() {
      this.studyName = this.study.name;
      this.renaming = true;
    },

    async rename() {
      this.loading = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.studyName });
        this.alertSuccess(`${name} renamed to ${this.studyName}`);
        this.setStudyName(this.studyName);
        this.setOrderProperty({ id, key: 'name', value: this.studyName });
        this.cancelRename();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.loading = false;
      }
    },

    cancelRename() {
      this.renaming = false;
      this.studyName = this.study.name;
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['setPoiFilter', 'setGroupName', 'setStudyName'])
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
  height: 250px;
}

.rename-icon {
  visibility: hidden;
}

.study-name:hover {
  .rename-icon {
    visibility: visible;
  }
}

.rename-study-input {
  max-width: 33%;
}
</style>
