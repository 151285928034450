import { feature } from '@turf/turf';

class StudyAreaLayerManager {
  map;

  studyArea;

  constructor(map, studyArea) {
    this.map = map;
    this.studyArea = studyArea;
    this.renderLayer();
  }

  renderLayer() {
    const { geom, ...properties } = this.studyArea;
    this.map.addSource('STUDYAREA', {
      type: 'geojson',
      data: feature(geom, properties)
    });

    this.map.addLayer({
      id: 'studyareaShape',
      type: 'fill',
      source: 'STUDYAREA',
      paint: {
        'fill-antialias': true,
        'fill-color': '#00DB4A',
        'fill-opacity': 0.5
      }
    });

    this.map.addLayer({
      id: 'studyareaLine',
      type: 'line',
      source: 'STUDYAREA',
      paint: {
        'line-color': '#00A237',
        'line-width': 2,
        'line-opacity': 0.75
      }
    });
  }
}

export default StudyAreaLayerManager;
