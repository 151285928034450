<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      const hours = new Set();
      Object.values(this.data).forEach((d) => d.forEach((d) => hours.add(d.hour)));
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1
        },
        grid: {
          show: false
        },
        colors: Object.values(this.comparisonColors),
        legend: {
          show: false
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: false
        },
        xaxis: {
          categories: [...hours].map((h) => {
            h = parseInt(h, 10);
            if (h === 0) {
              h = '12a';
            } else if (h === 12) {
              h = '12p';
            } else if (h > 12) {
              h = `${h - 12}p`;
            } else {
              h = `${h}a`;
            }
            return h;
          }),
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false,
            formatter(v) {
              return v ? mk(v) : '';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    },

    series() {
      const totals = [];
      Object.entries(this.data).sort((a, b) => {
        const keyA = a[0].toLowerCase();
        const keyB = b[0].toLowerCase();
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }).forEach(([dataSet, d]) => {
        totals.push({
          name: this.comparisonNames[dataSet],
          data: d.map((r) => r.extrapolated_devices)
        });
      });
      return totals;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
