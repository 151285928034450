import MapboxGL, { Map, FullscreenControl } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import CompassControl from 'mapbox-gl-controls/lib/compass';
import ZoomControl from 'mapbox-gl-controls/lib/zoom';
import { bbox, union } from '@turf/turf';
import isEmpty from 'lodash/isEmpty';

MapboxGL.accessToken = 'pk.eyJ1Ijoiamhhd2x3dXQiLCJhIjoiY2prMno4Mjl5MGkwZDNxcXFxNHgyY2ZiNSJ9.s4lvKHjuqpsTHyN323qOAQ';

class AirSageMap extends Map {
  constructor(container, {
    style = 'https://api.maptiler.com/maps/daf8b0da-58d2-4f37-9563-1f59182f279b/style.json?key=Pt2TXgCtJ5ziGcCMTIfn',
    darkStyle = 'https://api.maptiler.com/maps/streets/style.json?key=brAh6VGoyoVO8ORd5YkY',
    dark = false,
    satelliteStyle = 'mapbox://styles/mapbox/satellite-v9',
    satellite = false,
    preserveDrawingBuffer = false,
    eventHandlers = {},
    staticMap = false,
    minZoom = 2,
    maxZoom = 18
  } = {}) {
    super({
      container,
      center: [-90.5794797, 39.8283459],
      zoom: 3.39,
      interactive: true,
      attributionControl: true,
      preserveDrawingBuffer,
      style: dark ? darkStyle : style,
      minZoom,
      maxZoom
    });

    this.id = container.id;
    this.lightStyle = style;
    this.darkStyle = darkStyle;
    this.darkMode = dark;
    this.satelliteStyle = satelliteStyle;
    this.satelliteMode = satellite;

    if (!staticMap) {
      this.addControl(new CompassControl(), 'top-right');
      this.addControl(new ZoomControl(), 'top-right');
      this.addControl(new FullscreenControl());
    }

    const drawOptions = {
      displayControlsDefault: false
    };

    this.draw = new MapboxDraw(drawOptions);
    this.addControl(this.draw, 'top-left');

    Object.entries(eventHandlers).forEach((e) => {
      const [event, handler] = e;
      this.on(event, handler);
    });
  }

  toggleDarkMode() {
    if (!this.darkMode && this.darkStyle) {
      this.setStyle(this.darkStyle);
      this.darkMode = true;
    } else {
      this.setStyle(this.lightStyle);
      this.darkMode = false;
    }
  }

  toggleSatelliteMode() {
    if (!this.satelliteMode && this.satelliteStyle) {
      this.setStyle(this.satelliteStyle);
      this.satelliteMode = true;
    } else {
      if (this.darkMode && this.darkStyle) {
        this.setStyle(this.darkStyle);
      } else {
        this.setStyle(this.lightStyle);
      }
      this.satelliteMode = false;
    }
  }

  center(padding = 15) {
    let { features } = this.draw.getAll();
    if (!isEmpty(features)) {
      features = union(...features);
      this.fitBounds(bbox(features), { padding });
    }
  }

  centerOn(feature, { padding = 15, easing, zoom, pitch } = {}) {
    const options = {
      padding
    };
    if (easing) {
      options.easing = easing;
    }
    if (zoom) {
      options.zoom = zoom;
    }
    if (pitch) {
      options.pitch = pitch;
    }
    this.fitBounds(bbox(feature), options);
  }
}

export default AirSageMap;
