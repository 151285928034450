<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="400" max-height="400" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="mr-3">
        <v-icon left>mdi-calendar-range</v-icon>
        <span>{{ selectedAggregation }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="item in aggregations" :key="item.value" @click="$emit('input', item.value)">
        <v-list-item-action>
          <v-icon color="primary" v-if="value === item.value">mdi-checkbox-marked</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import get from 'lodash/get';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      menu: false,
      aggregations: [{
        name: 'Mon - Thur',
        value: 'Mon_Tue_Wed_Thu'
      }, {
        name: 'Friday',
        value: 'Fri'
      }, {
        name: 'Saturday',
        value: 'Sat'
      }, {
        name: 'Sunday',
        value: 'Sun'
      }]
    };
  },

  computed: {
    selectedAggregation() {
      const selectedAggregation = this.aggregations.find(a => a.value === this.value);
      return get(selectedAggregation, 'name', 'None');
    }
  },

  methods: {
    closeSelect() {
      this.menu = false;
    }
  }
};
</script>

<style lang="scss">
.v-list {
  padding: 0;
}
</style>
