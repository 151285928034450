<template>
  <div>
    <v-row>
      <v-col class="text-overline mx-2">Outbound</v-col>
      <v-spacer />
      <v-col class="text-overline text-right mx-2">Inbound</v-col>
    </v-row>
    <div class="nwtm-comparison-maps-container">
      <v-progress-linear class="map-loader ma-0" indeterminate v-show="loading" />
      <v-card class="compairison-infobox" v-show="hoverStateId">
        <v-card-title>{{ activeOriginName }}</v-card-title>
        <v-card-subtitle v-if="level === 'blockgroup'">{{ activeOriginParentsName }}</v-card-subtitle>
        <v-card-text v-html="tripInfo"></v-card-text>
      </v-card>
      <div ref="map0" id="nwtm-compare-map-0" class="nwtm-comparison-map"></div>
      <div ref="map1" id="nwtm-compare-map-1" class="nwtm-comparison-map"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { get, isEmpty, zipObject, first, upperFirst, trim, uniq } from 'lodash';
import { scaleQuantile } from 'd3-scale';
import { featureCollection, feature } from '@turf/turf';
import Map from '@/lib/map';
import syncMaps from '@/lib/syncMaps';
import { tileSets, promoteIdMap } from '@/config';
import alert from '@/mixins/alert';
import { mk } from '@/lib/utils';

const OUTBOUND_INDEX = 0;
const INBOUND_INDEX = 1;

const generateExpression = (data, dataKey, tileKey, { property = 'trips', colors = ['#F2F6FA', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B', '#04193A'] } = {}) => {
  const initialScalescale = scaleQuantile().domain(uniq(data.map(d => d[property]))).range(colors);
  const colorMap = zipObject([0].concat(initialScalescale.quantiles().map(q => Math.trunc(q))), colors);
  let expression = [];
  data.forEach((row) => {
    const dataNumber = Math.round(parseInt(row[property], 10));
    const thresholds = Object.keys(colorMap);
    const highest = thresholds.pop();
    const d = thresholds.find(k => dataNumber < k);
    const color = colorMap[d || highest];
    expression.push(row[dataKey], color);
  });
  expression = ['match', ['get', tileKey]].concat(expression);
  expression.push('rgba(0,0,0,0)');
  return expression;
};

export default {
  mixins: [alert],

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      maps: [],
      mapsReady: {
        outbound: false,
        inbound: false
      },
      hoverStateId: '',
      activeOriginName: null,
      activeOriginParentsName: null,
      tripInfo: '',
      countryCode: 'us',
      levels: ['county', 'blockgroup']
    };
  },

  computed: {
    ready() {
      return this.mapsReady.outbound && this.mapsReady.inbound;
    },

    selectedOrigin() {
      return first(this.filteredPois);
    },

    level() {
      let level;
      switch (this.selectedOrigin.length) {
        case 5:
          level = 'county';
          break;

        case 12:
          level = 'blockgroup';
          break;

        default:
          throw new Error('Could not determine selected origin level');
      }
      return level;
    },

    layerSourceKey() {
      return `${this.countryCode}${upperFirst(this.level)}`;
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['filteredPois', 'pois'])
  },

  beforeDestroy() {
    this.maps.forEach((map) => {
      if (map) {
        map.remove();
      }
    });
  },

  async mounted() {
    this.init();
  },

  watch: {
    ready: {
      immediate: true,
      handler() {
        if (this.ready) {
          syncMaps(this.maps);
          this.$emit('ready');
        }
      }
    },

    hoverStateId() {
      this.maps.forEach((map) => {
        const tileKey = promoteIdMap[this.layerSourceKey];
        let layerId = `${this.layerSourceKey}ShapesHighlight`;
        if (map.getLayer(layerId)) {
          map.setFilter(layerId, ['==', tileKey, this.hoverStateId]);
        }
        layerId = `${this.layerSourceKey}LinesHighlight`;
        if (map.getLayer(layerId)) {
          map.setFilter(layerId, ['==', tileKey, this.hoverStateId]);
        }
      });
    }
  },

  methods: {
    init() {
      const counties = featureCollection(this.pois.map(({ geom, ...properties }) => feature(geom, properties)));
      this.maps.push(new Map(this.$refs.map0, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        dark: this.dark,
        preserveDrawingBuffer: true,
        eventHandlers: {
          'style.load': async () => {
            await this.loadTileSets(this.maps[OUTBOUND_INDEX]);
            this.maps[OUTBOUND_INDEX].centerOn(counties, { padding: 10, easing: () => 1 });
            this.mapsReady.outbound = true;
          }
        }
      }));
      this.maps.push(new Map(this.$refs.map1, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        dark: this.dark,
        preserveDrawingBuffer: true,
        eventHandlers: {
          'style.load': async () => {
            await this.loadTileSets(this.maps[INBOUND_INDEX]);
            this.maps[INBOUND_INDEX].centerOn(counties, { padding: 10, easing: () => 1 });
            this.mapsReady.inbound = true;
          }
        }
      }));
    },

    async loadTileSets(map) {
      try {
        this.levels.forEach((level) => {
          const url = tileSets[this.countryCode][level];
          const key = `${this.countryCode}${upperFirst(level)}`;
          if (map && !map.getSource(key)) {
            let promoteId = JSON.parse(`{ "${level}": "${promoteIdMap[key]}" }`);
            map.addSource(key, {
              type: 'vector',
              url,
              promoteId
            });
          }
        });
      } catch (error) {
        this.alertError(error);
      }
    },

    removeAllOriginLayers(map) {
      try {
        if (map) {
          let layerId;
          this.levels.forEach((level) => {
            const key = `${this.countryCode}${upperFirst(level)}`;
            layerId = `${key}Shapes`;
            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);
            }
            layerId += 'Highlight';
            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);
            }
            layerId = `${key}Lines`;
            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);
            }
            layerId += 'Highlight';
            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);
            }
          });
          layerId = 'activeOriginLines';
          if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
          }
        }
      } catch (error) {
        this.alertError(error);
      }
    },

    renderData({ outbound = [], inbound = [] } = {}) {
      try {
        const render = (map, data) => {
          const origins = data.map(({ fips, name }) => ({ fips, name }));

          this.removeAllOriginLayers(map);

          if (!isEmpty(data)) {
            const dataKey = 'fips';
            const tileKey = promoteIdMap[this.layerSourceKey];
            const expression = generateExpression(data, dataKey, tileKey, { property: 'count' });
            const shapesFill = {
              id: `${this.layerSourceKey}Shapes`,
              type: 'fill',
              source: this.layerSourceKey,
              'source-layer': this.level,
              paint: {
                'fill-color': expression,
                'fill-opacity': 0.67
              }
            };

            const shapesLine = {
              id: `${this.layerSourceKey}Lines`,
              type: 'line',
              source: this.layerSourceKey,
              'source-layer': this.level,
              paint: {
                'line-color': expression,
                'line-width': 1,
                'line-opacity': 0.8
              }
            };

            const shapesFillHighlight = {
              id: `${this.layerSourceKey}ShapesHighlight`,
              type: 'fill',
              source: this.layerSourceKey,
              'source-layer': this.level,
              paint: {
                'fill-color': expression,
                'fill-opacity': 0.92
              }
            };

            const shapesLineHighlight = {
              id: `${this.layerSourceKey}LinesHighlight`,
              type: 'line',
              source: this.layerSourceKey,
              'source-layer': this.level,
              paint: {
                'line-color': expression,
                'line-width': 1,
                'line-opacity': 1
              }
            };

            const activeOriginLines = {
              id: 'activeOriginLines',
              type: 'line',
              source: this.layerSourceKey,
              'source-layer': this.level,
              paint: {
                'line-color': '#FF8606',
                'line-width': 1,
                'line-opacity': 1
              }
            };

            map.addLayer(shapesFill);
            map.setFilter(`${this.layerSourceKey}Shapes`, ['in', tileKey, ...data.map(d => d.fips)]);
            map.addLayer(shapesLine);
            map.setFilter(`${this.layerSourceKey}Lines`, ['in', tileKey, ...data.map(d => d.fips)]);
            map.addLayer(shapesFillHighlight);
            map.setFilter(`${this.layerSourceKey}ShapesHighlight`, ['==', tileKey, this.hoverStateId]);
            map.addLayer(shapesLineHighlight);
            map.setFilter(`${this.layerSourceKey}LinesHighlight`, ['==', tileKey, this.hoverStateId]);
            map.addLayer(activeOriginLines);
            map.setFilter('activeOriginLines', ['==', tileKey, this.selectedOrigin]);

            const createInfoBoxHTML = (origin) => {
              switch (this.level) {
                case 'county':
                  this.activeOriginName = origins.find(o => o.fips === origin).name;
                  this.activeOriginParentsName = null;
                  break;

                case 'blockgroup': {
                  const [countyState, tract, blockgroup] = origins.find(o => o.fips === origin).name.split('/').map(n => trim(n));
                  this.activeOriginName = blockgroup;
                  this.activeOriginParentsName = `${tract} / ${countyState}`;
                  break;
                }

                default:
                  this.activeOriginName = null;
                  this.activeOriginParentsName = null;
                  break;
              }
              const outboundInfo = outbound.find(cd => cd.fips === origin);
              const inboundInfo = inbound.find(cd => cd.fips === origin);
              this.tripInfo = `
                <div><strong>Outbound Trips: </strong><span>${mk(get(outboundInfo, 'count', 0))}</span></div>
                <div><strong>Inbound Trips: </strong><span>${mk(get(inboundInfo, 'count', 0))}</span></div>
              `;
            };

            map.on('mousemove', `${this.layerSourceKey}Shapes`, (e) => {
              const [feature] = e.features;
              if (feature) {
                const { id } = feature;
                if (id) {
                  if (!isEmpty(this.hoverStateId)) {
                    map.setFeatureState({ source: this.layerSourceKey, sourceLayer: this.level, id: this.hoverStateId }, { hover: false });
                  }
                  this.hoverStateId = id;
                  map.setFeatureState({ source: this.layerSourceKey, sourceLayer: this.level, id: this.hoverStateId }, { hover: true });
                  createInfoBoxHTML(id);
                }
              }
            });

            map.on('mouseleave', `${this.layerSourceKey}Shapes`, (e) => {
              if (!isEmpty(this.hoverStateId)) {
                map.setFeatureState({ source: this.layerSourceKey, sourceLayer: this.level, id: this.hoverStateId }, { hover: false });
              }
              this.hoverStateId = '';
              this.tripInfo = '';
            });
          }
        };
        render(this.maps[OUTBOUND_INDEX], outbound);
        render(this.maps[INBOUND_INDEX], inbound);
      } catch (error) {
        this.alertError(error);
      }
    },

    ...mapActions('visualize', ['setPoiFilter', 'setAggregationFilter', 'setDateFilter'])
  }
};
</script>

<style lang="scss">
.nwtm-comparison-maps-container {
  position: relative;
  display: flex;

  .nwtm-comparison-map {
    width: 50%;
    padding-bottom: 2em;
    height: 400px;

    .export-button {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 1;
    }
  }

  .map-loader {
    position: absolute;
    z-index: 1;
  }

  .compairison-infobox {
    position: absolute;
    z-index: 1;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
