<template>
  <v-dialog v-model="show" :fullscreen="isSmall" width="800" persistent>
    <v-card flat :tile="isSmall">
      <v-card-title>
        <div class="headline">Add Points of Interest</div>
        <v-spacer />
        <div>Cost: <span :class="costTextColor">{{ cost }}</span>/{{ creditsAvailable }} credits</div>
      </v-card-title>
      <v-card-text>
        <v-alert border="left" type="warning" dense text outlined>This study will unavailable while new POIs are calculated.</v-alert>
        <poi-select v-model="report.pois" :organization="studyOrganizationId" :existing-pois="pois" />
        <v-checkbox label="Only Include New POIs in download" v-model="report.outputDeltasOnly" hide-details />
      </v-card-text>
      <v-card-actions>
        <v-btn id="tla-add-pois-cancel" color="error" text large @click="cancel">Cancel</v-btn>
        <v-spacer />
        <v-btn id="tla-add-pois-submit" color="primary" depressed large :loading="pending" @click="checkout" :disabled="!canCheckout">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import get from 'lodash/get';
import { mapState, mapGetters, mapActions } from 'vuex';
import alert from '@/mixins/alert';
import PoiSelect from '@/components/PoiSelect';
import { unfurlNestedDates } from '@/lib/utils';

export default {
  mixins: [alert],

  components: {
    PoiSelect
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pending: false,
      report: {
        pois: [],
        outputDeltasOnly: true
      }
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    studyOrganizationId() {
      return get(this.study, 'organization', '');
    },

    canCheckout() {
      const { pois = [] } = this.report;
      return Boolean(this.isAuthenticated && pois.length && this.canAfford);
    },

    creditsAvailable() {
      let creditsAvailable = 0;
      const orgInfo = this.organizations.find((o) => o.id === this.studyOrganizationId);
      if (orgInfo) {
        creditsAvailable = orgInfo.credits || 0;
      }
      return creditsAvailable;
    },

    studyDates() {
      return get(this.study, 'config.dates', []);
    },

    cost() {
      const { pois = [] } = this.report;
      const numDates = unfurlNestedDates(this.studyDates, { interval: 'day' }).length;
      return pois.length * (Math.ceil(numDates / 15) || 1);
    },

    costTextColor() {
      let textColor = 'black';
      if (this.cost === 0) {
        textColor = 'black';
      } else if (this.cost < this.creditsAvailable) {
        textColor = 'success';
      } else if (this.cost === this.creditsAvailable) {
        textColor = 'warning';
      } else if (this.cost > this.creditsAvailable) {
        textColor = 'error';
      }
      return `${textColor}--text`;
    },

    canAfford() {
      return this.cost <= this.creditsAvailable;
    },

    ...mapState(['organizations']),
    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['pois'])
  },

  methods: {
    async checkout() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        let { pois, outputDeltasOnly } = this.report;

        if (!Array.isArray(pois)) {
          pois = [pois];
        }

        const config = { pois, outputDeltasOnly };

        await this.$services.orders.expand(id, config);
        this.alertSuccess(`Successfully added POIs to ${name}`);
        const { data: orders } = await this.$services.organizations.listOrders(this.studyOrganizationId);
        this.setOrders({ oid: this.studyOrganizationId, orders });
        this.cancel();
        this.$router.push({ name: 'main' });
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.report = {
        pois: []
      };
    },

    cancel() {
      this.reset();
      this.$emit('update:show', false);
    },

    ...mapActions(['setOrders'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
