<template>
  <v-dialog :max-width="((step - 1) * 400) + 800" v-model="show" :fullscreen="isSmall" persistent>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card width="800" flat :tile="isSmall">
          <v-card-title>
            <div class="headline">Create Study</div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-select id="report-organization" v-model="selectedOrganization" label="Organization" prepend-icon="mdi-domain" :items="organizations" return-object item-text="name" item-value="id" v-if="organizations.length > 1 && !isAdmin" />
              <div class="text-subtitle-2">Choose:</div>
              <v-row>
                <v-col cols="6" v-show="availableProducts.includes('tla')">
                  <v-card outlined>
                    <v-card-text>Get daily footfall counts, demographic information, and more for one or more points of interest.</v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" depressed block large @click="selectProduct('tla')">Target Location Analysis</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="6" v-show="availableProducts.includes('dla')">
                  <v-card outlined>
                    <v-card-text>Get monthly footfall estimates, home locations, and demographic information of visitors to a destination (MSA, county, city, or a custom study area).</v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" depressed block large @click="selectProduct('dla')">Destination Location Analysis</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="6" v-show="availableProducts.includes('ad')">
                  <v-card outlined>
                    <v-card-text>See all the locations of activity inside a study area.  Useful for populating a heatmap of population density.</v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" depressed block large @click="selectProduct('ad')">Activity Density</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="6" v-show="availableProducts.includes('nwtm')">
                  <v-card outlined>
                    <v-card-text>Get all block group to block group trips for selected counties.</v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" depressed block large @click="selectProduct('nwtm')">Nationwide Trip Matrix</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="6" v-show="availableProducts.includes('pp')">
                  <v-card outlined>
                    <v-card-text>Get all the raw points inside a study area.</v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" depressed block large @click="selectProduct('pp')">Point Plot</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-0 small-actions" v-if="isSmall">
            <v-btn class="ma-0" color="error" depressed large tile width="50%" @click="cancel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-btn id="report-cancel" color="error" text large @click="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-window-item>
      <v-window-item :value="2">
        <component :is="productShop" @cancel="cancel" :key="productShop" :organization="selectedOrganization" />
      </v-window-item>
    </v-window>
  </v-dialog>
</template>

<script>
import first from 'lodash/first';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import { mapState, mapGetters } from 'vuex';
import alert from '@/mixins/alert';

// const stripe = Stripe('pk_test_NW8kCwZlT8SNGz1HPzbIURJN');

export default {
  name: 'Shop',

  mixins: [alert],

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      product: null,
      step: 1,
      selectedOrganization: {}
    };
  },

  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    productShop() {
      return `shop-${this.product}`;
    },

    availableProducts() {
      return this.isAdmin ? ['tla', 'dla', 'ad', 'nwtm', 'pp'] : Object.keys(pickBy(get(this.selectedOrganization, 'products', {}), (p) => p.write));
    },

    ...mapState(['organizations']),
    ...mapGetters(['isAdmin'])
  },

  watch: {
    organizations() {
      this.selectedOrganization = first(this.organizations);
    }
  },

  mounted() {
    this.selectedOrganization = first(this.organizations);
  },

  methods: {
    selectProduct(product) {
      this.product = product;
      this.step = 2;
    },

    reset() {
      this.step = 1;
      this.product = null;
    },

    cancel() {
      this.reset();
      this.$emit('update:show', false);
    }
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
