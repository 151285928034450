<template>
   <v-select ref="countySelect" v-model="proxy" :prepend-icon="!noIcon ? 'mdi-map' : ''" :menu-props="{ maxHeight: 400 }" :items="filteredCountyOptions" :label="label" no-data-text="No Counties Found" item-text="name" item-value="fips" multiple @change="countyHandler" @focus="focusSearch" @blur="clearSearch">
    <template v-slot:prepend-item>
      <!-- County Search -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="countyFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 100)" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon dark @click="closeSelect">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, index }">
      <!-- Selection -->
      <v-chip v-if="index <= 3">
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-if="index === 4" class="grey--text caption">(+{{ value.length - 4 }} others)</span>
    </template>
    <template v-slot:item="data">
      <!-- State Select All Counties -->
      <template v-if="data.item.on">
        <v-list-item class="state-item" @click="data.item.on">
          <v-list-item-action>
            <v-checkbox color="primary darken-2" :value="data.item.fips" :value-comparator="allCountiesSelected" :indeterminate="someCountiesSelected(data.item.fips)" @toggle="data.item.on" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">{{ data.item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- Divider -->
      <template v-else-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"/>
      </template>
      <!-- County Item -->
      <template v-else>
        <v-list-item-action>
          <v-checkbox color="primary" v-model="data.attrs.inputValue" @toggle="data.on" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{ data.item.state }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import counties from '@/data/counties';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },

    limit: {
      type: Number,
      default: 0
    },

    label: {
      type: String,
      default: 'Counties'
    },

    noIcon: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      proxy: [],
      countyFilter: ''
    };
  },

  computed: {
    countyOptions() {
      const self = this;
      return sortBy(counties, ['name']).reduce((options, s) => {
        options.push({
          state: '',
          stateAbv: s.abv,
          name: s.name,
          fips: s.fips,
          on(value) {
            self.toggleCounties(s.fips);
          }
        });
        sortBy(s.counties, ['name']).forEach((c) => options.push({
          state: s.name,
          stateAbv: s.abv,
          stateFips: s.fips,
          name: c.name,
          fips: c.fips
        }));
        return options;
      }, []);
    },

    filteredCountyOptions() {
      if (this.countyFilter !== null && this.countyFilter.length) {
        return this.countyOptions.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.countyFilter.toLowerCase());
          }
          if (!found && item.stateFips) {
            found = item.state.toLowerCase().includes(this.countyFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.countyOptions;
    }
  },

  watch: {
    proxy() {
      if (!isEqual(this.value, this.proxy)) {
        this.$emit('input', this.proxy);
      }
    },

    value() {
      if (!isEqual(this.value, this.proxy)) {
        this.proxy = this.value;
      }
    },

    limit() {
      if (this.limit > 0 && this.proxy.length > this.limit) {
        this.proxy = this.proxy.slice(0, this.limit);
      }
    }
  },

  methods: {
    countyHandler(value) {
      // apply limit
      if (this.limit > 0 && value.length > this.limit) {
        this.proxy = value.slice(0, this.limit);
      }
    },

    allCountiesSelected(fips) {
      const stateCounties = this.countyOptions.filter((o) => o.stateFips === fips).map((o) => o.fips);
      const selectedStateCounties = this.proxy.filter((o) => o.slice(0, 2) === fips);
      return selectedStateCounties.length === stateCounties.length;
    },

    someCountiesSelected(fips) {
      const selectedStateCounties = this.proxy.filter((o) => o.slice(0, 2) === fips);
      return !this.allCountiesSelected(fips) && selectedStateCounties.length > 0;
    },

    async toggleCounties(fips) {
      let selection = this.proxy;
      if (this.allCountiesSelected(fips)) {
        selection = this.proxy.filter((o) => o.slice(0, 2) !== fips);
      } else {
        const stateCounties = this.countyOptions.filter((o) => o.stateFips === fips).map((o) => o.fips);
        if (this.limit > 0 && selection.length === this.limit) {
          selection = this.proxy.filter((o) => o.slice(0, 2) !== fips);
        } else {
          selection = uniq(stateCounties.concat(this.proxy));
        }
      }

      if (this.limit > 0) {
        selection = selection.slice(0, this.limit);
      }

      this.proxy = selection;
    },

    clearSearch() {
      this.countyFilter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      if (this.$refs.countySelect) {
        this.$refs.countySelect.blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list.v-sheet.v-sheet--tile[role="listbox"] {
  padding-top: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}

.state-item {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 64px;
  background: #FFFFFF;
  z-index: 5;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  border-right: none;
}
</style>
