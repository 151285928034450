<template>
  <v-card class="mb-5" outlined>
    <v-card-title class="headline text-center">Study Area Visitor Insights</v-card-title>
    <v-card-text>
      <trial-upgrade title="Visitor Home Locations" height="400" v-if="isTrial" />
      <choropleth-map @level="setActiveLevel" @iso="setActiveIso" v-else />
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col>
          <data-fetcher height="350" report="getStudyAreaVisits" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" :limit="10" v-slot="{ data }">            <top-chart title="Top 10" height="350" :data="data" :name="activeLevel" />
          </data-fetcher>
        </v-col>
        <v-col>
          <data-fetcher height="350" report="getStudyAreaVisitsByMonth" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" :limit="10" v-slot="{ data }">
            <origin-trend title="Top 10 Visit Trend" height="350" :group="activeLevel" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col v-if="isTrial">
          <trial-upgrade title="Ethnicity" height="250" />
        </v-col>
        <v-col v-else-if="hasDemographics">
          <data-fetcher height="250" report="getStudyAreaEthnicityDemographics" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <ethnicity-chart title="Ethnicity" height="250" :data="data" />
          </data-fetcher>
        </v-col>
        <v-col v-if="isTrial">
          <trial-upgrade title="Gender & Age" height="250" />
        </v-col>
        <v-col v-else-if="hasDemographics">
          <data-fetcher height="250" report="getStudyAreaGenderAgeDemographics" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <gender-age-chart title="Gender & Age" height="250" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col v-if="isTrial">
          <trial-upgrade title="Income" height="250" />
        </v-col>
        <v-col v-else-if="hasDemographics">
          <data-fetcher height="250" report="getStudyAreaIncomeDemographics" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <income-chart title="Income" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import upperFirst from 'lodash/upperFirst';
import DataFetcher from '@/components/charts/DataFetcher';
import TopChart from '@/components/charts/TopChart';
import ChoroplethMap from '@/components/charts/ChoroplethMap';
import OriginTrend from '@/components/charts/OriginTrend';
import EthnicityChart from '@/components/charts/EthnicityChart';
import GenderAgeChart from '@/components/charts/GenderAgeChart';
import IncomeChart from '@/components/charts/IncomeChart';
import TrialUpgrade from '@/components/charts/TrialUpgrade';

export default {
  props: {
    isTrial: {
      type: Boolean,
      default: false
    }
  },

  components: {
    DataFetcher,
    TopChart,
    OriginTrend,
    ChoroplethMap,
    EthnicityChart,
    GenderAgeChart,
    IncomeChart,
    TrialUpgrade
  },

  computed: {
    properActiveLevel() {
      return upperFirst(this.activeLevel);
    },

    hasDemographics() {
      return ['us', 'is'].includes(this.activeIso);
    },

    ...mapState('visualize', ['dates', 'activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredHomeLocations'])
  },

  methods: mapActions('visualize', ['setActiveIso', 'setActiveLevel'])
};
</script>
