<template>
  <v-container>
    <v-alert border="left" colored-border text type="error" dense prominent v-if="shapeInError">
      <v-row align="center" dense>
         <v-col class="grow">{{ shapeError }}</v-col>
         <v-col class="shrink">
           <v-btn color="error" text @click="simplifyShape">Simplify</v-btn>
         </v-col>
      </v-row>
    </v-alert>
    <v-row>
      <v-col cols="12" sm="6">
        <v-form v-model="formValid">
          <v-container>
            <v-row>
              <v-text-field id="poi-name" label="Name" v-model="poi.properties.name" prepend-icon="mdi-map-marker-outline" />
            </v-row>
            <v-row>
              <v-combobox id="poi-label" v-model="poi.properties.label" :items="availableLabels" label="Label" prepend-icon="mdi-label-outline" :disabled="isStudyArea" />
            </v-row>
            <v-row>
              <v-combobox id="poi-tags" v-model="poi.properties.tags" :items="availableTags" label="Tags" prepend-icon="mdi-tag-outline" multiple chips :disabled="isStudyArea" />
            </v-row>
            <v-row v-if="showMakeStudyArea">
              <v-checkbox label="Make Study Area" v-model="isStudyArea" />
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col cols="12" sm="6">
        <div ref="poiMap" class="poi-map"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { coordAll, simplify } from '@turf/turf';
import Map from '@/lib/map';

export default {
  name: 'EditPoi',

  props: {
    poi: {
      type: Object,
      default: () => ({ properties: { name: '', label: '' }, geometry: {} })
    },

    organization: {
      type: Object,
      default: () => {}
    },

    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formValid: false,
      map: null,
      shapeError: null,
      isStudyArea: false
    };
  },

  computed: {
    availableLabels() {
      return !isEmpty(this.organization) ? this.poiLabels[this.organization.id] : [];
    },

    availableTags() {
      return !isEmpty(this.organization) ? this.poiTags[this.organization.id] : [];
    },

    shapeInError() {
      return this.shapeError !== null;
    },

    canProceed() {
      return !this.shapeInError && !isEmpty(this.poi.properties.name) && !isEmpty(this.poi.properties.label);
    },

    showMakeStudyArea() {
      return this.$route.name === 'pois' && this.isAdmin;
    },

    ...mapState(['poiLabels', 'poiTags']),
    ...mapGetters(['isAdmin'])
  },

  mounted() {
    this.initMap();
    this.checkShape();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

  watch: {
    canProceed: {
      immediate: true,
      handler(value) {
        this.$emit('input', value);
      }
    },

    isStudyArea() {
      if (this.isStudyArea) {
        this.poi.properties.purpose = 'studyarea';
        this.poi.properties.label = 'Study Area';
        this.poi.properties.tags = [];
      } else {
        this.poi.properties.purpose = 'poi';
        this.poi.properties.label = '';
      }
    }
  },

  methods: {
    initMap() {
      this.map = new Map(this.$refs.poiMap, {
        draw: false,
        staticMap: true,
        eventHandlers: {
          'style.load': async () => {
            this.loadBaseMetaLayers();
          }
        }
      });
    },

    loadBaseMetaLayers() {
      if (this.map) {
        if (this.map.getLayer('poi')) {
          this.map.removeLayer('poi');
        }
        if (this.map.getSource('POI')) {
          this.map.removeSource('POI');
        }

        this.map.addSource('POI', {
          type: 'geojson',
          data: this.poi
        });

        this.map.addLayer({
          id: 'poi',
          type: 'fill',
          source: 'POI',
          paint: {
            'fill-antialias': true,
            'fill-color': '#EF8275',
            'fill-outline-color': 'rgba(0, 51, 51, 0.6)',
            'fill-opacity': 0.83
          }
        });

        this.map.centerOn(this.poi, { easing: () => 1 });
      }
    },

    checkShape() {
      const maxPolygonVertices = 8000;
      const type = this.poi.geometry.type.toUpperCase();
      let shapeError = null;
      // Shapes must be polygons OR multipolygons
      if (!['POLYGON', 'MULTIPOLYGON'].includes(type)) {
        shapeError = new Error('Shape is not a polygon or multipolygon');
      }

      const numTotalCoordsOfAllPolygons = coordAll(this.poi).length;
      if (numTotalCoordsOfAllPolygons > maxPolygonVertices) {
        shapeError = new Error(`Shape contains ${numTotalCoordsOfAllPolygons} vertices. Max allowed is ${maxPolygonVertices.toLocaleString()}.`);
      }

      this.shapeError = shapeError;
    },

    simplifyShape() {
      const doSimplify = (tolerance = 0) => {
        tolerance += 0.0001;
        const oldNumPoints = coordAll(this.poi).length;
        this.poi.geometry = simplify(this.poi.geometry, { tolerance, highQuality: true });
        const newNumPoints = coordAll(this.poi).length;
        this.checkShape();
        if (this.shapeInError && oldNumPoints !== newNumPoints) {
          doSimplify(tolerance);
        }
      };
      doSimplify();
      this.loadBaseMetaLayers();
    }
  }
};
</script>

<style lang="scss">
  .poi-map {
    width: 100%;
    height: 100%;
  }

  .mapboxgl-control-container {
    display: none;
  }
</style>
