<template>
  <v-container pt-0>
    <v-toolbar flat>
      <v-btn text @click="close">
        <v-icon left>mdi-arrow-left</v-icon>
        <span>{{ $tc('back') }}</span>
      </v-btn>
      <v-spacer />
      <v-btn text @click="showAddDate = true" v-if="canModify">
        <v-icon left>mdi-calendar-plus</v-icon>
        <span>{{ $tc('addDate') }}</span>
      </v-btn>
      <v-btn text @click="showAddPoi = true" v-if="canModify">
        <v-icon left>mdi-map-marker-plus</v-icon>
        <span>{{ $tc('addPoi') }}</span>
      </v-btn>
      <v-btn text :loading="exportingShapes" @click="exportShapes">
        <v-icon left>mdi-map-marker-down</v-icon>
        <span>{{ $tc('downloadShapes') }}</span>
      </v-btn>
      <v-btn text :loading="exporting" @click="exportOrder">
        <v-icon left>mdi-download</v-icon>
        <span>{{ $tc('download') }}</span>
      </v-btn>
    </v-toolbar>
    <study-totals />
    <length-of-stay />
    <geo-level-summary ref="geoLevelSummary" :is-trial="isTrial" />
    <poi-summary ref="poiSummary" v-if="hasPois" />
    <add-date-dialog :show.sync="showAddDate" />
    <add-poi-dialog :show.sync="showAddPoi" />
  </v-container>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { mapState, mapActions, mapGetters } from 'vuex';
import { featureCollection, feature } from '@turf/turf';
import alert from '@/mixins/alert';
import AddDateDialog from '@/components/studies/dla/AddDateDialog';
import AddPoiDialog from '@/components/studies/dla/AddPoiDialog';
import StudyTotals from '@/components/studies/dla/reports/StudyTotals';
import GeoLevelSummary from '@/components/studies/dla/reports/GeoLevelSummary';
import PoiSummary from '@/components/studies/dla/reports/PoiSummary';
import LengthOfStay from '@/components/studies/dla/reports/LengthOfStay';

export default {
  mixins: [alert],

  components: {
    AddDateDialog,
    AddPoiDialog,
    StudyTotals,
    GeoLevelSummary,
    PoiSummary,
    LengthOfStay
  },

  data() {
    return {
      pending: false,
      exporting: false,
      exportingShapes: false,
      filterMenu: false,
      showAddDate: false,
      showAddPoi: false,
      scrollOptions: {
        duration: 500,
        offset: 0,
        easing: 'linear'
      }
    };
  },

  mounted() {
    this.setActiveIso('us');
    this.setActiveLevel('state');
  },

  computed: {
    hasPois() {
      return !isEmpty(this.pois);
    },

    canModify() {
      return this.isAdmin || get(this.parentOrganization, 'products.dla.write', false) && !this.isTrial;
    },

    isTrial() {
      return get(this.parentOrganization, 'trial', false);
    },

    ...mapState('visualize', ['study']),
    ...mapGetters(['isAdmin']),
    ...mapGetters('visualize', ['studyArea', 'pois', 'parentOrganization'])
  },

  methods: {
    close() {
      this.reset();
      this.$router.push({ name: 'main' });
    },

    async exportOrder() {
      try {
        const { id, downloadUrl } = this.study;
        if (downloadUrl) {
          window.location = downloadUrl;
        } else {
          this.exporting = true;
          const { data } = await this.$services.orders.export(id);
          window.location = data;
          this.study.downloadUrl = data;
          this.setOrderProperty({ id, key: 'downloadUrl', value: data });
        }
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.exporting = false;
      }
    },

    async exportShapes() {
      try {
        this.exportingShapes = true;
        const { name } = this.study;
        const filename = `${name.replace(/\W/g, '')}_Shapes.geojson`;
        const features = featureCollection([].concat(this.studyArea, this.pois).map(({ geom, ...properties }) => feature(geom, properties)));
        const blob = new Blob([JSON.stringify(features)], { type: 'application/json' });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          document.body.append(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (error) {
        this.alertError(error);
      } finally {
        this.exportingShapes = false;
      }
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['reset', 'setActiveIso', 'setActiveLevel'])
  }
};
</script>

<style lang="scss">
.poi-label-item {
  position: sticky !important;
  position: -webkit-sticky !important;
  background: #FFFFFF;
  top: 0;
  z-index: 5;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  border-right: none;
}
</style>
