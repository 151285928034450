<template>
  <v-card class="mb-5" outlined>
    <v-card-text>
      <v-row>
        <v-col>
          <data-fetcher height="250" report="getCountByTimeOfDay" :filter="{ studyDates: filteredDateRange, poiIds: filteredPois, aggregation: filteredAggregation }" v-slot="{ data }">
            <simple-bar-chart title="Trips By Time of Day" :data="data" x="hour" :x-formatter="formatHour"  format-legend />
          </data-fetcher>
        </v-col>
        <v-col>
          <data-fetcher height="250" report="getCountByTripPurpose" :filter="{ studyDates: filteredDateRange, poiIds: filteredPois, aggregation: filteredAggregation }" v-slot="{ data }">
            <simple-bar-chart title="Trips By Purpose" :data="data" x="purpose" :x-formatter="formatPurpose" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <data-fetcher height="250" report="getTripPurposeByTimeOfDay" :filter="{ studyDates: filteredDateRange, poiIds: filteredPois, aggregation: filteredAggregation }" v-slot="{ data }">
            <simple-area-chart title="Trip Purpose By Time of Day" :data="data" x="hour" :x-formatter="formatHour" group-by="purpose"  format-legend />
          </data-fetcher>
        </v-col>
        <v-col>
          <data-fetcher height="250" report="getTripPurposeByDayOfWeek" :filter="{ studyDates: filteredDateRange, poiIds: filteredPois }" v-slot="{ data }">
            <simple-column-chart title="Trip Purpose By Day of Week" :data="data" :x="['Mon_Tue_Wed_Thu', 'Fri', 'Sat', 'Sun']" group-by="purpose" :x-formatter="formatDayOfWeek" format-legend />
          </data-fetcher>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import DataFetcher from '@/components/charts/DataFetcher';
import SimpleBarChart from '@/components/charts/SimpleBarChart';
import SimpleAreaChart from '@/components/charts/SimpleAreaChart';
import SimpleColumnChart from '@/components/charts/SimpleColumnChart';

export default {
  components: {
    DataFetcher,
    SimpleBarChart,
    SimpleAreaChart,
    SimpleColumnChart
  },

  data() {
    return {
      formatHour(hour) {
        if (hour) {
          hour = parseInt(hour, 10);
          if (hour === 0) {
            hour = '12a';
          } else if (hour === 12) {
            hour = '12p';
          } else if (hour > 12) {
            hour = `${hour - 12}p`;
          } else {
            hour = `${hour}a`;
          }
          return hour;
        }
        return '';
      },

      formatPurpose(purpose) {
        const purposeMap = {
          HH: 'Home &#8594; Home',
          HO: 'Home &#8594; Other',
          HW: 'Home &#8594; Work',
          OH: 'Other &#8594; Home',
          OO: 'Other &#8594; Other',
          OW: 'Other &#8594; Work',
          WH: 'Work &#8594; Home',
          WO: 'Work &#8594; Other',
          WW: 'Work &#8594; Work'
        };

        if (purpose) {
          return purposeMap[purpose.toUpperCase()];
        }
        return '';
      },

      formatDayOfWeek(day) {
        if (day) {
          return day.replaceAll('_', ', ');
        }
        return '';
      }
    };
  },

  computed: mapGetters('visualize', ['filteredDateRange', 'filteredPois', 'filteredAggregation'])
};
</script>
