<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="350" max-height="400" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="mr-3">
        <v-icon left>mdi-map</v-icon>
        <span>{{ selectedOriginName }}</span>
      </v-btn>
    </template>
    <v-tabs v-model="tab" fixed-tabs dark>
      <v-tab key="county">Counties</v-tab>
      <v-tab key="blockgroup">BlockGroups</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="county">
        <single-county-select-list v-model="proxy" :items="pois" />
      </v-tab-item>
      <v-tab-item key="blockgroup">
        <single-blockgroup-select-list v-model="proxy" :items="blockgroups" />
      </v-tab-item>
    </v-tabs-items>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import alert from '@/mixins/alert';
import SingleBlockgroupSelectList from '@/components/SingleBlockgroupSelectList';
import SingleCountySelectList from '@/components/SingleCountySelectList';

export default {
  mixins: [alert],

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  components: {
    SingleCountySelectList,
    SingleBlockgroupSelectList
  },

  data() {
    return {
      proxy: '',
      menu: false,
      tab: 'county',
      blockgroups: []
    };
  },

  computed: {
    selectedOriginName() {
      const origin = this.blockgroups.find(bg => bg.fips === this.value);
      if (origin) {
        switch (this.value.length) {
          case 12:
            return `${origin.county} / ${origin.tract} / ${origin.name}`;

          default:
            return origin.name;
        }
      }
      return '';
    },

    ...mapGetters('visualize', ['pois'])
  },

  watch: {
    proxy() {
      if (this.value !== this.proxy) {
        this.$emit('input', this.proxy);
      }
    },

    value: {
      immediate: true,
      handler() {
        if (this.value !== this.proxy) {
          this.proxy = this.value;
        }
      }
    }
  },

  async mounted() {
    await this.getBlockGroups();
  },

  methods: {
    async getBlockGroups() {
      const { id } = this.$route.params;
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        if (this.call) {
          this.call.cancel();
        }
        this.call = CancelToken.source();
        this.pending = true;
        const { data } = await this.$services.orders.visualize(id, {
          params: { report: 'getStudyBlockGroupList' },
          cancelToken: this.call.token
        });
        this.blockgroups = Object.freeze(data.reduce((items, { fips, name: countyName, tracts }) => {
          items.push({ fips, name: countyName, county: countyName });
          tracts.forEach(({ fips, name: tractName, blockgroups }) => {
            items.push({ fips, name: tractName, county: countyName });
            blockgroups.forEach(({ fips, name }) => {
              items.push({ fips, name, county: countyName, tract: tractName });
            });
          });
          return items;
        }, []));
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      }
    }
  }
};
</script>
