import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import { Popup } from 'mapbox-gl';
import { promoteIdMap } from '@/config';
import LayerManager from '@/lib/layerManagers/layerManager';
import { mk, round25 } from '@/lib/utils';

class UsLayerManager extends LayerManager {
  hoverStateId;

  constructor(map) {
    super(map, { countryCode: 'us' });
  }

  renderLayer(iso, level, data, { previousLayer, colorMap, property = 'extrapolated_devices' } = {}) {
    if (iso !== this.countryCode) {
      super.removeAllLayers();
    } else {
      const key = `${this.countryCode}${upperFirst(level)}`;
      const tileKey = promoteIdMap[key];
      const dataKey = level === 'msa' ? 'geoid' : 'fips';
      const dataFilter = (d) => !Number.isNaN(Number(d[dataKey])); // US data has no country code prepended

      data = data.filter(dataFilter);
      const expression = LayerManager.generateExpression(data, dataKey, tileKey, colorMap, { property }); // generate color expression on the entire data set

      if (!isEmpty(data)) {
        const shapesFill = {
          id: `${key}Shapes`,
          type: 'fill',
          source: key,
          'source-layer': level,
          paint: {
            'fill-color': expression,
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.92, 0.67]
          }
        };

        const shapesLine = {
          id: `${key}Lines`,
          type: 'line',
          source: key,
          'source-layer': level,
          paint: {
            'line-color': expression,
            'line-width': 1,
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.8]
          }
        };

        super.removeAllLayers();
        this.map.addLayer(shapesFill, previousLayer);
        this.map.setFilter(`${key}Shapes`, ['in', tileKey, ...data.map((d) => d[dataKey])]);
        this.map.addLayer(shapesLine, previousLayer);
        this.map.setFilter(`${key}Lines`, ['in', tileKey, ...data.map((d) => d[dataKey])]);

        const popUp = new Popup({
          closeButton: false,
          closeOnClick: false
        });
        this.map.on('click', `${key}Shapes`, (e) => {
          const [feature] = e.features;
          if (feature) {
            const info = data.find((d) => level === 'msa' ? d.geoid === feature.id : d.fips === feature.id);
            if (info) {
              popUp.setLngLat(e.lngLat).setHTML(`
              <div class="v-card v-card--flat v-sheet">
                <div class="v-card__text pa-0">
                  <div class="headline">${['tract', 'blockgroup'].includes(level) ? feature.properties.NAMELSAD10 : info[level]}</div>
                  ${['tract', 'blockgroup'].includes(level) ? `<div class="caption">FIPS: ${info.fips}</div>` : ''}
                  ${info.extrapolated_devices == null ? '' : `
                    <div>
                      <b>${mk(info.extrapolated_devices)}</b> estimated unique visitors
                    </div>
                  `}
                  ${info.average_days_seen == null ? '' : `
                    <div>
                      <b>${round25(info.average_days_seen)}</b> average days seen
                    </div>
                  `}
                </div>
              </div>
            `).addTo(this.map);
            }
          }
        });

        this.map.on('mousemove', `${key}Shapes`, (e) => {
          const [feature] = e.features;
          if (feature) {
            const { id } = feature;
            if (id) {
              this.map.getCanvas().style.cursor = 'pointer';
              if (this.hoverStateId) {
                this.map.setFeatureState({ source: key, sourceLayer: level, id: this.hoverStateId }, { hover: false });
              }
              this.hoverStateId = id;
              this.map.setFeatureState({ source: key, sourceLayer: level, id: this.hoverStateId }, { hover: true });
            }
          }
        });

        this.map.on('mouseleave', `${key}Shapes`, (e) => {
          popUp.remove();
          this.map.getCanvas().style.cursor = '';
          if (this.hoverStateId) {
            this.map.setFeatureState({ source: key, sourceLayer: level, id: this.hoverStateId }, { hover: false });
          }
          this.hoverStateId = null;
        });
      }
    }
  }
}

export default UsLayerManager;
