<template>
  <v-container pb-0>
    <v-row>
      <div class="headline pl-1 pr-1">Activity Density</div>
      <v-spacer />
      <div class="title pl-1 pr-1">{{ intervalLabel }}</div>
      <v-spacer />
      <v-btn-toggle v-model="currentLayer" color="primary" group mandatory>
        <v-btn value="HeatMapLayer">
          <v-icon left>mdi-fire</v-icon>
          <span class="hidden-sm-and-down">Heatmap</span>
        </v-btn>
        <v-btn value="ScreenGridLayer">
          <v-icon left>mdi-grid</v-icon>
          <span class="hidden-sm-and-down">Screen Grid</span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row>
      <div class="loader" :style="{ width, height }" v-if="initializing">
        <v-progress-circular indeterminate color="primary" size="64" />
      </div>
      <div class="map-container" :style="{ width, height }" v-else>
        <v-progress-linear class="map-loading-bar ma-0" indeterminate v-show="pending" />
        <div ref="map" class="map"></div>
      </div>
    </v-row>
    <v-row>
      <v-col class="pa-0" cols="12">
        <apexchart ref="chart" class="summary-chart" type="area" :height="120" :options="options" :series="series"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0" cols="4">
        <v-btn outlined block color="secondary" @click="resetZoom">
          <v-icon left>mdi-magnify-minus-outline</v-icon>
          <span>Reset Zoom</span>
        </v-btn>
      </v-col>
      <v-col cols="4"></v-col>
      <v-col class="pa-0" cols="4">
        <v-btn color="dark" :dark="!isPlaying" block :outlined="isPlaying" :depressed="!isPlaying" @click="togglePlayer">
          <v-icon left v-if="isPlaying">mdi-pause-circle</v-icon>
          <v-icon left v-else>mdi-play-circle</v-icon>
          <span v-if="isPlaying">Pause</span>
          <span v-else>Play</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import getTime from 'date-fns/getTime';
import { feature } from '@turf/turf';
import { HeatmapLayer, ScreenGridLayer } from '@deck.gl/aggregation-layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import Map from '@/lib/map';
import { unfurlNestedDates } from '@/lib/utils';
import alert from '@/mixins/alert';

const LAYERS = {
  HEATMAP: 'HeatMapLayer',
  SCREENGRID: 'ScreenGridLayer'
};

const TIMEOUT = 2500;

let currentLayer;
let timeoutHandler;

export default {
  mixins: [alert],

  props: {
    width: {
      type: [String, Number],
      default: '100%'
    },

    height: {
      type: [String, Number],
      default: '100%'
    }
  },

  data() {
    return {
      data: [],
      trendData: [],
      intervals: [],
      intervalIndex: 0,
      map: null,
      pending: false,
      initializing: true,
      currentLayer: 'HeatMapLayer',
      isPlaying: false,
      call: null
    };
  },

  computed: {
    groupBy() {
      return get(this.study, 'config.groupBy', 'day');
    },

    intervalLabel() {
      if (!isEmpty(this.intervals)) {
        let formatString = 'MMMM dd, yyyy';
        if (this.groupBy === 'hour') {
          formatString = 'ha MMMM dd, yyyy';
        } else if (this.groupBy === 'month') {
          formatString = 'MMMM yyyy';
        }
        return formatDate(parseISO(this.intervals[this.intervalIndex]), formatString);
      }
      return '';
    },

    startDate() {
      return this.trendData.length ? getTime(minDate(this.trendData.map((d) => parseISO(d.study_date)))) : new Date().getTime();
    },

    endDate() {
      return this.trendData.length ? getTime(maxDate(this.trendData.map((d) => parseISO(d.study_date)))) : new Date().getTime();
    },

    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            autoScaleYaxis: true
          },
          events: {
            dataPointSelection: this.handleSelect
          }
        },
        tooltip: {
          intersect: true,
          shared: false,
          y: {
            formatter: (v) => v ? v.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 }) : '0'
          },
          x: {
            formatter: (timestamp) => {
              let formatString = 'MMMM dd, yyyy';
              if (this.groupBy === 'hour') {
                formatString = 'ha MMMM dd, yyyy';
              } else if (this.groupBy === 'month') {
                formatString = 'MMMM yyyy';
              }
              return formatDate(new Date(timestamp), formatString);
            }
            // datetimeUTC: true,
            // format: this.groupBy === 'hour' ? 'hTT MMM dd, yyyy' : this.groupBy === 'month' ? 'MMM yyyy' : 'MMM dd, yyyy'
          },
          marker: {
            show: false
          }
        },
        stroke: {
          width: 2
        },
        markers: {
          size: 4
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        colors: ['#1675D1'],
        xaxis: {
          type: 'datetime',
          min: this.startDate,
          max: this.endDate,
          labels: {
            datetimeUTC: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          show: false
        }
      };
    },

    series() {
      const series = {
        name: 'count',
        data: []
      };
      if (this.trendData.length) {
        this.trendData.forEach((d) => {
          const { study_date: studyDate, extrapolated_devices: count } = d;
          const timestamp = getTime(parseISO(studyDate));
          series.data.push([timestamp, count]);
        });
      }
      return [series];
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['studyArea'])
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    this.pause();
    if (this.map) {
      this.map.remove();
    }

    if (this.call) {
      this.call.cancel();
    }
  },

  watch: {
    currentLayer() {
      this.toggleLayer();
    },

    async intervalIndex() {
      await this.loadData();
      this.refreshLayer();
    }
  },

  methods: {
    async init() {
      try {
        const { id, config: { dates = [] } } = this.study;
        if (!isEmpty(dates)) {
          if (this.groupBy === 'hour') {
            this.intervals = flatten(unfurlNestedDates(dates, { interval: 'day' }).map((d) => formatDate(d, 'yyyy-MM-dd')).map((d) => {
              const days = [];
              for (let x = 0; x < 23; x++) {
                days.push(`${d}T${x < 10 ? `0${x}` : x}:00:00`);
              }
              return days;
            }));
          } else {
            this.intervals = unfurlNestedDates(dates, { interval: this.groupBy }).map((d) => formatDate(d, 'yyyy-MM-dd\'T\'HH:mm:ss'));
          }
        }

        const { data } = await this.$services.orders.visualize(id, { params: { report: 'getSummary' } });
        this.trendData = data;
        await this.loadData();
        this.initializing = false;

        await this.$nextTick();
        if (this.$refs.map) {
          this.map = new Map(this.$refs.map, {
            style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
            darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
            eventHandlers: {
              'style.load': async () => {
                this.addStudyAreaLayer();
                this.addHeatMapLayer();
              }
            }
          });
        }
      } catch (error) {
        console.error(error); // eslint-disable-line
        this.alertError(error);
      }
    },

    async loadData() {
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        this.pending = true;
        const { id } = this.$route.params;
        const interval = this.intervals[this.intervalIndex];
        if (this.call) {
          this.call.cancel();
        }
        this.call = CancelToken.source();
        const { data } = await this.$services.orders.visualize(id, {
          params: {
            report: 'getPoints',
            interval
          },
          cancelToken: this.call.token
        });
        this.data = data;
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      } finally {
        this.pending = false;
      }
    },

    addStudyAreaLayer() {
      if (this.map) {
        try {
          // remove existing shapes from the map
          ['studyareaShape', 'studyareaLine'].forEach((layerId) => {
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
          });
          if (this.map.getSource('STUDYAREA')) {
            this.map.removeSource('STUDYAREA');
          }

          const { geom, ...properties } = this.studyArea;
          const studyAreaFeature = feature(geom, properties);

          this.map.addSource('STUDYAREA', {
            type: 'geojson',
            data: studyAreaFeature
          });

          this.map.addLayer({
            id: 'studyareaShape',
            type: 'fill',
            source: 'STUDYAREA',
            paint: {
              'fill-antialias': true,
              'fill-color': '#00DB4A',
              'fill-opacity': 0.10
            }
          });

          this.map.addLayer({
            id: 'studyareaLine',
            type: 'line',
            source: 'STUDYAREA',
            paint: {
              'line-color': '#00A237',
              'line-width': 2,
              'line-opacity': 0.25
            }
          });

          this.map.centerOn(studyAreaFeature);
        } catch (error) {
          this.alertError(error);
        }
      }
    },

    addHeatMapLayer() {
      if (this.map) {
        const heatLayer = new MapboxLayer({
          id: LAYERS.HEATMAP,
          type: HeatmapLayer,
          data: this.data,
          opacity: 0.8,
          getPosition: (d) => [Number(d.longitude), Number(d.latitude)],
          getWeight: (d) => Number(d.extrapolated)
        });
        this.map.addLayer(heatLayer);
        currentLayer = heatLayer;
      }
    },

    addScreenGridLayer() {
      if (this.map) {
        const gridLayer = new MapboxLayer({
          id: LAYERS.SCREENGRID,
          type: ScreenGridLayer,
          data: this.data,
          opacity: 0.8,
          cellSizePixels: 10,
          getPosition: (d) => [Number(d.longitude), Number(d.latitude)],
          getWeight: (d) => Number(d.extrapolated)
        });
        this.map.addLayer(gridLayer);
        currentLayer = gridLayer;
      }
    },

    toggleLayer() {
      if (this.map) {
        Object.values(LAYERS).forEach((layer) => {
          if (this.map.getLayer(layer)) {
            this.map.removeLayer(layer);
          }
        });
        this[`add${this.currentLayer}`]();
      }
    },

    refreshLayer() {
      if (this.map && currentLayer) {
        currentLayer.setProps({
          data: this.data
        });

        if (this.isPlaying) {
          this.play();
        }
      }
    },

    handleSelect(event, chartContext, config) {
      const { dataPointIndex } = config;
      this.intervalIndex = dataPointIndex;
    },

    togglePlayer() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
      this.isPlaying = !this.isPlaying;
    },

    play() {
      timeoutHandler = setTimeout(() => {
        if (this.intervalIndex < this.intervals.length - 1) {
          this.intervalIndex++;
        } else {
          this.intervalIndex = 0;
        }
        if (this.$refs.chart) {
          this.$refs.chart.toggleDataPointSelection(0, this.intervalIndex);
        }
      }, TIMEOUT);
    },

    pause() {
      clearTimeout(timeoutHandler);
    },

    resetZoom() {
      if (this.$refs.chart) {
        this.$refs.chart.zoomX(this.startDate, this.endDate);
      }
    }
  }
};
</script>

<style lang="scss">
.summary-chart {
  cursor: crosshair;

  .apexcharts-inner.apexcharts-graphical {
    transform: translate(10px, 10px);
  }
}
</style>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}

.map-container {
 position: relative;
}

.map-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-loading-bar {
  z-index: 3;
}

.play-button-container {
  display: flex;
  justify-content: center;
}

.headline, .title {
  align-self: center;
}
</style>
