<template>
  <v-card flat :tile="isSmall">
    <v-card-title>
      <div class="headline">{{ $tc('createDlaStudy') }}</div>
      <v-spacer />
      <div v-if="isTrial">{{ $tc('trialStudy') }}</div>
      <div v-else>{{ $tc('cost') }}: <span :class="costTextColor">{{ cost }}</span>/{{ creditsAvailable }} {{ $tc('credits') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form v-model="formValid">
        <v-container class="py-2">
          <v-row>
            <v-col class="py-0">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-container>
                    <v-row>
                      <v-select id="report-organization" v-model="report.organization" label="Organization" prepend-icon="mdi-domain" :items="selectableOrganizations" return-object item-text="name" item-value="id" :readonly="!isAdmin" />
                    </v-row>
                    <v-row>
                      <v-text-field id="report-name" v-model="report.name" label="Report Name" prepend-icon="mdi-text-box-outline" />
                    </v-row>
                    <v-row>
                      <v-text-field id="report-description" v-model="report.description" label="Description" prepend-icon="mdi-text" />
                    </v-row>
                    <v-row>
                      <date-select v-model="report.dates" :limit="isTrial ? 3 : 0" study-product="dla" :organization="report.organization" can-select-future />
                    </v-row>
                    <v-row>
                      <v-combobox id="report-tags" v-model="report.tags" :items="availableTags" label="Tags" prepend-icon="mdi-tag-outline" multiple chips />
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item :value="2">
                  <v-container>
                    <v-row>
                      <study-area-select v-model="report.studyArea" :organization="report.organization" />
                    </v-row>
                    <v-row class="mb-3">
                      <poi-select ref="poiSelect" v-model="report.pois" :organization="report.organization" @createPoi="showCreatePoiForm = true" :disabled="isTrial" />
                    </v-row>
                    <v-row class="mb-5">
                      <v-slider v-model="report.buffer" label="Min Buffer" track-color="dark lighten-5" prepend-icon="mdi-map-marker-circle" min="0" max="100" hide-details>
                        <template v-slot:append>
                          <div class="text-no-wrap">{{ report.buffer }} miles</div>
                        </template>
                      </v-slider>
                    </v-row>
                    <v-row class="mb-5">
                      <v-slider v-model="report.maxBuffer" label="Max Buffer" track-color="dark lighten-5" prepend-icon="mdi-map-marker-circle" min="0" max="100" hide-details>
                        <template v-slot:append>
                          <div class="text-no-wrap">{{ report.maxBuffer }} miles</div>
                        </template>
                      </v-slider>
                    </v-row>
                    <v-row>
                      <v-alert width="100%" dense text border="left" :type="bufferAlertType" v-if="bufferText" >{{ bufferText }}</v-alert>
                    </v-row>
                  </v-container>
                </v-window-item>
              </v-window>
            </v-col>
            <v-col class="py-0" v-if="!isSmall">
              <study-feature-map width="600px" height="450px" :study-area="report.studyArea" :min-buffer="report.buffer" :max-buffer="report.maxBuffer" :pois="report.pois" product="dla" key="dla-feature-map" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-0 small-actions" v-if="isSmall">
      <v-btn class="ma-0" color="error" depressed large tile width="50%" @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn class="ma-0" color="primary" depressed large tile width="50%" :loading="pending" @click="checkout" :disabled="!canCheckout">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn id="create-poi-cancel" color="error" text large @click="cancel" v-if="step === 1">{{ $tc('cancel') }}</v-btn>
      <v-btn id="create-poi-back" color="secondary" text large @click="step--" v-else>{{ $tc('back') }}</v-btn>
      <v-spacer />
      <v-btn id="create-poi-next" color="primary" text large @click="step++" v-if="step === 1">{{ $tc('next') }}</v-btn>
      <v-btn id="report-submit" color="primary" depressed large :loading="pending" @click="checkout" :disabled="!canCheckout" v-else>{{ $tc('submit') }}</v-btn>
    </v-card-actions>
    <create-poi :show.sync="showCreatePoiForm" :organization-override="report.organization" @setPois="setReportPois" />
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import alert from '@/mixins/alert';
import { unfurlNestedDates } from '@/lib/utils';
import StudyFeatureMap from '@/components/StudyFeatureMap';
import DateSelect from '@/components/DateSelect';
import StudyAreaSelect from '@/components/StudyAreaSelect';
import PoiSelect from '@/components/PoiSelect';
import CreatePoi from '@/components/CreatePoi';

export default {
  name: 'ShopDla',

  mixins: [alert],

  props: {
    organization: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    StudyFeatureMap,
    DateSelect,
    StudyAreaSelect,
    PoiSelect,
    CreatePoi
  },

  data() {
    return {
      formValid: false,
      pending: false,
      showCreatePoiForm: false,
      step: 1,
      report: {
        organization: {},
        name: '',
        description: '',
        geolevels: ['state', 'county', 'msa'],
        tags: [],
        dates: [],
        studyArea: '',
        buffer: 0,
        maxBuffer: 0,
        pois: []
      }
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    selectableOrganizations() {
      if (this.isAdmin) {
        return this.organizations;
      }
      return this.organizations.filter((org) => get(org, 'products.dla.write', false));
    },

    canCheckout() {
      const { organization, name = '', dates = [], studyArea, buffer, maxBuffer } = this.report;
      return Boolean(this.isAuthenticated && organization && name.length && dates.length && !isEmpty(studyArea) && (buffer > 0 && maxBuffer > 0 ? buffer < maxBuffer : true) && (this.canAfford || (this.isTrial && !this.hasOrders)));
    },

    availableTags() {
      return !isEmpty(this.report.organization) ? this.tags[this.report.organization.id] : [];
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    cost() {
      const { dates = [], geolevels = [], pois = [] } = this.report;
      return (unfurlNestedDates(dates)).length * geolevels.length * (Math.ceil(pois.length / 20) || 1);
    },

    creditsAvailable() {
      let creditsAvailable = 0;
      if (!this.isTrial) {
        const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
        if (orgInfo) {
          creditsAvailable = orgInfo.credits || 0;
        }
      }
      return creditsAvailable;
    },

    isTrial() {
      let isTrial = false;
      const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
      if (orgInfo) {
        isTrial = orgInfo.trial === undefined ? true : orgInfo.trial;
      }
      return isTrial;
    },

    hasOrders() {
      let hasOrders = false;
      const orgId = get(this.report.organization, 'id', '');
      if (!isEmpty(orgId)) {
        hasOrders = !isEmpty(this.orders[orgId]);
      }
      return hasOrders;
    },

    costTextColor() {
      let textColor = 'black';
      if (this.cost === 0) {
        textColor = 'black';
      } else if (this.cost < this.creditsAvailable) {
        textColor = 'success';
      } else if (this.cost === this.creditsAvailable) {
        textColor = 'warning';
      } else if (this.cost > this.creditsAvailable) {
        textColor = 'error';
      }
      return `${textColor}--text`;
    },

    canAfford() {
      return this.cost <= this.creditsAvailable;
    },

    bufferText() {
      const { buffer, maxBuffer } = this.report;
      let bufferText;
      if (buffer > 0 && maxBuffer === 0) {
        bufferText = `Only including visitors from ${buffer}+ miles away`;
      } else if (buffer === 0 && maxBuffer > 0) {
        bufferText = `Only including visitors within ${maxBuffer} miles away`;
      } else if (buffer > 0 && maxBuffer > 0) {
        if (buffer < maxBuffer) {
          bufferText = `Only including visitors from ${buffer} - ${maxBuffer} miles away`;
        } else {
          bufferText = 'Minimum Buffer must be smaller than Maximum Buffer';
        }
      }
      return bufferText;
    },

    bufferAlertType() {
      const { buffer, maxBuffer } = this.report;
      let bufferAlertType = 'info';

      if ((buffer > 0 && maxBuffer > 0) && (buffer >= maxBuffer)) {
        bufferAlertType = 'error';
      }

      return bufferAlertType;
    },

    ...mapState(['organizations', 'tags', 'orders']),
    ...mapGetters(['userId', 'isAdmin'])
  },

  watch: {
    isTrial() {
      if (this.isTrial && this.report.dates.length > 1) {
        this.report.dates = this.report.dates.slice(0, 1);
      }
    }
  },

  mounted() {
    this.report.organization = this.organization;
  },

  methods: {
    async checkout() {
      try {
        let { organization: { id: organization }, name, dates, geolevels, studyArea, buffer, maxBuffer, pois, description, tags } = this.report;

        this.pending = true;
        const order = {
          name,
          description,
          tags,
          product: 'dla',
          config: {
            geolevels,
            studyArea,
            buffer,
            maxBuffer,
            pois,
            dates: dates.map((date) => date.map((d) => formatDate(parseISO(d), 'yyyyMM')))
          }
        };
        await this.$services.orders.create({ order, organization });
        this.alertSuccess(`${name} Submitted Successfully`);
        this.cancel();
        let organizations = [];
        if (this.isAdmin) {
          const { data } = await this.$services.organizations.all();
          organizations = data;
        } else {
          const { data } = await this.$services.users.listOrganizations(this.userId);
          organizations = data;
        }
        this.setOrganizations(organizations);
        const { data: orders } = await this.$services.organizations.listOrders(organization);
        this.setOrders({ oid: organization, orders });
        const { data: newTags } = await this.$services.organizations.listTags(organization);
        this.setTags({ oid: organization, tags: newTags });
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.report = {
        organization: this.organization,
        name: '',
        description: '',
        geolevels: ['state', 'county', 'msa'],
        tags: [],
        dates: [],
        studyArea: '',
        buffer: 0,
        maxBuffer: 0,
        pois: []
      };
      this.step = 1;
    },

    cancel() {
      this.reset();
      this.$emit('cancel');
    },

    async setReportPois(poiIds) {
      await this.$refs.poiSelect.fetchPois();
      this.report.pois = uniq(this.report.pois.concat(poiIds));
    },

    ...mapActions(['setOrders', 'setTags', 'setOrganizations'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
