import Vue from 'vue';
import Vuex from 'vuex';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import { configureScope } from '@sentry/browser';
import visualize from './modules/visualize';

Vue.use(Vuex);

const debug = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  strict: debug,

  state: {
    user: {},
    organizations: [],
    tags: {},
    poiTags: {},
    poiLabels: {},
    orders: {},
    pois: {},
    alert: {
      color: 'error',
      message: '',
      timeout: 6000
    },
    offsetTop: 0,
    filter: {
      tags: [],
      organizations: [],
      products: [],
      terms: [],
      labels: [],
      sortBy: 'created-desc',
      showArchived: false
    },
    shopVisible: false,
    createPoiVisible: false,
    helpVisible: false
  },

  getters: {
    userName: (state) => state.user.name,
    userEmail: (state) => state.user.email,
    userId: (state) => state.user.sub.split('|')[1],
    isAdmin: (state) => (state.user['http://airsage.com/roles'] || []).includes('Jacquerie Admin'),
    hasScrolled: (state) => state.offsetTop > 0,
    allPois: (state) => flatten(Object.values(state.pois)),
    allTags: (state) => uniq(flatten(Object.values(state.tags))),
    allPoiTags: (state) => uniq(flatten(Object.values(state.poiTags))),
    allPoiLabels: (state) => uniq(flatten(Object.values(state.poiLabels))),
    allOrders: (state) => flatten(Object.values(state.orders)),
    allOrderDates(state, getters) {
      return uniq(getters.allOrders.map((o) => o.created)).sort();
    },
    minDate(state, getters) {
      return minDate(getters.allOrders.map((o) => o.created)).valueOf();
    },
    maxDate(state, getters) {
      return maxDate(getters.allOrders.map((o) => o.created)).valueOf();
    }
  },

  actions: {
    setUser({ commit }, user) {
      configureScope((scope) => {
        const { sub, email, name: username } = user;
        const id = sub.split('|')[1];
        scope.setUser({ id, email, username });
      });
      commit('SET_USER', user);
    },

    setOrganizations({ commit }, organizations) {
      commit('SET_ORGANIZATIONS', organizations);
    },

    setOrders({ commit }, payload) {
      commit('SET_ORDERS', payload);
    },

    setOrderProperty({ commit }, payload) {
      commit('SET_ORDER_PROPERTY', payload);
    },

    setPois({ commit }, payload) {
      commit('SET_POIS', payload);
    },

    setPoiProperty({ commit }, payload) {
      commit('SET_POI_PROPERTY', payload);
    },

    setTags({ commit }, payload) {
      commit('SET_TAGS', payload);
    },

    setPoiTags({ commit }, payload) {
      commit('SET_POI_TAGS', payload);
    },

    setPoiLabels({ commit }, payload) {
      commit('SET_POI_LABELS', payload);
    },

    setAlert({ commit }, alert) {
      commit('SET_ALERT', alert);
    },

    setOffsetTop({ commit }, offset) {
      commit('SET_OFFSET_TOP', offset);
    },

    setFilter({ commit }, filter) {
      commit('SET_FILTER', filter);
    },

    showShop({ commit }) {
      commit('SET_SHOP_VISIBILITY', true);
    },

    hideShop({ commit }) {
      commit('SET_SHOP_VISIBILITY', false);
    },

    showCreatePoi({ commit }) {
      commit('SET_CREATE_POI_VISIBILITY', true);
    },

    hideCreatePoi({ commit }) {
      commit('SET_CREATE_POI_VISIBILITY', false);
    },

    showHelp({ commit }) {
      commit('SET_HELP_VISIBILITY', true);
    },

    hideHelp({ commit }) {
      commit('SET_HELP_VISIBILITY', false);
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations;
    },

    SET_ORDERS(state, payload) {
      const { oid, orders } = payload;
      Vue.set(state.orders, oid, orders);
    },

    SET_ORDER_PROPERTY(state, payload) {
      const { id, key, value } = payload;
      const order = flatten(Object.values(state.orders)).find((o) => o.id === id);
      if (order) {
        Vue.set(order, key, value);
      }
    },

    SET_POIS(state, payload) {
      const { oid, pois } = payload;
      Vue.set(state.pois, oid, pois);
    },

    SET_POI_PROPERTY(state, payload) {
      const { id, key, value } = payload;
      const poi = flatten(Object.values(state.pois)).find((f) => f.id === id);
      if (poi) {
        Vue.set(poi, key, value);
      }
    },

    SET_TAGS(state, payload) {
      const { oid, tags } = payload;
      Vue.set(state.tags, oid, tags.sort((a, b) => a.localeCompare(b, undefined, { ignorePunctuation: true })));
    },

    SET_POI_TAGS(state, payload) {
      const { oid, tags } = payload;
      Vue.set(state.poiTags, oid, tags.sort((a, b) => a.localeCompare(b, undefined, { ignorePunctuation: true })));
    },

    SET_POI_LABELS(state, payload) {
      const { oid, labels } = payload;
      Vue.set(state.poiLabels, oid, labels.sort((a, b) => a.localeCompare(b, undefined, { ignorePunctuation: true })));
    },

    SET_ALERT(state, alert) {
      const { color, message, timeout = 6000 } = alert;
      state.alert = {
        color,
        message,
        timeout: color === 'error' ? 0 : timeout
      };
    },

    SET_OFFSET_TOP(state, offset) {
      state.offsetTop = offset;
    },

    SET_FILTER(state, filter) {
      state.filter = filter;
    },

    SET_SHOP_VISIBILITY(state, visibility) {
      state.shopVisible = visibility;
    },

    SET_CREATE_POI_VISIBILITY(state, visibility) {
      state.createPoiVisible = visibility;
    },

    SET_HELP_VISIBILITY(state, visibility) {
      state.helpVisible = visibility;
    }
  },

  modules: {
    visualize
  }
});
