<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="rangeBar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import isNumber from 'lodash/isNumber';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    x: {
      type: String,
      default: 'state',
      validator: (val) => ['state', 'msa', 'county', 'study_date'].includes(val)
    },

    limit: {
      type: Number,
      default: undefined
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter(v) {
            return v ? `${v.toFixed(0)}%` : '';
          }
        },
        stroke: {
          width: 0
        },
        grid: {
          show: false
        },
        colors: ['#014E68'],
        legend: {
          show: false
        },
        tooltip: {
          x: {
            formatter(v) {
              if (isNumber(v)) {
                return `${v}%`;
              }
              return v;
            }
          }
        },
        xaxis: {
          categories: [],
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: true
          }
        },
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: 2,
          labels: {
            show: true,
            formatter(v) {
              return v ? `${v.toFixed(0)}%` : '0%';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    },

    series() {
      return [this.data.filter((d) => d.percent_overnight_low > 0 && d.percent_overnight_high > 0).slice(0, this.limit).reduce((series, d) => {
        const overnightLow = (Number(d.percent_overnight_low) * 100).toFixed(0);
        const overnightHigh = (Number(d.percent_overnight_high) * 100).toFixed(0);
        series.data.push({
          x: this.x === 'study_date' ? format(parseISO(d[this.x]), 'MMM yyyy') : d[this.x],
          y: [overnightLow, overnightHigh]
        });
        return series;
      }, {
        name: 'Overnight Stay Percentage Range',
        data: []
      })];
    }
  }
};
</script>
