<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

const sorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};

function sortByDay(a, b) {
  let day1 = a.toLowerCase();
  let day2 = b.toLowerCase();
  return sorter[day1] - sorter[day2];
}

function sortByDayObject(a, b) {
  let day1 = a.weekday.toLowerCase();
  let day2 = b.weekday.toLowerCase();
  return sorter[day1] - sorter[day2];
}

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      const weekdays = new Set();
      if (!isEmpty(this.data)) {
        Object.values(this.data).forEach((d) => d.forEach((d) => weekdays.add(d.weekday)));
      }
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1
        },
        grid: {
          show: false
        },
        colors: Object.values(this.comparisonColors),
        legend: {
          show: false
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: false
        },
        xaxis: {
          categories: [...weekdays].sort(sortByDay),
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false,
            formatter(v) {
              return v ? mk(v) : '';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    },

    series() {
      const totals = [];
      Object.entries(this.data).sort((a, b) => {
        const keyA = a[0].toLowerCase();
        const keyB = b[0].toLowerCase();
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }).forEach(([dataSet, d]) => {
        totals.push({
          name: this.comparisonNames[dataSet],
          data: d.sort(sortByDayObject).map((r) => r.extrapolated_devices)
        });
      });
      return totals;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
