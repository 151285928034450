<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="300px" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="primary" outlined>
        <v-icon left>mdi-calendar</v-icon>
        <span>{{ selectedDateText }}</span>
      </v-btn>
      <!-- <help-card :subject="$refs.button" title="Date Filter" description="This is a Test" /> -->
    </template>
    <v-list width="320px">
      <v-list-item class="mt-2">
        <v-combobox v-model="selectedDatesChips" multiple chips small-chips label="Filtered Dates" readonly>
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" close @click:close="removeDates(data.index)">{{ data.item }}</v-chip>
          </template>
        </v-combobox>
      </v-list-item>
      <v-divider class="py-1" />
      <v-list-item>
        <v-date-picker ref="picker" v-model="newDates" :min="allowedDates.min" :max="allowedDates.max" :allowed-dates="isInStudyDates" :type="pickerType" range no-title>
          <v-container pa-0>
            <v-row>
              <v-col>
                <v-btn color="accent" outlined @click="newDates = []">Clear</v-btn>
              </v-col>
              <v-spacer/>
              <v-col class="d-flex flex-row-reverse">
                <v-btn color="primary" depressed @click="addDates(newDates)">Add</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-date-picker>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import isBefore from 'date-fns/isBefore';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
// import HelpCard from '@/components/HelpCard';
import alert from '@/mixins/alert';
import { unfurlNestedDates } from '@/lib/utils';

export default {
  mixins: [alert],

  // components: {
  //   HelpCard
  // },

  data() {
    return {
      menu: false,
      newDates: []
    };
  },

  computed: {
    dateFormatString() {
      return this.isMonthly ? 'MMM yyyy' : 'MMM dd yyyy';
    },

    pickerType() {
      return this.isMonthly ? 'month' : 'date';
    },

    selectedDates: {
      get() {
        return this.filteredDateRange;
      },

      set(value) {
        this.setDateFilter(value);
      }
    },

    selectedDatesChips: {
      cache: false,
      get() {
        return this.selectedDates.map(([start, end]) => {
          start = formatDate(parseISO(start), this.dateFormatString);
          if (end) {
            end = formatDate(parseISO(end), this.dateFormatString);
            return `${start} - ${end}`;
          }
          return start;
        });
      }
    },

    selectedDateText() {
      let dateText;
      const formatString = this.isMonthly ? 'MMM yyyy' : 'MMM dd yyyy';
      const processDateText = (dateRange = []) => {
        let dateText = '';
        dateRange = dateRange.map((d) => parseISO(d));
        if (dateRange.length === 1) {
          dateText = formatDate(first(dateRange), formatString);
        } else if (dateRange.length) {
          dateText = `${formatDate(minDate(dateRange), formatString)} - ${formatDate(maxDate(dateRange), formatString)}`;
        }
        return dateText;
      };
      if (!isEmpty(this.selectedDates)) {
        dateText = this.selectedDates.map(processDateText).join(', ');
      } else {
        dateText = this.dates.map(processDateText).join(', ');
      }
      return dateText;
    },

    allowedDates() {
      const formatString = this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd';
      let dates = unfurlNestedDates(this.dates, { interval: this.isMonthly ? 'month' : 'day' });
      let min = '2018-01-01';
      let max = '2020-12-31'; // TODO: possibly get the latest ingested month here.
      if (dates.length) {
        min = formatDate(minDate(dates), formatString);
        max = formatDate(maxDate(dates), formatString);
        dates = dates.map((d) => formatDate(d, formatString));
      }
      return { min, max, dates };
    },

    ...mapState('visualize', ['study', 'dates']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredDates', 'isMonthly'])
  },

  watch: {
    allowedDates: {
      deep: true,
      handler() {
        let formatString;
        if (this.isMonthly) {
          formatString = 'yyyy';
        } else {
          formatString = 'yyyy-M';
        }
        if (this.allowedDates.max) {
          this.$refs.picker.tableDate = formatDate(parseISO(this.allowedDates.max), formatString);
        }
      }
    }
  },

  methods: {
    isInStudyDates(date) {
      return this.allowedDates.dates.includes(date) && (!isEmpty(this.selectedDates) ? !this.currentlyPicking(date) : true);
    },

    currentlyPicking(date) {
      if (isEmpty(this.selectedDates)) {
        return false;
      }

      const selectedDates = flatten(this.selectedDates.map((dr) => {
        let [start, end] = dr.map((d) => parseISO(d));
        date = formatDate(parseISO(date), this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd');
        if (!end) {
          return formatDate(start, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd');
        }
        let pickedDates = this.isMonthly ? eachMonthOfInterval({ start, end }) : eachDayOfInterval({ start, end });
        pickedDates = pickedDates.map((d) => formatDate(d, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd'));
        return pickedDates;
      }));
      return selectedDates.includes(date);
    },

    addDates() {
      const dates = cloneDeep(this.selectedDates);
      if (this.newDates.length) {
        if (this.newDates.length > 1) {
          let [start, end] = this.newDates.map((d) => parseISO(d));
          if (isBefore(end, start)) {
            const start2 = start;
            start = end;
            end = start2;
            this.newDates = [start, end].map((d) => formatDate(d, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd'));
          }
        }
        dates.push(this.newDates);
        this.selectedDates = dates;
      }
      this.newDates = [];
    },

    removeDates(index) {
      const dates = cloneDeep(this.selectedDates);
      dates.splice(index, 1);
      this.selectedDates = dates;
    },

    ...mapActions('visualize', ['setDateFilter'])
  }
};
</script>
