<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <div class="text-subtitle-1" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="heatmap" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import chart from '@/mixins/chart';
import { mk, formatIncomeLevel } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: Object.keys(this.data).sort().map((d) => this.comparisonColors[d]).reverse(),
        plotOptions: {
          heatmap: {
            distributed: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter(v) {
            return v ? mk(v) : '';
          }
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        tooltip: {
          shared: false,
          y: {
            formatter(v) {
              return v ? mk(v) : '';
            }
          }
        },
        yaxis: [{
          labels: {
            show: false
          }
        }],
        legend: {
          show: false
        }
      };
    },

    series() {
      let incomeLevels;
      switch (this.activeIso) {
        case 'is':
          incomeLevels = ['socioeconomic_class_0', 'socioeconomic_class_1', 'socioeconomic_class_2', 'socioeconomic_class_3', 'socioeconomic_class_4', 'socioeconomic_class_5', 'socioeconomic_class_6', 'socioeconomic_class_7', 'socioeconomic_class_8', 'socioeconomic_class_9', 'socioeconomic_class_10'];
          break;

        case 'us': default:
          incomeLevels = ['income_0_10', 'income_10_15', 'income_15_20', 'income_20_25', 'income_25_30', 'income_30_35', 'income_35_40', 'income_40_45', 'income_45_50', 'income_50_60', 'income_60_75', 'income_75_100', 'income_100_125', 'income_125_150', 'income_150_200', 'income_over_200'];
      }
      return Object.entries(this.data).sort((a, b) => {
        const keyA = a[0].toLowerCase();
        const keyB = b[0].toLowerCase();
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }).reverse().reduce((series, [dataSet, data]) => {
        series.push({
          name: this.comparisonNames[dataSet],
          data: incomeLevels.map((i) => {
            return {
              x: formatIncomeLevel(i, this.activeIso),
              y: data.find((d) => d.income_level === i).total || 0
            };
          })
        });
        return series;
      }, []);
    },

    ...mapState('visualize', ['activeIso']),
    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
