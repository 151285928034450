<template>
  <v-card class="mb-5" outlined>
    <v-card-text>
      <data-fetcher height="400" report="getCountyMatrix" :filter="{ studyDates: filteredDateRange, poiIds: filteredPois, aggregation: filteredAggregation }" must-have-pois v-slot="{ data }">
        <trip-matrix :data="data" />
      </data-fetcher>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import DataFetcher from '@/components/charts/DataFetcher';
import TripMatrix from '@/components/charts/TripMatrix';

export default {
  components: {
    DataFetcher,
    TripMatrix
  },

  computed: mapGetters('visualize', ['filteredDateRange', 'filteredPois', 'filteredAggregation'])
};
</script>
