<template>
  <apexchart ref="chart" class="summary-chart" type="area" :height="height" :options="options" :series="series"/>
</template>

<script>
import { format as formateDate } from 'date-fns';
import { padStart, range } from 'lodash';

const categories = [...Array(25).keys()];

let selectionTimeout;

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: Number,
      default: 120
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false,
            autoSelected: 'selection'
          },
          selection: {
            enabled: true,
            xaxis: {
              min: 0,
              max: 23
            }
          },
          events: {
            selection: this.handlePan
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        markers: {
          size: 4
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        colors: ['#1675D1'],
        xaxis: {
          type: 'numeric',
          labels: {
            formatter(v) {
              const d = new Date();
              v = v === 24 ? 0 : v;
              d.setHours(v);
              return formateDate(d, 'haaa');
            }
          },
          tickAmount: 'dataPoints',
          tickPlacement: 'on'
        },
        yaxis: {
          show: false
        }
      };
    },

    series() {
      const series = {
        name: 'count',
        data: []
      };
      if (this.data.length) {
        this.data.forEach((d, index) => {
          const { count } = d;
          series.data.push([categories[index], count]);
        });
        series.data.push([24, 0]);
      }
      return [series];
    }
  },

  methods: {
    handlePan(chartContext, { xaxis: { min, max } }) {
      if (selectionTimeout) {
        clearTimeout(selectionTimeout);
      }
      selectionTimeout = setTimeout(() => {
        const hours = range(Math.round(min), Math.round(max)).map((h1) => {
          return `H${padStart(h1, 2, '0')}:H${padStart(h1 + 1, 2, '0')}`;
        });
        this.$emit('selection', hours);
      }, 750);
    }
  }
};
</script>

<style lang="scss">
.summary-chart {
  .apexcharts-inner.apexcharts-graphical {
    transform: translate(18px, 10px);
  }
}
</style>
