<template>
  <v-simple-table>
    <thead>
      <tr>
        <th></th>
        <th v-for="(group, name) in dataSets" :key="`${name}-header`" class="title text-capitalize" :style="`color: ${comparisonColors[name]}`">{{ comparisonNames[name] }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Visitors</td>
        <td v-for="(group, name) in dataSets" :key="`${name}-count`" class="text-capitalize">{{ group.extrapolated_devices | mkF }}</td>
      </tr>
      <tr>
        <td>Average Duration</td>
        <td v-for="(group, name) in dataSets" :key="`${name}-duration`" class="text-capitalize">{{ group.average_duration | round25F }} hr</td>
      </tr>
      <tr>
        <td>
          <v-row dense>
            <v-col>
              <span style="white-space: nowrap">Typical Visitor Persona</span>
            </v-col>
            <v-spacer />
            <v-col>
              <table class="poi-summary-table">
                <tr>
                  <td class="text-caption text-right">Ethnicity</td>
                </tr>
                <tr>
                  <td class="text-caption text-right">Gender</td>
                </tr>
                <tr>
                  <td class="text-caption text-right">Income</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </td>
        <td v-for="(group, name) in dataSets" :key="`${name}-persona`">
          <table class="poi-summary-table">
            <tr>
              <td class="text-capitalize">{{ group.ethnicity }}</td>
            </tr>
            <tr>
              <td class="text-capitalize">{{ group.gender_age | formatGender }}</td>
            </tr>
            <tr>
              <td>{{ group.income_level | incomeF }}</td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { mk, round25, formatIncomeLevel, formatAge, formatGender } from '@/lib/utils';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    dataSets() {
      const dataSets = {};
      Object.entries(this.data).sort((a, b) => {
        const keyA = a[0].toLowerCase();
        const keyB = b[0].toLowerCase();
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }).forEach(([dataSet, d]) => {
        dataSets[dataSet] = d;
      });
      return dataSets;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  },

  filters: {
    mkF: mk,
    round25F: round25,
    incomeF: formatIncomeLevel,
    formatGender,
    formatAge
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #FFFFFF;
}
</style>
