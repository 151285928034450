<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <div class="text-subtitle-1" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import tinycolor from 'tinycolor2';
import sum from 'lodash/sum';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: [Array, Object],
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    aggregations() {
      let aggregations;
      switch (this.activeIso) {
        case 'is':
          aggregations = {
            age_0_14: ['0_5', '5_9', '10_14'],
            age_15_29: ['15_19', '20_24', '25_29'],
            age_30_44: ['30_34', '35_39', '40_44'],
            age_45_59: ['45_49', '50_54', '55_59'],
            age_60_74: ['60_64', '65_69', '70_74'],
            age_75_over: ['75_over']
          };
          break;

        case 'us': default:
          aggregations = {
            age_0_14: ['0_5', '5_9', '10_14'],
            age_15_29: ['15_17', '18_19', '20_20', '21_21', '22_24', '25_29'],
            age_30_44: ['30_34', '35_39', '40_44'],
            age_45_59: ['45_49', '50_54', '55_59'],
            age_60_74: ['60_61', '62_64', '65_66', '67_69', '70_74'],
            age_75_over: ['75_79', '80_84', 'over_85']
          };
      }
      return aggregations;
    },

    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          stacked: true
        },
        colors: Object.values(this.comparisonColors).reduce((colors, color) => {
          colors.push(color);
          colors.push(tinycolor(color).brighten(18).toString());
          return colors;
        }, []),
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter(v) {
            return v ? mk(Math.abs(v)) : '';
          }
        },
        stroke: {
          width: 1
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter(v) {
              return v ? `${v.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}` : '';
            }
          },
          y: {
            formatter(v) {
              return v ? mk(Math.abs(v)) : '';
            }
          }
        },
        yaxis: {
          labels: {
            formatter(v) {
              return v ? `${v.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}` : '';
            }
          }
        },
        xaxis: {
          categories: Object.keys(this.aggregations).reverse().map((k) => k.replace('age_', '').replace('_', '-').replace('-over', '+')),
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
    },

    series() {
      const series = [];

      Object.entries(this.data).forEach(([dataSet, data]) => {
        const maleData = data.filter((d) => startsWith(d.gender_age, 'male_'));
        const femaleData = data.filter((d) => startsWith(d.gender_age, 'female_'));
        series.push({
          name: `${this.comparisonNames[dataSet]}: Male`,
          data: reduce(this.aggregations, (result, value) => {
            result.push(sum(maleData.filter((d) => value.includes(d.gender_age.replace('male_', ''))).map((d) => Number(d.total))));
            return result;
          }, []).reverse()
        });
        series.push({
          name: `${this.comparisonNames[dataSet]}: Female`,
          data: reduce(this.aggregations, (result, value) => {
            result.push(sum(femaleData.filter((d) => value.includes(d.gender_age.replace('female_', ''))).map((d) => Number(d.total))) * -1);
            return result;
          }, []).reverse()
        });
      });
      return series;
    },

    ...mapState('visualize', ['activeIso']),
    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
