<template>
  <v-row justify="center">
    <v-col cols="4">
      <v-card flat>
        <v-card-title>
          <v-icon color="primary" x-large>mdi-account-group-outline</v-icon>
          <div class="pl-4">Estimated Visits</div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <table class="poi-summary-table">
            <tr>
              <td class="font-weight-medium">Total:</td>
              <td class="text-capitalize text-right">{{ totals.extrapolated_devices | mkF }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">Average per Day:</td>
              <td class="text-capitalize text-right">{{ totals.average_devices | mkF }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card flat>
        <v-card-title>
          <v-icon color="primary" x-large>mdi-account-outline</v-icon>
          <div class="pl-4">Typical Visitor Persona</div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <table class="poi-summary-table">
            <tr>
              <td class="font-weight-medium">Ethnicity:</td>
              <td class="text-capitalize text-right">{{ totals.ethnicity }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">Gender:</td>
              <td class="text-capitalize text-right">{{ totals.gender_age | formatGenderF }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">Income:</td>
              <td class="text-right">{{ totals.income_level | formatIncomeLevelF }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card flat>
        <v-card-title>
          <v-icon color="primary" x-large>mdi-clock-outline</v-icon>
          <div class="pl-4">Time & Duration</div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <table class="poi-summary-table">
            <tr>
              <td class="font-weight-medium">Average Length of Stay:</td>
              <td class="text-capitalize text-right">{{ totals.average_duration | round25F }} hours</td>
            </tr>
            <tr>
              <td class="font-weight-medium">Busiest Day of Week:</td>
              <td class="text-capitalize text-right">{{ totals.busiest_weekday }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">Busiest Hour:</td>
              <td class="text-right">{{ totals.busiest_hour | formatHourF }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import first from 'lodash/first';
import { mk, round25, formatIncomeLevel, formatGender, formatAge, formatHour } from '@/lib/utils';

export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    totals() {
      if (this.data.length) {
        return first(this.data);
      }
      return {};
    }
  },

  filters: {
    mkF: mk,
    round25F: round25,
    formatIncomeLevelF: formatIncomeLevel,
    formatGenderF: formatGender,
    formatAgeF: formatAge,
    formatHourF: formatHour
  }
};
</script>

<style lang="scss">
.poi-summary-table {
  width: 100%;
}
</style>
