var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.poi.name))]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('choropleth-map',{on:{"level":_vm.setActiveLevel,"iso":_vm.setActiveIso}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getPoiSummary","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('poi-summary-totals',{attrs:{"data":data}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getDailyPoiTrend","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('trend-daily',{attrs:{"height":"350","title":"Daily Visitation Pattern","data":data}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getWeekdayHoursByPoi","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('weekday-heatmap',{attrs:{"height":"350","title":"Hourly Visitation Heatmap","data":data}})]}}])})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getPoiVisitDuration","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('visit-duration-chart',{attrs:{"height":"350","title":"Visit Duration","data":data}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getPoiTripMatrix","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('trip-chart',{attrs:{"title":"Visitor Flow","width":1200,"data":data}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getEthnicityByPoi","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('ethnicity-chart',{attrs:{"title":"Ethnicity","height":"250","data":data}})]}}])})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getGenderAgeByPoi","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('gender-age-chart',{attrs:{"title":"Gender & Age","height":"250","data":data}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('data-fetcher',{attrs:{"height":"350","report":"getIncomeByPoi","filter":{ poiIds: [_vm.poi.id], studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('income-chart',{attrs:{"title":"Income","data":data}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }