<template>
  <v-dialog max-width="800" v-model="show" :fullscreen="isSmall" persistent>
    <v-card flat :tile="isSmall">
      <v-card-title>
        <div class="headline">Create Point of Interest</div>
        <v-spacer />
        <v-chip color="dark" class="caption white--text" pill v-if="step > 2 && step < numSteps">{{ step - 2 }} of {{ pois.length }}</v-chip>
      </v-card-title>
      <v-divider />
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-btn color="primary" depressed block x-large @click="declareIntent('draw')">
                      <v-icon left>mdi-vector-polygon</v-icon>
                      <span>Draw</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <div class="subtitle-1 text-uppercase">or</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="secondary" depressed block x-large @click="declareIntent('upload')">
                      <v-icon left>mdi-cloud-upload-outline</v-icon>
                      <span>Upload</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-window-item>
          <v-window-item :value="2" eager>
            <v-card-text>
              <v-container>
                <v-row v-if="!hasOrganizationOverride">
                  <v-select id="poi-organization" v-model="organization" label="Organization" prepend-icon="mdi-domain" :items="organizations" return-object item-text="name" item-value="id" :readonly="organizations.length === 1" />
                </v-row>
                <v-row v-if="intent === 'draw'">
                  <johannes v-model="pois[0]" height="400px" width="1200px" />
                </v-row>
                <v-row v-else>
                  <v-file-input id="poi-file" v-model="upload.file" accept=".zip,.json,.geojson" label="POIs" :error="upload.fileInError" :loading="upload.checkingFile" :error-messages="upload.fileErrorMessage" show-size @change="checkFile">
                    <template v-slot:append>
                      <v-icon color="success" v-if="upload.file && upload.fileGood && !upload.checkingFile">mdi-check</v-icon>
                      <v-icon color="error" v-else-if="upload.file && !upload.checkingFile">mdi-alert-circle-outline</v-icon>
                    </template>
                  </v-file-input>
                </v-row>
              </v-container>
            </v-card-text>
          </v-window-item>
          <v-window-item v-for="(f, index) in pois" :value="index + 3" :key="`poi-${index}-${f.id}-window`" >
            <v-card-text>
              <edit-poi v-model="poisValid[index]" :key="`poi-${index}-${f.id}`" :poi="f" :organization="organization" />
            </v-card-text>
          </v-window-item>
          <v-window-item :value="numSteps">
            <v-card-text>
              <div class="pa-4 text-center">
                <v-icon color="success" size="128">mdi-check-circle-outline</v-icon>
                <h3 class="body-1 mb-2">Ready to save</h3>
                <span class="caption grey--text">All shapes check out!</span>
              </div>
            </v-card-text>
          </v-window-item>
        </v-window>
        <v-divider />
      <v-card-actions>
        <v-btn id="create-poi-cancel" color="error" text large @click="cancel" v-if="step === 1">Cancel</v-btn>
        <v-btn id="create-poi-back" color="secondary" text large @click="step--" v-else>Back</v-btn>
        <v-spacer />
        <v-btn id="create-poi-next" color="primary" text large @click="step++" :disabled="!canProceed" v-if="step > 1 && step < numSteps">Next</v-btn>
        <v-btn id="create-poi-save" color="primary" depressed large :loading="pending" @click="saveAll" :disabled="!canSaveAll" v-else-if="step === numSteps">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import alert from '@/mixins/alert';
import EditPoi from '@/components/EditPoi';
import Johannes from '@/components/johannes/Johannes';

export default {
  name: 'CreatePoi',

  mixins: [alert],

  components: {
    EditPoi,
    Johannes
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    organizationOverride: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      organization: {},
      formValid: false,
      pending: false,
      step: 1,
      pois: [],
      upload: {
        file: null,
        fileGood: false,
        fileErrorMessage: '',
        checkingFile: false
      },
      intent: '',
      poisValid: []
    };
  },

  computed: {
    canSaveAll() {
      return this.allPoisValid;
    },

    numSteps() {
      return Math.max(3, this.pois.length + 3);
    },

    canProceed() {
      let canProceed = false;
      if (this.step === 2) {
        if (this.intent === 'draw') {
          canProceed = !isEmpty(this.pois[0]);
        } else if (this.intent === 'upload') {
          canProceed = this.upload.file && this.upload.fileGood && !this.upload.checkingFile;
        }
      } else if (this.step > 2 && this.step < this.numSteps) {
        canProceed = this.poisValid[this.step - 3];
      } else {
        canProceed = this.step < this.numSteps;
      }
      return canProceed;
    },

    allPoisValid() {
      return this.poisValid.every((p) => p);
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    hasOrganizationOverride() {
      return !isEmpty(this.organizationOverride);
    },

    ...mapState(['organizations']),
    ...mapGetters(['userId', 'isAdmin'])
  },

  watch: {
    organizations: {
      immediate: true,
      handler() {
        if (isEmpty(this.organizationOverride)) {
          // eslint-disable-next-line prefer-destructuring
          this.organization = this.organizations[0];
        }
      }
    },

    organizationOverride: {
      immediate: true,
      handler() {
        if (!isEmpty(this.organizationOverride)) {
          this.organization = this.organizationOverride;
        }
      }
    }
  },

  methods: {
    reset() {
      this.organization = this.organizationOverride || (this.organizations || [])[0] || {};
      this.step = 1;
      this.pois = [];
      this.upload = {
        file: null,
        fileGood: false,
        fileErrorMessage: '',
        checkingFile: false
      };
      this.intent = '';
    },

    cancel() {
      this.reset();
      this.$emit('update:show', false);
    },

    declareIntent(intent) {
      this.intent = intent;
      this.step = 2;
    },

    async checkFile() {
      try {
        this.upload.fileErrorMessage = '';
        if (this.upload.file) {
          this.upload.checkingFile = true;
          const formData = new FormData();
          formData.append('file', this.upload.file);
          const { data } = await this.$services.geometry.processFile(formData, { params: { bypassCheck: true } });
          this.pois = data;
          if (!Array.isArray(this.pois)) {
            this.pois = [this.pois];
          }
          this.pois = this.pois.map((d) => {
            // assign temp id for use a :key to force component refresh
            d.id = `poi-${uuidv4()}`;
            return d;
          });
          this.upload.fileGood = true;
        }
      } catch (error) {
        this.upload.fileGood = false;
        this.upload.fileErrorMessage = get(error, 'response.data.message', get(error, 'response.message', 'Unknown'));
      } finally {
        this.upload.checkingFile = false;
      }
    },

    async saveAll() {
      try {
        if (this.canSaveAll) {
          this.pending = true;
          const oid = this.organization.id;
          const feature = this.pois.map((p) => {
            // remove temp id
            delete p.id;
            return p;
          });
          const { data } = await this.$services.geometry.create({ feature, organization: oid });
          this.$emit('setPois', data.map((p) => p.properties.id));
          const { data: pois } = await this.$services.organizations.listPois(oid);
          this.setPois({ oid, pois });
          const { data: tags } = await this.$services.organizations.listPoiTags(oid);
          this.setPoiTags({ oid, tags });
          const { data: labels } = await this.$services.organizations.listPoiLabels(oid);
          this.setPoiLabels({ oid, labels });
          this.alertSuccess('POIs successfully saved');
        }
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
        this.reset();
        this.$emit('update:show', false);
      }
    },

    ...mapActions(['setPois', 'setPoiTags', 'setPoiLabels'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
