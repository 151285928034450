<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <div class="text-subtitle-1" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-0">
      <apexchart ref="chart" type="treemap" :height="height" :options="options" :series="series" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import startCase from 'lodash/startCase';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: Object.values(this.comparisonColors),
        stroke: {
          width: 1
        },
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: (v) => v ? mk(v) : ''
          }
        }
      };
    },

    series() {
      const series = [];
      const ethnicities = ['white', 'black', 'asian', 'native_american', 'hawaiian_pacific', 'other', 'two_with_other', 'two_ex_other_and_three_plus'];
      Object.entries(this.data).forEach(([dataSet, data]) => {
        series.push({
          name: this.comparisonNames[dataSet],
          data: ethnicities.map((e) => ({
            x: startCase(e),
            y: data.find((d) => d.ethnicity === e).total || 0
          }))
        });
      });
      return series;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
