import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import isSameMonth from 'date-fns/isSameMonth';
import isValidDate from 'date-fns/isValid';
import uniq from 'lodash/uniq';
import first from 'lodash/first';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import { unfurlNestedDates } from '@/lib/utils';

const namespaced = true;

const NWTM_MAX_SELECTED_COUNTIES = 10;

const state = {
  study: {},
  dates: [],
  features: [],
  homeLocations: {},
  filter: {
    dates: [],
    pois: [],
    aggregation: 'Mon_Tue_Wed_Thu',
    timesOfDay: [],
    homeLocations: []
  },
  comparisonGroups: {
    a: {
      name: 'Group A',
      color: '#2F3B44'
    },
    b: {
      name: 'Group B',
      color: '#006F6B'
    },
    c: {
      name: 'Group C',
      color: '#599C3D'
    },
    d: {
      name: 'Group D',
      color: '#FFA600'
    }
  },
  activePoi: {},
  activeIso: 'us',
  activeLevel: 'state'
};

const getters = {
  pois: (state) => state.features && state.study ? state.study.product === 'nwtm' ? state.features : state.features.filter((f) => f.purpose === 'poi') : [],
  studyArea: (state) => state.features ? state.features.find((f) => f.purpose === 'studyarea') || {} : {},
  labels: (state) => state.features ? uniq(state.features.filter((f) => f.purpose === 'poi').map((p) => p.label)) : [],
  isMonthly: (state) => ['dla', 'nwtm'].includes(state.study.product),
  allStudyDates: (state, getters) => unfurlNestedDates(state.dates, { interval: getters.isMonthly ? 'month' : 'day', returnFormatted: true }),
  filteredDateRange: (state) => state.filter.dates,
  filteredDates: (state, getters) => unfurlNestedDates(state.filter.dates, { interval: getters.isMonthly ? 'month' : 'day', returnFormatted: true }),
  filteredPois: state => state.filter.pois,
  filteredHomeLocations: state => state.filter.homeLocations,
  filteredAggregation: state => state.filter.aggregation || 'Mon_Tue_Wed_Thu',
  filteredTimesOfDay: state => state.filter.timesOfDay || [],
  parentOrganization: (state, getters, rootState) => rootState.organizations.find((o) => o.id === state.study.organization),
  allowedCountries: (state, getters) => get(getters.parentOrganization, `products.${state.study.product}.additional.countries`, ['us']),
  comparisonColors: (state) => {
    let comparisonColors = mapValues(state.comparisonGroups, 'color');
    if (state.study.product === 'tla') {
      comparisonColors = pickBy(comparisonColors, (v, k) => !isEmpty(state.filter.pois[k]));
    }
    return comparisonColors;
  },
  comparisonNames: (state) => mapValues(state.comparisonGroups, 'name')
};

const actions = {
  setStudy({ commit }, study) {
    commit('SET_STUDY', study);
  },

  setStudyName({ commit }, name) {
    commit('SET_STUDY_NAME', name);
  },

  setDates({ commit, state }, dates) {
    const parsedDates = dates.map((d) => parseISO(d));
    if (parsedDates.length && parsedDates.every(isValidDate)) {
      const start = minDate(parsedDates);
      const end = maxDate(parsedDates);
      dates = [start, end];
      if (['tla', 'ad'].includes(state.study.product) && start === end) {
        dates = [start];
      } else if (['dla', 'nwtm'].includes(state.study.product) && isSameMonth(start, end)) {
        dates = [start];
      }
      dates = dates.map((d) => format(d, ['dla', 'nwtm'].includes(state.study.product) ? 'yyyy-MM' : 'yyyy-MM-dd'));
    }
    commit('SET_DATES', [dates.map((d) => format(parseISO(d), state.study.product === 'tla' ? 'yyyyMMdd' : 'yyyyMM'))]);
  },

  setDateFilter({ commit }, dates) {
    commit('SET_DATE_FILTER', dates);
  },

  setHomeLocationFilter({ commit }, homeLocations) {
    commit('SET_HOME_LOCATION_FILTER', homeLocations);
  },

  async setFeatures({ commit, state }, features) {
    const firstPoi = first(features);
    commit('SET_FEATURES', features);
    // load study home locations (for DLA and TLA)
    if (['dla', 'tla'].includes(state.study.product)) {
      const { data: homeLocations } = await this._vm.$services.orders.getHomeLocations(state.study.id, {
        params: { poiId: firstPoi.id }
      });
      commit('SET_HOME_LOCATIONS', homeLocations);
    }
    commit('SET_ACTIVE_POI', firstPoi);
  },

  setPoiFilter({ commit }, pois) {
    commit('SET_POI_FILTER', pois);
  },

  setAggregationFilter({ commit }, aggregation) {
    commit('SET_AGGREGATION_FILTER', aggregation);
  },

  setTimesOfDayFilter({ commit }, timesOfDay) {
    commit('SET_TIMES_OF_DAY_FILTER', timesOfDay);
  },

  reset({ commit }) {
    commit('RESET');
  },

  async setActivePoi({ commit, state, rootGetters }, poi) {
    if (isArray(poi)) {
      poi = poi.map((p) => {
        if (!isObject(p)) {
          return rootGetters['visualize/pois'].find((vp) => vp.id === p);
        }
        return p;
      });
    }
    if (!isObject(poi)) {
      poi = rootGetters['visualize/pois'].find((p) => p.id === poi);
    }

    // load study home locations (for DLA and TLA)
    if (['dla', 'tla'].includes(state.study.product)) {
      let homeLocations;
      if (isArray(poi)) {
        ({ data: homeLocations } = await this._vm.$services.orders.getHomeLocations(state.study.id, {
          params: { poiId: poi.map(p => p.id) }
        }));
      } else {
        ({ data: homeLocations } = await this._vm.$services.orders.getHomeLocations(state.study.id, {
          params: { poiId: poi.id }
        }));
      }
      commit('SET_HOME_LOCATIONS', homeLocations);
    }
    commit('SET_ACTIVE_POI', poi);
  },

  setActiveIso({ commit }, iso) {
    commit('SET_ACTIVE_ISO', iso);
  },

  setActiveLevel({ commit }, level) {
    commit('SET_ACTIVE_LEVEL', level);
  },

  setGroupName({ commit }, payload) {
    commit('SET_GROUP_NAME', payload);
  }
};

const mutations = {
  SET_STUDY(state, study) {
    state.study = study;
  },

  SET_STUDY_NAME(state, name) {
    state.study.name = name;
  },

  SET_DATES(state, dates) {
    state.dates = dates;
  },

  SET_DATE_FILTER(state, dates) {
    state.filter.dates = dates;
  },

  SET_HOME_LOCATION_FILTER(state, homeLocations) {
    state.filter.homeLocations = homeLocations;
  },

  SET_AGGREGATION_FILTER(state, aggregation) {
    state.filter.aggregation = aggregation;
  },

  SET_TIMES_OF_DAY_FILTER(state, timesOfDay) {
    state.filter.timesOfDay = timesOfDay;
  },

  SET_FEATURES(state, features) {
    state.features = features;
    if (state.study.product === 'tla') {
      const firstPoi = first(features);
      state.filter.pois = { a: [firstPoi.id], b: [], c: [], d: [] };
    } else if (state.study.product === 'nwtm') {
      state.filter.pois = features.map((f) => f.fips).slice(0, NWTM_MAX_SELECTED_COUNTIES);
    }
  },

  SET_HOME_LOCATIONS(state, homeLocations) {
    state.homeLocations = homeLocations;
  },

  SET_POI_FILTER(state, payload) {
    if (state.study.product === 'tla') {
      const { group, pois } = payload;
      state.filter.pois[group] = pois;
    } else if (!isEqual(state.filter.pois, payload)) {
      state.filter.pois = payload;
    }
  },

  RESET(state) {
    state.study = {};
    state.totals = {};
    state.dates = [];
    state.features = [];
    state.filter.pois = [];
    state.filter.dates = [];
    state.filter.aggregation = 'Mon_Tue_Wed_Thu';
    state.activePoi = {};
  },

  SET_ACTIVE_POI(state, poi) {
    state.activePoi = poi;
  },

  SET_ACTIVE_ISO(state, iso) {
    state.activeIso = iso;
  },

  SET_ACTIVE_LEVEL(state, level) {
    state.activeLevel = level;
  },

  SET_GROUP_NAME(state, { group, name }) {
    state.comparisonGroups[group].name = name || `Group ${group.toUpperCase()}`;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
