import { parse } from 'json2csv';
import omitBy from 'lodash/omitBy';
import startsWith from 'lodash/startsWith';

export default {
  data() {
    return {
      fab: false
    };
  },

  methods: {
    async exportPng(title) {
      const { imgURI, blob } = await this.$refs.chart.dataURI();
      const filename = `${(this.title || title).replace(/\s/g, '')}.png`;
      if (blob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.href = imgURI;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },

    async exportCsv(title) {
      const filename = `${(this.title || title).replace(/\s/g, '')}.csv`;

      // convert data to csv
      let csvData;
      if (Array.isArray(this.data)) {
        csvData = parse(this.data.map((d) => omitBy(d, (v, k) => startsWith(k.toLowerCase(), 'raw') || startsWith(k.toLowerCase(), 'sample'))));
      } else {
        csvData = parse(Object.entries(this.data).reduce((jsonData, [group, data]) => {
          return jsonData.concat(data.map((d) => {
            d.group = this.comparisonNames[group] || group;
            return d;
          }));
        }, []).map((d) => omitBy(d, (v, k) => startsWith(k.toLowerCase(), 'raw') || startsWith(k.toLowerCase(), 'sample'))));
      }
      const blob = new Blob([csvData], { type: 'text/csv' });

      // download file
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        document.body.append(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }
};
