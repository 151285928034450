var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"study-name"},[(_vm.renaming)?_c('v-text-field',{staticClass:"rename-study-input text-body-1",attrs:{"append-icon":"mdi-check","clearable":"","outlined":"","hide-details":"","dense":""},on:{"click:clear":_vm.cancelRename,"click:append":_vm.rename},model:{value:(_vm.studyName),callback:function ($$v) {_vm.studyName=$$v},expression:"studyName"}}):_c('div',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.study.name))]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.renaming),expression:"!renaming"}],staticClass:"rename-icon",attrs:{"icon":""},on:{"click":_vm.startRenaming}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-spacer'),_c('single-origin-select',{model:{value:(_vm.selectedOrigin),callback:function ($$v) {_vm.selectedOrigin=$$v},expression:"selectedOrigin"}}),_c('study-aggregation-select',{model:{value:(_vm.selectedAggregation),callback:function ($$v) {_vm.selectedAggregation=$$v},expression:"selectedAggregation"}}),_c('study-date-select')],1),_c('comparison-map',{ref:"comparisonMap",attrs:{"loading":_vm.pending},on:{"ready":function($event){_vm.mapReady = true}}}),_c('data-fetcher',{attrs:{"height":"120","report":"getTripsByHour","filter":_vm.summaryFilter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('comparison-summary-chart',{attrs:{"height":120,"data":data},on:{"selection":_vm.setTimesOfDayFilter}})]}}])}),_c('v-row',[_c('v-col',[_c('data-fetcher',{attrs:{"height":"350","report":_vm.inOutReport,"filter":_vm.filter,"limit":5},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-bar-chart',{attrs:{"title":("Top 5 " + _vm.pluralLevel),"height":350,"data":data,"x":_vm.level,"y":"outbound","y-name":"Outbound","y2":"inbound","y2-name":"Inbound","horizontal":"","hide-export":""}})]}}])})],1),_c('v-col',[_c('data-fetcher',{attrs:{"height":"350","report":"getTripPurpose","filter":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-bar-chart',{attrs:{"title":"Trip Purpose","height":350,"data":data,"x":"purpose","y":"outbound","y-name":"Outbound","y2":"inbound","y2-name":"Inbound","hide-export":""}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getTripPurposeByTimeOfDay","filter":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-area-chart',{attrs:{"title":"Trip Purpose By Time of Day","data":data,"x":"hour","x-formatter":_vm.formatHour,"group-by":"purpose","format-legend":""}})]}}])})],1),_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getTripPurposeByDayOfWeek","filter":_vm.filter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('simple-column-chart',{attrs:{"title":"Trip Purpose By Day of Week","data":data,"x":['Mon_Tue_Wed_Thu', 'Fri', 'Sat', 'Sun'],"group-by":"purpose","x-formatter":_vm.formatDayOfWeek,"format-legend":""}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }