<template>
  <v-card flat :tile="isSmall">
    <v-card-title>
      <div class="headline">{{ $tc('createTlaStudy') }}</div>
      <v-spacer />
      <div v-if="isTrial">{{ $tc('trialStudy') }}</div>
      <div v-else>{{ $tc('cost') }}: <span :class="costTextColor">{{ cost }}</span>/{{ creditsAvailable }} {{
      $tc('credits') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form v-model="formValid">
        <v-container class="py-2">
          <v-row>
            <v-col class="py-0">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-container>
                    <v-row>
                      <v-select id="report-organization" v-model="report.organization" label="Organization"
                        prepend-icon="mdi-domain" :items="selectableOrganizations" return-object item-text="name"
                        item-value="id" :readonly="!isAdmin" />
                    </v-row>
                    <v-row>
                      <v-text-field id="report-name" v-model="report.name" label="Report Name"
                        prepend-icon="mdi-text-box-outline" />
                    </v-row>
                    <v-row>
                      <v-text-field id="report-description" v-model="report.description" label="Description"
                        prepend-icon="mdi-text" />
                    </v-row>
                    <v-row>
                      <date-select v-model="report.dates" study-product="tla" :organization="report.organization"
                        can-select-future />
                    </v-row>
                    <v-row>
                      <v-combobox id="report-tags" v-model="report.tags" :items="availableTags" label="Tags"
                        prepend-icon="mdi-tag-outline" multiple chips />
                    </v-row>
                  </v-container>
                </v-window-item>
                <v-window-item :value="2">
                  <v-container>
                    <!-- <v-row class="mb-2">
                      <v-select id="report-groupby" v-model="report.groupBy" label="Group By" prepend-icon="mdi-chart-timeline" :items="groups" hide-details />
                    </v-row> -->
                    <v-row>
                      <poi-select ref="poiSelect" v-model="report.pois" :organization="report.organization"
                        @createPoi="showCreatePoiForm = true" />
                    </v-row>
                    <v-row class="mb-5">
                      <v-slider v-model="report.minBuffer" label="Min Buffer" track-color="dark lighten-5"
                        prepend-icon="mdi-map-marker-circle" min="0" max="100" hide-details>
                        <template v-slot:append>
                          <div class="text-no-wrap">{{ report.minBuffer }} miles</div>
                        </template>
                      </v-slider>
                    </v-row>
                    <v-row class="mb-5">
                      <v-slider v-model="report.maxBuffer" label="Max Buffer" track-color="dark lighten-5"
                        prepend-icon="mdi-map-marker-circle" min="0" max="100" hide-details>
                        <template v-slot:append>
                          <div class="text-no-wrap">{{ report.maxBuffer }} miles</div>
                        </template>
                      </v-slider>
                    </v-row>
                    <v-row>
                      <v-alert width="100%" dense text border="left" :type="bufferAlertType" v-if="bufferText">{{
                      bufferText }}</v-alert>
                    </v-row>
                    <v-row v-if="allowPointTypeSelection">
                      <v-select id="report-point-type" v-model="report.pointTypes" label="Point Types"
                        prepend-icon="mdi-circle-double" :items="pointTypes" multiple hide-details />
                    </v-row>
                    <v-row v-if="allowVisualization">
                      <v-checkbox label="Visualize" v-model="report.visualize" />
                    </v-row>
                  </v-container>
                </v-window-item>
              </v-window>
            </v-col>
            <v-col class="py-0" v-if="!isSmall">
              <study-feature-map width="600px" height="450px" :pois="report.pois" product="tla" key="tla-feature-map" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-0 small-actions" v-if="isSmall">
      <v-btn class="ma-0" color="error" depressed large tile width="50%" @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn class="ma-0" color="primary" depressed large tile width="50%" :loading="pending" @click="checkout"
        :disabled="!canCheckout">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn id="create-poi-cancel" color="error" text large @click="cancel" v-if="step === 1">{{ $tc('cancel') }}
      </v-btn>
      <v-btn id="create-poi-back" color="secondary" text large @click="step--" v-else>{{ $tc('back') }}</v-btn>
      <v-spacer />
      <v-btn id="create-poi-next" color="primary" text large @click="step++" v-if="step === 1">{{ $tc('next') }}</v-btn>
      <v-btn id="report-submit" color="primary" depressed large :loading="pending" @click="checkout"
        :disabled="!canCheckout" v-else>{{ $tc('submit') }}</v-btn>
    </v-card-actions>
    <create-poi :show.sync="showCreatePoiForm" :organization-override="report.organization" @setPois="setReportPois" />
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import alert from '@/mixins/alert';
import { unfurlNestedDates } from '@/lib/utils';
import StudyFeatureMap from '@/components/StudyFeatureMap';
import DateSelect from '@/components/DateSelect';
import PoiSelect from '@/components/PoiSelect';
import CreatePoi from '@/components/CreatePoi';

export default {
  name: 'ShopTla',

  mixins: [alert],

  props: {
    organization: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    StudyFeatureMap,
    DateSelect,
    PoiSelect,
    CreatePoi
  },

  data() {
    return {
      formValid: false,
      pending: false,
      showCreatePoiForm: false,
      step: 1,
      report: {
        organization: {},
        name: '',
        description: '',
        pointTypes: ['EP'],
        groupBy: 'month',
        tags: [],
        dates: [],
        minBuffer: 0,
        maxBuffer: 0,
        pois: [],
        visualize: true
      },
      pointTypes: [{
        text: 'End Point',
        value: 'EP'
      }, {
        text: 'Transit Point',
        value: 'TP'
      }, {
        text: 'Home Point',
        value: 'HP'
      }, {
        text: 'Work Point',
        value: 'WP'
      }, {
        text: 'Home/Work Point',
        value: 'HWP'
      }],
      groups: [{
        text: 'Hourly',
        value: 'hour'
      }, {
        text: 'Daily',
        value: 'day'
      }, {
        text: 'Weekly',
        value: 'week'
      }, {
        text: 'Monthly',
        value: 'month'
      }/* , {
        text: 'Bucketed',
        value: 'bucketed'
      } */]
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    selectableOrganizations() {
      if (this.isAdmin) {
        return this.organizations;
      }
      return this.organizations.filter((org) => get(org, 'products.tla.write', false));
    },

    canCheckout() {
      const { organization, name = '', dates = [], pois, minBuffer, maxBuffer } = this.report;
      return Boolean(this.isAuthenticated && organization && name.length && dates.length && pois.length && (minBuffer > 0 && maxBuffer > 0 ? minBuffer < maxBuffer : true) && (this.canAfford || (this.isTrial && !this.hasOrders)));
    },

    allowPointTypeSelection() {
      const { organization } = this.report;
      return this.isAdmin || get(organization, 'products.tla.additional.allowPointTypeSelection', false);
    },

    allowVisualization() {
      const { organization } = this.report;
      return this.isAdmin || get(organization, 'products.tla.additional.allowVisualization', false);
    },

    availableTags() {
      return !isEmpty(this.report.organization) ? this.tags[this.report.organization.id] : [];
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    cost() {
      const { dates = [], pois = [] } = this.report;
      const numDates = unfurlNestedDates(dates, { interval: 'day' }).length;
      return pois.length * (Math.ceil(numDates / 15) || 1);
    },

    creditsAvailable() {
      let creditsAvailable = 0;
      if (!this.isTrial) {
        const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
        if (orgInfo) {
          creditsAvailable = orgInfo.credits || 0;
        }
      }
      return creditsAvailable;
    },

    isTrial() {
      let isTrial = false;
      const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
      if (orgInfo) {
        isTrial = orgInfo.trial === undefined ? true : orgInfo.trial;
      }
      return isTrial;
    },

    hasOrders() {
      let hasOrders = false;
      const orgId = get(this.report.organization, 'id', '');
      if (!isEmpty(orgId)) {
        hasOrders = !isEmpty(this.orders[orgId]);
      }
      return hasOrders;
    },

    costTextColor() {
      let textColor = 'black';
      if (this.cost === 0) {
        textColor = 'black';
      } else if (this.cost < this.creditsAvailable) {
        textColor = 'success';
      } else if (this.cost === this.creditsAvailable) {
        textColor = 'warning';
      } else if (this.cost > this.creditsAvailable) {
        textColor = 'error';
      }
      return `${textColor}--text`;
    },

    canAfford() {
      return this.cost <= this.creditsAvailable;
    },

    bufferText() {
      const { minBuffer, maxBuffer } = this.report;
      let bufferText;
      if (minBuffer > 0 && maxBuffer === 0) {
        bufferText = `Only including visitors from ${minBuffer}+ miles away`;
      } else if (minBuffer === 0 && maxBuffer > 0) {
        bufferText = `Only including visitors within ${maxBuffer} miles away`;
      } else if (minBuffer > 0 && maxBuffer > 0) {
        if (minBuffer < maxBuffer) {
          bufferText = `Only including visitors from ${minBuffer} - ${maxBuffer} miles away`;
        } else {
          bufferText = 'Minimum Buffer must be smaller than Maximum Buffer';
        }
      }
      return bufferText;
    },

    bufferAlertType() {
      const { minBuffer, maxBuffer } = this.report;
      let bufferAlertType = 'info';

      if ((minBuffer > 0 && maxBuffer > 0) && (minBuffer >= maxBuffer)) {
        bufferAlertType = 'error';
      }

      return bufferAlertType;
    },

    ...mapState(['organizations', 'tags', 'orders']),
    ...mapGetters(['userId', 'isAdmin'])
  },

  watch: {
    organizations: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        if (this.isTrial) {
          this.report.dates = this.report.dates[0] || '';
        } else if (!Array.isArray(this.report.dates) && this.report.dates.length) {
          this.report.dates = [this.report.dates];
        }
      }
    },

    /* eslint-disable-next-line */
    'report.organization': function () {
      this.report.visualize = get(this.report.organization, 'products.tla.additional.allowVisualization', false);
    }
  },

  mounted() {
    this.report.organization = this.organization;
  },

  methods: {
    async checkout() {
      try {
        let { organization: { id: organization }, name, dates, minBuffer, maxBuffer, pois, pointTypes, groupBy, description, tags, visualize } = this.report;

        if (!Array.isArray(dates)) {
          dates = [dates];
        }

        this.pending = true;
        const order = {
          name,
          description,
          tags,
          product: 'tla',
          config: {
            minBuffer,
            maxBuffer,
            pointTypes,
            groupBy,
            pois,
            dates: dates.map(date => date.map(d => formatDate(parseISO(d), 'yyyyMMdd'))),
            visualize
          }
        };
        await this.$services.orders.create({ order, organization });
        this.alertSuccess(`${name} Submitted Successfully`);
        this.cancel();
        let organizations = [];
        if (this.isAdmin) {
          const { data } = await this.$services.organizations.all();
          organizations = data;
        } else {
          const { data } = await this.$services.users.listOrganizations(this.userId);
          organizations = data;
        }
        this.setOrganizations(organizations);
        const { data: orders } = await this.$services.organizations.listOrders(organization);
        this.setOrders({ oid: organization, orders });
        const { data: newTags } = await this.$services.organizations.listTags(organization);
        this.setTags({ oid: organization, tags: newTags });
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.report = {
        organization: this.organization,
        name: '',
        description: '',
        pointTypes: ['EP'],
        groupBy: 'month',
        tags: [],
        dates: [],
        pois: [],
        minBuffer: 0,
        maxBuffer: 0,
        visualize: false
      };
    },

    cancel() {
      this.reset();
      this.$emit('cancel', false);
    },

    async setReportPois(poiIds) {
      await this.$refs.poiSelect.fetchPois();
      this.report.pois = uniq(this.report.pois.concat(poiIds));
    },

    ...mapActions(['setOrders', 'setTags', 'setOrganizations'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
