var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.label))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{ref:"poiMap",staticClass:"label-map"})]),_c('v-col',{attrs:{"cols":"6"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getPoiVisitsSummary","filter":{ label: _vm.label, studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations },"limit":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('top-chart',{attrs:{"height":"350","title":"Top 10 By Visits","data":data,"name":"name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('data-fetcher',{attrs:{"height":"350","report":"getPoiVisitsByMonth","filter":{ label: _vm.label, studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations },"limit":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('origin-trend',{attrs:{"height":"350","title":"Monthly Visits (Top 10)","data":data,"group":"name"}})]}}])})],1)],1),(_vm.hasDemographics)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getPoiEthnicityDemographics","filter":{ label: _vm.label, studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('ethnicity-chart',{attrs:{"title":"Ethnicity","height":"250","data":data}})]}}],null,false,2142667644)})],1),_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getPoiGenderAgeDemographics","filter":{ label: _vm.label, studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('gender-age-chart',{attrs:{"title":"Gender & Age","height":"250","data":data}})]}}],null,false,3688772343)})],1)],1):_vm._e(),(_vm.hasDemographics)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getPoiIncomeDemographics","filter":{ label: _vm.label, studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('income-chart',{attrs:{"title":"Income","data":data}})]}}],null,false,461707074)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }