<template>
  <v-card class="legend pa-0" outlined v-if="Object.keys(colorMap).length">
    <v-card-text>
      <div v-for="(color, threshold, index) in colorMap" :key="index">
        <v-icon class="threshold-dot" small :color="color">mdi-checkbox-blank-circle</v-icon>
        <span class="ml-2">{{ Number(threshold) | mkF }} +</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mk } from '@/lib/utils';

export default {
  props: {
    colorMap: {
      type: Object,
      default: () => {}
    }
  },

  filters: {
    mkF: mk
  }
};
</script>

<style lang="scss">
.legend {
  position: absolute;
  right: 10px;
  bottom: -2px;
  z-index: 1;
  transform: scale(0.85);

  .threshold-dot {
    position: relative;
    top: -2px;
  }
}
</style>
