<template>
  <v-card flat :tile="isSmall">
    <v-card-title>
      <div class="headline">{{ $tc('createNwtmStudy') }}</div>
      <v-spacer />
      <div v-if="isTrial">{{ $tc('trialStudy') }}</div>
      <div v-else>{{ $tc('cost') }}: <span :class="costTextColor">{{ cost }}</span>/{{ creditsAvailable }} {{ $tc('credits') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form v-model="formValid">
        <v-window v-model="step">
          <v-window-item :value="1" eager>
            <v-container class="py-2">
              <v-row>
                <v-col class="py-0">
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <v-container>
                        <v-row>
                          <v-select id="report-organization" v-model="report.organization" label="Organization" prepend-icon="mdi-domain" :items="selectableOrganizations" return-object item-text="name" item-value="id" :readonly="!isAdmin" />
                        </v-row>
                        <v-row>
                          <v-text-field id="report-name" v-model="report.name" label="Report Name" prepend-icon="mdi-text-box-outline" />
                        </v-row>
                        <v-row>
                          <v-text-field id="report-description" v-model="report.description" label="Description" prepend-icon="mdi-text" />
                        </v-row>
                        <v-row>
                          <county-select v-model="report.locations" key="nwtm-county-select" />
                        </v-row>
                        <v-row>
                          <date-select v-model="report.dates" study-product="nwtm" :organization="report.organization" />
                        </v-row>
                        <v-row>
                          <v-combobox id="report-tags" v-model="report.tags" :items="availableTags" label="Tags" prepend-icon="mdi-tag-outline" multiple chips />
                        </v-row>
                        <!-- <v-row>
                          <v-checkbox v-model="report.visualize" label="Include Visualized Report" />
                        </v-row> -->
                      </v-container>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-container>
                        <v-row>
                          <adv-location-select v-model="report.locations" />
                        </v-row>
                        <v-row>
                          <v-select id="report-trip-purpose" v-model="report.tripPurposes" label="Trip Purpose" prepend-icon="mdi-home-city-outline" :items="tripPurposeOptions" multiple>
                            <template v-slot:selection="{ item, index }">
                              <!-- Selection -->
                              <v-chip v-if="index <= 3">
                                <span>{{ item.text }}</span>
                              </v-chip>
                              <span v-if="index === 4" class="grey--text caption">(+{{ report.tripPurposes.length - 4 }} others)</span>
                            </template>
                          </v-select>
                        </v-row>
                        <v-row>
                          <v-select id="report-weekdays" v-model="report.weekDays" label="Weekday Filter" prepend-icon="mdi-calendar" :items="weekDays" multiple>
                            <template v-slot:selection="{ item, index }">
                              <!-- Selection -->
                              <v-chip v-if="index <= 3">
                                <span>{{ item.text }}</span>
                              </v-chip>
                              <span v-if="index === 4" class="grey--text caption">(+{{ report.weekDays.length - 4 }} others)</span>
                            </template>
                          </v-select>
                        </v-row>
                        <v-row>
                          <v-select id="report-times-of-day" v-model="report.timesOfDay" label="Time of Day Filter" prepend-icon="mdi-clock-outline" :items="timesOfDay" multiple>
                            <template v-slot:selection="{ item, index }">
                              <!-- Selection -->
                              <v-chip v-if="index <= 3">
                                <span>{{ item }}</span>
                              </v-chip>
                              <span v-if="index === 4" class="grey--text caption">(+{{ report.timesOfDay.length - 4 }} others)</span>
                            </template>
                          </v-select>
                        </v-row>
                        <v-row>
                          <adv-location-select label="Home Filter Locations" v-model="report.homeFilter" />
                        </v-row>
                        <v-row>
                          <v-select id="report-time-aggregation" v-model="report.timeAggregate" label="Time Aggregation" prepend-icon="mdi-clock-outline" :items="timeAggregates" />
                        </v-row>
                        <v-row>
                          <v-select id="report-other-aggregations" v-model="report.aggregates" label="Other Aggregations" prepend-icon="mdi-sigma" :items="aggregatesOptions" hide-details multiple />
                        </v-row>
                      </v-container>
                    </v-window-item>
                  </v-window>
                </v-col>
                <v-col class="py-0" v-if="!isSmall">
                  <study-feature-map width="600px" height="450px" :study-area="report.locations" product="nwtm" key="nwtm-feature-map" />
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
           <v-window-item :value="2" eager>
            <v-card-text>
              <v-container>
                <v-row no-gutters>
                  <v-col>
                    <adv-location-select v-model="advLocations" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-select v-model="report.tripPurposes" label="Trip Purpose" prepend-icon="mdi-home-city-outline" :items="tripPurposeOptions" multiple>
                      <template v-slot:selection="{ item, index }">
                        <!-- Selection -->
                        <v-chip v-if="index <= 3">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 4" class="grey--text caption">(+{{ report.tripPurposes.length - 4 }} others)</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-select v-model="report.weekDays" label="Weekday Filter" prepend-icon="mdi-calendar" :items="weekDays" multiple>
                      <template v-slot:selection="{ item, index }">
                        <!-- Selection -->
                        <v-chip v-if="index <= 3">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 4" class="grey--text caption">(+{{ report.weekDays.length - 4 }} others)</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-select v-model="report.timesOfDay" label="Time of Day Filter" prepend-icon="mdi-clock-outline" :items="timesOfDay" multiple>
                      <template v-slot:selection="{ item, index }">
                        <!-- Selection -->
                        <v-chip v-if="index <= 3">
                          <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 4" class="grey--text caption">(+{{ report.timesOfDay.length - 4 }} others)</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <adv-location-select label="Home Filter Locations" v-model="report.homeFilter" />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-select v-model="report.timeAggregate" label="Time Aggregation" prepend-icon="mdi-clock-outline" :items="timeAggregates" />
                  </v-col>
                  <v-col>
                    <v-select v-model="report.aggregates" label="Other Aggregations" prepend-icon="mdi-sigma" :items="aggregatesOptions" hide-details multiple />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-window-item>
        </v-window>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions class="pa-0 small-actions" v-if="isSmall">
      <v-btn class="ma-0" color="error" depressed large tile width="50%" @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn class="ma-0" color="primary" depressed large tile width="50%" :loading="pending" @click="checkout" :disabled="!canCheckout">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn id="report-cancel" color="error" text large @click="cancel">{{ $tc('cancel') }}</v-btn>
      <v-spacer />
      <v-btn color="secondary" text @click="step = 2" v-if="allowAdvancedOptions && step === 1">
        <v-icon left>mdi-cog-outline</v-icon>
        <span>{{ $tc('advancedOptions') }}</span>
      </v-btn>
      <v-btn color="secondary" text @click="step = 1" v-if="allowAdvancedOptions && step === 2">
        <v-icon left>mdi-cog-outline</v-icon>
        <span>{{ $tc('basicOptions') }}</span>
      </v-btn>
      <v-spacer />
      <v-btn id="report-submit" color="primary" depressed large :loading="pending" @click="checkout" :disabled="!canCheckout">{{ $tc('submit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import alert from '@/mixins/alert';
import { unfurlNestedDates } from '@/lib/utils';
import StudyFeatureMap from '@/components/StudyFeatureMap';
import DateSelect from '@/components/DateSelect';
import CountySelect from '@/components/CountySelect';
import AdvLocationSelect from '@/components/AdvLocationSelect';

export default {
  name: 'ShopNwtm',

  mixins: [alert],

  props: {
    organization: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    StudyFeatureMap,
    DateSelect,
    CountySelect,
    AdvLocationSelect
  },

  data() {
    return {
      formValid: false,
      datesMenu: false,
      pending: false,
      step: 1,
      report: {
        organization: {},
        name: '',
        description: '',
        tags: [],
        dates: [],
        locations: [],
        homeFilter: [],
        weekDays: ['Mon_Tue_Wed_Thu', 'Fri', 'Sat', 'Sun'],
        tripPurposes: ['HW', 'HO', 'HH', 'OH', 'OO', 'OW', 'WH', 'WO', 'WW'],
        timesOfDay: ['H00:H01', 'H01:H02', 'H02:H03', 'H03:H04', 'H04:H05', 'H05:H06', 'H06:H07', 'H07:H08', 'H08:H09', 'H09:H10', 'H10:H11', 'H11:H12', 'H12:H13', 'H13:H14', 'H14:H15', 'H15:H16', 'H16:H17', 'H17:H18', 'H18:H19', 'H19:H20', 'H20:H21', 'H21:H22', 'H22:H23', 'H23:H24'],
        timeAggregate: 'none',
        aggregates: [],
        visualize: true
      },
      advLocations: [],
      tripPurposeOptions: [{
        text: 'Home -> Work',
        value: 'HW'
      }, {
        text: 'Home -> Other',
        value: 'HO'
      }, {
        text: 'Home -> Home',
        value: 'HH'
      }, {
        text: 'Other -> Home',
        value: 'OH'
      }, {
        text: 'Other -> Other',
        value: 'OO'
      }, {
        text: 'Other -> Work',
        value: 'OW'
      }, {
        text: 'Work -> Home',
        value: 'WH'
      }, {
        text: 'Work -> Other',
        value: 'WO'
      }, {
        text: 'Work -> Work',
        value: 'WW'
      }],
      timeAggregates: [{
        text: 'None',
        value: 'none'
      }, {
        text: 'Hour',
        value: 'hour'
      }, {
        text: 'Day',
        value: 'day'
      }, {
        text: 'Month',
        value: 'month'
      }, {
        text: 'Bucketed',
        value: 'bucketed',
        disabled: true
      }],
      aggregatesOptions: [{
        text: 'Trip Purpose',
        value: 'tripPurposes'
      }, {
        text: 'Home Locations',
        value: 'homeLocations'
      }],
      weekDays: [{
        text: 'Monday - Thursday',
        value: 'Mon_Tue_Wed_Thu'
      }, {
        text: 'Friday',
        value: 'Fri'
      }, {
        text: 'Saturday',
        value: 'Sat'
      }, {
        text: 'Sunday',
        value: 'Sun'
      }],
      timesOfDay: ['H00:H01', 'H01:H02', 'H02:H03', 'H03:H04', 'H04:H05', 'H05:H06', 'H06:H07', 'H07:H08', 'H08:H09', 'H09:H10', 'H10:H11', 'H11:H12', 'H12:H13', 'H13:H14', 'H14:H15', 'H15:H16', 'H16:H17', 'H17:H18', 'H18:H19', 'H19:H20', 'H20:H21', 'H21:H22', 'H22:H23', 'H23:H24']
    };
  },

  computed: {
    isAuthenticated: {
      cache: false,
      get() {
        return this.$auth.isAuthenticated();
      }
    },

    selectableOrganizations() {
      if (this.isAdmin) {
        return this.organizations;
      }
      return this.organizations.filter((org) => get(org, 'products.nwtm.write', false));
    },

    allowAdvancedOptions() {
      const { organization } = this.report;
      return this.isAdmin || get(organization, 'products.nwtm.additional.advancedOptions', false);
    },

    canCheckout() {
      const { organization, name = '', dates = [], locations = [] } = this.report;
      return Boolean(this.isAuthenticated && organization && name.length && dates.length && locations.length && (this.canAfford || (this.isTrial && !this.hasOrders)));
    },

    availableTags() {
      return !isEmpty(this.report.organization) ? this.tags[this.report.organization.id] : [];
    },

    isSmall() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    cost() {
      return (this.report.counties || []).length * (unfurlNestedDates(this.report.dates) || []).length;
    },

    creditsAvailable() {
      let creditsAvailable = 0;
      if (!this.isTrial) {
        const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
        if (orgInfo) {
          creditsAvailable = orgInfo.credits || 0;
        }
      }
      return creditsAvailable;
    },

    isTrial() {
      let isTrial = false;
      const orgInfo = this.organizations.find((o) => o.id === get(this.report.organization, 'id', ''));
      if (orgInfo) {
        isTrial = orgInfo.trial === undefined ? true : orgInfo.trial;
      }
      return isTrial;
    },

    hasOrders() {
      let hasOrders = false;
      const orgId = get(this.report.organization, 'id', '');
      if (!isEmpty(orgId)) {
        hasOrders = !isEmpty(this.orders[orgId]);
      }
      return hasOrders;
    },

    costTextColor() {
      let textColor = 'black';
      if (this.cost === 0) {
        textColor = 'black';
      } else if (this.cost < this.creditsAvailable) {
        textColor = 'success';
      } else if (this.cost === this.creditsAvailable) {
        textColor = 'warning';
      } else if (this.cost > this.creditsAvailable) {
        textColor = 'error';
      }
      return `${textColor}--text`;
    },

    canAfford() {
      return this.cost <= this.creditsAvailable;
    },

    ...mapState(['organizations', 'tags', 'orders']),
    ...mapGetters(['userId', 'isAdmin'])
  },

  watch: {
    isTrial() {
      if (this.isTrial && this.report.dates.length > 1) {
        this.report.dates = this.report.dates.slice(0, 1);
      }
    },

    step() {
      if (this.step === 1 && !isEmpty(this.advLocations)) {
        let counties = new Set();
        this.advLocations.forEach((location) => counties.add(location.slice(0, 5)));
        this.report.locations = [...counties];
      } else if (this.step === 2 && isEmpty(this.advLocations)) {
        this.advLocations = this.report.locations;
      }
    }
  },

  mounted() {
    this.report.organization = this.organization;
  },

  methods: {
    dateHandler() {
      if (this.isTrial) {
        this.report.dates = this.report.dates.slice(-1);
      }
    },

    async checkout() {
      try {
        let { organization: { id: organization }, name, dates, locations, description, tags, timeAggregate, tripPurposes, weekDays, timesOfDay, homeFilter, aggregates, visualize } = this.report;

        if (!Array.isArray(dates)) {
          dates = [dates];
        }

        this.pending = true;
        visualize = this.step === 1;
        const order = {
          name,
          description,
          tags,
          product: 'nwtm',
          config: {
            locations: this.step === 2 ? this.advLocations : locations,
            homeFilter,
            dates: dates.map((date) => date.map((d) => formatDate(parseISO(d), 'yyyyMM'))),
            timeAggregate,
            tripPurposes,
            weekDays,
            timesOfDay,
            aggregates,
            visualize
          }
        };
        await this.$services.orders.create({ order, organization });
        this.alertSuccess(`${name} Submitted Successfully`);
        this.cancel();
        let organizations = [];
        if (this.isAdmin) {
          const { data } = await this.$services.organizations.all();
          organizations = data;
        } else {
          const { data } = await this.$services.users.listOrganizations(this.userId);
          organizations = data;
        }
        this.setOrganizations(organizations);
        const { data: orders } = await this.$services.organizations.listOrders(organization);
        this.setOrders({ oid: organization, orders });
        const { data: newTags } = await this.$services.organizations.listTags(organization);
        this.setTags({ oid: organization, tags: newTags });
      } catch (error) {
        this.alertError(error);
      } finally {
        this.pending = false;
      }
    },

    reset() {
      this.report = {
        organization: this.organization,
        name: '',
        description: '',
        tags: [],
        dates: [],
        locations: [],
        homeFilter: [],
        weekDays: ['Mon_Tue_Wed_Thu', 'Fri', 'Sat', 'Sun'],
        tripPurposes: ['HW', 'HO', 'HH', 'OH', 'OO', 'OW', 'WH', 'WO', 'WW'],
        timesOfDay: ['H00:H01', 'H01:H02', 'H02:H03', 'H03:H04', 'H04:H05', 'H05:H06', 'H06:H07', 'H07:H08', 'H08:H09', 'H09:H10', 'H10:H11', 'H11:H12', 'H12:H13', 'H13:H14', 'H14:H15', 'H15:H16', 'H16:H17', 'H17:H18', 'H18:H19', 'H19:H20', 'H20:H21', 'H21:H22', 'H22:H23', 'H23:H24'],
        timeAggregate: 'none',
        aggregates: [],
        visualize: true
      };
      this.advLocations = [];
    },

    cancel() {
      this.reset();
      this.$emit('cancel');
    },

    ...mapActions(['setOrders', 'setTags', 'setOrganizations'])
  }
};
</script>

<style lang="scss">
.small-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
