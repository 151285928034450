<template>
  <v-container pb-0>
    <v-row class="mb-1">
      <div class="headline pl-1 pr-1">Point Plot</div>
      <v-spacer />
      <div class="title pl-1 pr-1">{{ intervalLabel }}</div>
      <v-spacer />
      <study-point-type-select v-model="pointTypeFilter" />
    </v-row>
    <v-row>
      <div class="loader" :style="{ width, height }" v-if="initializing">
        <v-progress-circular indeterminate color="primary" size="64" />
      </div>
      <div class="map-container" :style="{ width, height }" v-else>
        <v-progress-linear class="map-loading-bar ma-0" indeterminate v-show="pending" />
        <div ref="map" class="map">
        <v-card class="legend pa-0" outlined v-if="Object.keys(colorMap).length">
          <v-card-text>
            <div v-for="(color, pointType, index) in colorMap" :key="index">
              <v-icon class="threshold-dot" small :color="color">mdi-checkbox-blank-circle</v-icon>
              <span class="ml-2">{{ pointType }}</span>
            </div>
          </v-card-text>
        </v-card>
        </div>
      </div>
    </v-row>
    <v-row>
      <v-col class="pa-0" cols="12">
        <apexchart ref="chart" class="summary-chart" type="area" :height="180" :options="options" :series="series"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { range, isEmpty, get, flatten, first } from 'lodash';
import { format as formatDate, parseISO, min as minDate, max as maxDate, getTime } from 'date-fns';
import { feature } from '@turf/turf';
import { ScatterplotLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import Map from '@/lib/map';
import StudyPointTypeSelect from '@/components/StudyPointTypeSelect';
import { unfurlNestedDates } from '@/lib/utils';
import alert from '@/mixins/alert';

let pointLayer;
let selectionTimeout;

export default {
  mixins: [alert],

  props: {
    width: {
      type: [String, Number],
      default: '100%'
    },

    height: {
      type: [String, Number],
      default: '100%'
    }
  },

  components: {
    StudyPointTypeSelect
  },

  data() {
    return {
      data: {},
      trendData: [],
      intervals: [],
      activeIntervals: [0],
      map: null,
      pending: false,
      initializing: true,
      dataState: {},
      pointTypeFilter: 1,
      pointTypes: [{
        text: 'EP',
        value: 1,
        color: '#2F3B44',
        color2: [47, 59, 68]
      }, {
        text: 'TP',
        value: 2,
        color: '#E84855',
        color2: [232, 72, 85]
      }, {
        text: 'HP',
        value: 4,
        color: '#549D43',
        color2: [84, 157, 67]
      }, {
        text: 'WP',
        value: 8,
        color: '#FFF152',
        color2: [255, 241, 82]
      }, {
        text: 'HWP',
        value: 16,
        color: '#226076',
        color2: [34, 96, 118]
      }]
    };
  },

  computed: {
    groupBy() {
      return get(this.study, 'config.groupBy', 'day');
    },

    formatString() {
      let formatString = 'MMMM dd, yyyy';
      if (this.groupBy === 'hour') {
        formatString = 'ha MMMM dd, yyyy';
      } else if (this.groupBy === 'month') {
        formatString = 'MMMM yyyy';
      }
      return formatString;
    },

    intervalLabel() {
      if (!isEmpty(this.intervals) && !isEmpty(this.activeIntervals)) {
        let formatString = 'MMMM dd, yyyy';
        if (this.groupBy === 'hour') {
          formatString = 'ha MMMM dd, yyyy';
        } else if (this.groupBy === 'month') {
          formatString = 'MMMM yyyy';
        }

        if (this.activeIntervals.length > 1) {
          const dates = this.intervals.filter((_, index) => this.activeIntervals.includes(index)).map(parseISO);
          return `${formatDate(minDate(dates), formatString)} - ${formatDate(maxDate(dates), formatString)}`;
        }
        return formatDate(parseISO(this.intervals[first(this.activeIntervals)]), formatString);
      }
      return '';
    },

    startDate() {
      return this.trendData.length ? getTime(minDate(this.trendData.map((d) => parseISO(d.study_date)))) : new Date().getTime();
    },

    endDate() {
      return this.trendData.length ? getTime(maxDate(this.trendData.map((d) => parseISO(d.study_date)))) : new Date().getTime();
    },

    options() {
      return {
        chart: {
          toolbar: {
            show: false,
            autoSelected: 'selection'
          },
          selection: {
            enabled: true,
            xaxis: {
              min: 0,
              max: 0.49
            }
          },
          events: {
            selection: this.handlePan
          }
        },
        tooltip: {
          intersect: true,
          shared: false,
          y: {
            formatter: (v) => v ? v.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 }) : '0'
          },
          x: {
            formatter: this.formatXAxis
          },
          marker: {
            show: false
          }
        },
        stroke: {
          width: 2
        },
        markers: {
          size: 4
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        colors: ['#1675D1'],
        xaxis: {
          type: 'numeric',
          labels: {
            formatter: this.formatXAxis
          },
          tickAmount: 'dataPoints',
          tickPlacement: 'on'
        },
        yaxis: {
          show: false
        }
      };
    },

    series() {
      const series = {
        name: 'count',
        data: []
      };
      if (this.trendData.length) {
        const categories = [...Array(this.trendData.length).keys()];
        this.trendData.forEach((d, index) => {
          const { count } = d;
          series.data.push([categories[index], count]);
        });
      }
      return [series];
    },

    colorMap() {
      return this.pointTypes.filter((pt) => this.study.config.pointTypes.includes(pt.text)).reduce((colorMap, pointType) => {
        colorMap[pointType.text] = pointType.color;
        return colorMap;
      }, {});
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['studyArea'])
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }

    if (this.call) {
      this.call.cancel();
    }
  },

  watch: {
    pointTypeFilter() {
      this.refreshLayer();
    }
  },

  methods: {
    async init() {
      try {
        const { id, config: { dates = [] } } = this.study;
        if (!isEmpty(dates)) {
          if (this.groupBy === 'hour') {
            this.intervals = flatten(unfurlNestedDates(dates, { interval: 'day' }).map((d) => formatDate(d, 'yyyy-MM-dd')).map((d) => {
              const days = [];
              for (let x = 0; x < 23; x++) {
                days.push(`${d}T${x < 10 ? `0${x}` : x}:00:00`);
              }
              return days;
            }));
          } else {
            this.intervals = unfurlNestedDates(dates, { interval: this.groupBy }).map((d) => formatDate(d, 'yyyy-MM-dd\'T\'HH:mm:ss'));
          }
        }

        this.dataState = this.intervals.reduce((state, interval) => {
          state[interval] = { call: null, loaded: false };
          return state;
        }, {});

        const { data } = await this.$services.orders.visualize(id, { params: { report: 'getSummary' } });
        this.trendData = data;
        await this.loadData();
        this.initializing = false;

        await this.$nextTick();
        if (this.$refs.map) {
          this.map = new Map(this.$refs.map, {
            style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
            darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
            eventHandlers: {
              'style.load': async () => {
                this.addStudyAreaLayer();
                this.addPointLayer();
              }
            }
          });
        }
      } catch (error) {
        console.error(error); // eslint-disable-line
        this.alertError(error);
      }
    },

    async loadData() {
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        this.pending = true;
        const { id } = this.$route.params;
        const intervals = this.intervals.filter((_, index) => this.activeIntervals.includes(index));
        const fetchData = async (interval) => {
          let { call, loaded } = this.dataState[interval];
          if (!loaded) {
            if (call) {
              call.cancel();
            }
            call = CancelToken.source();
            const { data } = await this.$services.orders.visualize(id, {
              params: {
                report: 'getPoints',
                interval
              },
              cancelToken: call.token
            });
            this.dataState[interval] = { call, loaded: true };
            this.data[interval] = data;
          }
        };

        // prune inactive interval ata
        this.intervals.filter((_, index) => !this.activeIntervals.includes(index)).forEach((interval) => {
          delete this.data[interval];
          this.dataState[interval] = { call: null, loaded: false };
        });
        // get active interval data
        await Promise.all(intervals.map(fetchData));
        this.refreshLayer();
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      } finally {
        this.pending = false;
      }
    },

    addStudyAreaLayer() {
      if (this.map) {
        try {
          // remove existing shapes from the map
          ['studyareaShape', 'studyareaLine'].forEach((layerId) => {
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
          });
          if (this.map.getSource('STUDYAREA')) {
            this.map.removeSource('STUDYAREA');
          }

          const { geom, ...properties } = this.studyArea;
          const studyAreaFeature = feature(geom, properties);

          this.map.addSource('STUDYAREA', {
            type: 'geojson',
            data: studyAreaFeature
          });

          this.map.addLayer({
            id: 'studyareaShape',
            type: 'fill',
            source: 'STUDYAREA',
            paint: {
              'fill-antialias': true,
              'fill-color': '#00DB4A',
              'fill-opacity': 0.10
            }
          });

          this.map.addLayer({
            id: 'studyareaLine',
            type: 'line',
            source: 'STUDYAREA',
            paint: {
              'line-color': '#00A237',
              'line-width': 2,
              'line-opacity': 0.25
            }
          });

          this.map.centerOn(studyAreaFeature);
        } catch (error) {
          this.alertError(error);
        }
      }
    },

    getData() {
      return flatten(Object.values(this.data)).filter(d => parseInt(d.point_type, 10) & this.pointTypeFilter);
    },

    addPointLayer() {
      if (this.map) {
        pointLayer = new MapboxLayer({
          id: 'POINT_LAYER',
          type: ScatterplotLayer,
          data: this.getData(),
          opacity: 0.5,
          getPosition: (d) => [Number(d.longitude), Number(d.latitude)],
          getRadius: (d) => 10,
          getFillColor: (d) => this.pointTypes.find(pt => pt.value === parseInt(d.point_type, 10)).color2
        });
        this.map.addLayer(pointLayer);
      }
    },

    refreshLayer() {
      if (this.map && pointLayer) {
        this.pending = true;
        pointLayer.setProps({
          data: this.getData()
        });
        this.pending = false;
        if (this.isPlaying) {
          this.play();
        }
      }
    },

    formatXAxis(v) {
      if (!isEmpty(this.intervals)) {
        let formatString = 'MMMM dd, yyyy';
        if (this.groupBy === 'hour') {
          formatString = 'ha MMMM dd, yyyy';
        } else if (this.groupBy === 'month') {
          formatString = 'MMMM yyyy';
        }
        return formatDate(parseISO(this.intervals[v]), formatString);
      }
      return v;
    },

    handlePan(chartContext, { xaxis: { min, max } }) {
      if (selectionTimeout) {
        clearTimeout(selectionTimeout);
      }
      selectionTimeout = setTimeout(() => {
        this.activeIntervals = range(Math.round(min), Math.round(max) + 1);
        this.loadData();
      }, 750);
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}

.map-container {
 position: relative;
}

.map-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-attrib {
  display: none;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-loading-bar {
  z-index: 3;
}

.headline, .title {
  align-self: center;
}

.legend {
  position: absolute;
  right: 10px;
  bottom: -2px;
  z-index: 1;
  transform: scale(0.85);

  .threshold-dot {
    position: relative;
    top: -2px;
  }
}

.summary-chart {
  .apexcharts-inner.apexcharts-graphical {
    transform: translate(18px, 10px);
  }
}
</style>
