<template>
  <v-container pt-0>
    <v-toolbar flat>
      <v-btn text @click="close">
        <v-icon left>mdi-arrow-left</v-icon>
        <span>{{ $tc('back') }}</span>
      </v-btn>
      <v-spacer />
      <v-btn text @click="comparing = true" v-if="!comparing && pois.length > 1">
        <v-icon left>mdi-select-compare</v-icon>
        <span>Compare</span>
      </v-btn>
      <v-btn text @click="comparing = false" v-if="comparing">
        <v-icon left>mdi-information-outline</v-icon>
        <span>POI Summaries</span>
      </v-btn>
      <v-btn text @click="showAddDate = true" v-if="canModify">
        <v-icon left>mdi-calendar-plus</v-icon>
        <span>{{ $tc('addDate') }}</span>
      </v-btn>
      <v-btn text @click="showAddPoi = true" v-if="canModify">
        <v-icon left>mdi-map-marker-plus</v-icon>
        <span>{{ $tc('addPoi') }}</span>
      </v-btn>
      <v-btn text :loading="exportingShapes" @click="exportShapes">
        <v-icon left>mdi-map-marker-down</v-icon>
        <span>{{ $tc('downloadShapes') }}</span>
      </v-btn>
      <v-btn text :loading="exporting" @click="exportOrder">
        <v-icon left>mdi-download</v-icon>
        <span>{{ $tc('download') }}</span>
      </v-btn>
    </v-toolbar>
    <template v-if="comparing">
      <comparison-title />
      <poi-comparison />
    </template>
    <template v-else>
      <study-title />
      <poi-summary :poi="selectedPoi" :key="`summary-${activePoi.id}`" v-if="activePoiSelected" />
    </template>
    <add-date-dialog :show.sync="showAddDate" />
    <add-poi-dialog :show.sync="showAddPoi" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { featureCollection, feature } from '@turf/turf';
import alert from '@/mixins/alert';
import StudyTitle from '@/components/studies/tla/reports/StudyTitle';
import PoiSummary from '@/components/studies/tla/reports/PoiSummary';
import ComparisonTitle from '@/components/studies/tla/reports/ComparisonTitle';
import PoiComparison from '@/components/studies/tla/reports/PoiComparison';
import AddDateDialog from '@/components/studies/tla/AddDateDialog';
import AddPoiDialog from '@/components/studies/tla/AddPoiDialog';

export default {
  mixins: [alert],

  components: {
    AddDateDialog,
    AddPoiDialog,
    StudyTitle,
    PoiSummary,
    ComparisonTitle,
    PoiComparison
  },

  data() {
    return {
      pending: false,
      exporting: false,
      exportingShapes: false,
      comparing: false,
      showAddDate: false,
      showAddPoi: false
    };
  },

  mounted() {
    this.setActiveIso('us');
    this.setActiveLevel('state');
  },

  computed: {
    selectedPoi() {
      return this.activePoi;
    },

    activePoiSelected() {
      return !isEmpty(this.selectedPoi);
    },

    canModify() {
      return this.isAdmin || get(this.parentOrganization, 'products.tla.write', false) && !this.isTrial;
    },

    isTrial() {
      return get(this.parentOrganization, 'trial', false);
    },

    ...mapState('visualize', ['study', 'activePoi']),
    ...mapGetters(['isAdmin']),
    ...mapGetters('visualize', ['pois', 'parentOrganization'])
  },

  methods: {
    close() {
      this.reset();
      this.$router.push({ name: 'main' });
    },

    async exportOrder() {
      try {
        const { id, downloadUrl } = this.study;
        if (downloadUrl) {
          window.location = downloadUrl;
        } else {
          this.exporting = true;
          const { data } = await this.$services.orders.export(id);
          window.location = data;
          this.study.downloadUrl = data;
          this.setOrderProperty({ id, key: 'downloadUrl', value: data });
        }
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.exporting = false;
      }
    },

    async exportShapes() {
      try {
        this.exportingShapes = true;
        const { name } = this.study;
        const filename = `${name.replace(/\W/g, '')}_Shapes.geojson`;
        const features = featureCollection(this.pois.map(({ geom, ...properties }) => feature(geom, properties)));
        const blob = new Blob([JSON.stringify(features)], { type: 'application/json' });

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          document.body.append(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (error) {
        this.alertError(error);
      } finally {
        this.exportingShapes = false;
      }
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['reset', 'setActiveIso', 'setActiveLevel'])
  }
};
</script>
