<template>
   <v-select ref="blockgroupSelect" v-model="proxy" :menu-props="{ maxHeight: 400 }" :items="filteredBlockgroupOptions" :label="label" no-data-text="No Blockgroups Found" item-text="name" item-value="fips" multiple @focus="focusSearch" @blur="clearSearch" :disabled="!tracts.length">
    <template v-slot:prepend-item>
      <!-- Blockgroup Search -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="blockgroupFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 100)" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon dark @click="closeSelect">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, index }">
      <!-- Selection -->
      <v-chip v-if="index <= 3">
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-if="index === 4" class="grey--text caption">(+{{ value.length - 4 }} others)</span>
    </template>
    <template v-slot:item="data">
      <!-- Tract Select All Blockgroups -->
      <template v-if="data.item.on">
        <v-list-item class="tract-item" @click="data.item.on">
          <v-list-item-action>
            <v-checkbox color="primary darken-2" :value="data.item.fips" :value-comparator="allBlockgroupsSelected" :indeterminate="someBlockgroupsSelected(data.item.fips)" @toggle="data.item.on" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">{{ data.item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- Divider -->
      <template v-else-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"/>
      </template>
      <!-- Blockgroup Item -->
      <template v-else>
        <v-list-item-action>
          <v-checkbox color="primary" v-model="data.attrs.inputValue" @toggle="data.on" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{ data.item.tract }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },

    tracts: {
      type: Array,
      default: () => []
    },

    items: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: 'Block Groups'
    }
  },

  data() {
    return {
      blockgroups: [],
      proxy: [],
      blockgroupFilter: ''
    };
  },

  computed: {
    blockgroupOptions() {
      const self = this;
      return this.tracts.reduce((options, tract) => {
        const { county, name, fips } = tract;
        options.push({
          county,
          name,
          fips,
          on(value) {
            self.toggleBlockgroups(fips);
          }
        });
        const stateFips = fips.slice(0, 2);
        const countyFips = fips.slice(2, 5);
        const tractCe = fips.slice(5, 11);
        sortBy(this.items.filter((bg) => bg.stateFips === stateFips && bg.countyFips === countyFips && bg.tractCe === tractCe), ['name']).forEach((bg) => {
          options.push({
            tract: `${name}, ${county}`,
            tractFips: fips.slice(0, 11),
            name: bg.name,
            fips: bg.geoId
          });
        });
        return options;
      }, []);
    },

    filteredBlockgroupOptions() {
      if (this.blockgroupFilter !== null && this.blockgroupFilter.length) {
        return this.blockgroupOptions.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.blockgroupFilter.toLowerCase());
          }
          if (!found && item.stateFips) {
            found = item.state.toLowerCase().includes(this.blockgroupFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.blockgroupOptions;
    }
  },

  watch: {
    proxy() {
      if (this.value !== this.proxy) {
        this.$emit('input', this.proxy);
      }
    },

    value() {
      if (this.value !== this.proxy) {
        this.proxy = this.value;
      }
    }
  },

  methods: {
    allBlockgroupsSelected(fips) {
      const tractBlockgroups = this.blockgroupOptions.filter((o) => o.tractFips === fips).map((o) => o.fips);
      const selectedTractBlockgroups = this.proxy.filter((o) => o.slice(0, 11) === fips);
      return selectedTractBlockgroups.length === tractBlockgroups.length;
    },

    someBlockgroupsSelected(fips) {
      const selectedTractBlockgroups = this.proxy.filter((o) => o.slice(0, 11) === fips);
      return !this.allBlockgroupsSelected(fips) && selectedTractBlockgroups.length > 0;
    },

    async toggleBlockgroups(fips) {
      let selection = this.proxy;
      if (this.allBlockgroupsSelected(fips)) {
        selection = this.proxy.filter((o) => o.slice(0, 11) !== fips);
      } else {
        const tractBlockgroups = this.blockgroupOptions.filter((o) => o.tractFips === fips).map((o) => o.fips);
        selection = uniq(tractBlockgroups.concat(this.proxy));
      }
      this.proxy = selection;
    },

    clearSearch() {
      this.blockgroupFilter = '';
    },

    focusSearch(event, timeout = 250) {
      this.$emit('focus');
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      if (this.$refs.blockgroupSelect) {
        this.$refs.blockgroupSelect.blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list.v-sheet.v-sheet--tile[role="listbox"] {
  padding-top: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}

.tract-item {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 64px;
  background: #FFFFFF;
  z-index: 5;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  border-right: none;
}
</style>
