import upperFirst from 'lodash/upperFirst';
import { tileSets, promoteIdMap } from '@/config';

class LayerManager {
  countryCode;

  map;

  levels;

  constructor(map, { countryCode } = {}) {
    this.map = map;
    this.countryCode = countryCode;
    this.levels = Object.keys(tileSets[this.countryCode]);
  }

  loadTileSets() {
    Object.entries(tileSets[this.countryCode]).forEach((ts) => {
      const [level, url] = ts;
      const key = `${this.countryCode}${upperFirst(level)}`;
      if (this.map && !this.map.getSource(key)) {
        let promoteId = JSON.parse(`{ "${level}": "${promoteIdMap[key]}" }`);
        this.map.addSource(key, {
          type: 'vector',
          tiles: [url],
          promoteId,
          minzoom: 0,
          maxzoom: 11
        });
      }
    });
  }

  removeAllLayers() {
    this.levels.forEach((level) => {
      const key = `${this.countryCode}${upperFirst(level)}`;
      let layerId = `${key}Shapes`;
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
      layerId = `${key}Lines`;
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
    });
  }

  static generateExpression(data, dataKey, tileKey, colorMap, { dataFilter = (d) => d, dataTransform = (d) => d, property = 'extrapolated_devices' } = {}) {
    let expression = [];
    data.filter(dataFilter).map(dataTransform).forEach((row) => {
      const dataNumber = Math.round(parseInt(row[property], 10));
      const thresholds = Object.keys(colorMap);
      const highest = thresholds.pop();
      const d = thresholds.find((k) => dataNumber < k);
      const color = colorMap[d || highest];
      expression.push(row[dataKey], color);
    });
    expression = ['match', ['get', tileKey]].concat(expression);
    expression.push('rgba(0,0,0,0)');
    return expression;
  }
}

export default LayerManager;
