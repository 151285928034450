<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="line" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import chart from '@/mixins/chart';
import { mk, round25 } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    numOfMonths() {
      return this.data.length ? uniq(this.data.map((d) => format(parseISO(d.date), 'MMyyyy'))).length : 0;
    },

    showMonths() {
      return this.numOfMonths > 3;
    },

    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [0, 4]
        },
        grid: {
          show: false
        },
        colors: ['#0DAADD', '#2F3B44'],
        legend: {
          show: false
        },
        xaxis: {
          categories: this.data.length ? uniq(this.data.map((d) => format(parseISO(d.date), this.showMonths ? 'MMM yyyy' : 'MMM dd yyyy'))) : [],
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: [{
          labels: {
            show: false,
            formatter(v) {
              return v ? mk(v) : '';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }, {
          labels: {
            show: false,
            formatter(v) {
              return v ? `${round25(v)} hr` : '';
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }]
      };
    },

    series() {
      if (this.showMonths) {
        const grouped = groupBy(this.data, (d) => format(parseISO(d.date), 'MMM yyyy'));
        return [{
          name: 'Visitor Count',
          type: 'column',
          data: Object.values(grouped).map((data) => sumBy(data, (d) => d.extrapolated_devices))
        }, {
          name: 'Average Duration of Visit',
          type: 'line',
          data: Object.values(grouped).map((data) => data.reduce((a, b) => a + b.visit_duration, 0) / data.length)
        }];
      }
      return [{
        name: 'Visitor Count',
        type: 'column',
        data: this.data.map((d) => d.extrapolated_devices)
      }, {
        name: 'Average Duration of Visit',
        type: 'line',
        data: this.data.map((d) => d.visit_duration)
      }];
    }
  }
};
</script>
