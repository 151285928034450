<template>
   <v-select ref="tractSelect" v-model="proxy" :menu-props="{ maxHeight: 400 }" :items="filteredTractOptions" :label="label" no-data-text="No Tracts Found" item-text="name" item-value="fips" multiple @focus="focusSearch" @blur="clearSearch" :disabled="!counties.length">
    <template v-slot:prepend-item>
      <!-- Tract Search -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="tractFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 100)" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon dark @click="closeSelect">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, index }">
      <!-- Selection -->
      <v-chip v-if="index <= 3">
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-if="index === 4" class="grey--text caption">(+{{ value.length - 4 }} others)</span>
    </template>
    <template v-slot:item="data">
      <!-- County Select All Tracts -->
      <template v-if="data.item.on">
        <v-list-item class="county-item" @click="data.item.on">
          <v-list-item-action>
            <v-checkbox color="primary darken-2" :value="data.item.fips" :value-comparator="allTractsSelected" :indeterminate="someTractsSelected(data.item.fips)" @toggle="data.item.on" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">{{ data.item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- Divider -->
      <template v-else-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"/>
      </template>
      <!-- Tract Item -->
      <template v-else>
        <v-list-item-action>
          <v-checkbox color="primary" v-model="data.attrs.inputValue" @toggle="data.on" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{ data.item.county }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import counties from '@/data/counties';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },

    counties: {
      type: Array,
      default: () => []
    },

    items: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: 'Tracts'
    }
  },

  data() {
    return {
      proxy: [],
      tractFilter: ''
    };
  },

  computed: {
    tractOptions() {
      const self = this;
      const selectedStates = uniq(this.counties.map((c) => c.slice(0, 2)));
      return selectedStates.reduce((options, ss) => {
        const state = counties.find((s) => s.fips === ss);
        if (state) {
          sortBy(state.counties.filter((c) => this.counties.includes(c.fips)), ['name']).forEach((c) => {
            options.push({
              stateAbv: state.abv,
              name: c.name,
              fips: c.fips,
              on(value) {
                self.toggleTracts(c.fips);
              }
            });
            sortBy(this.items.filter((t) => t.stateFips === state.fips && t.countyFips === c.fips.slice(2)), ['name']).forEach((t) => {
              options.push({
                county: `${c.name}, ${state.abv}`,
                countyFips: c.fips,
                name: t.name,
                fips: t.geoId
              });
            });
          });
        }
        return options;
      }, []);
    },

    filteredTractOptions() {
      if (this.tractFilter !== null && this.tractFilter.length) {
        return this.tractOptions.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.tractFilter.toLowerCase());
          }
          if (!found && item.stateFips) {
            found = item.state.toLowerCase().includes(this.tractFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.tractOptions;
    }
  },

  watch: {
    proxy() {
      if (this.value !== this.proxy) {
        this.$emit('input', this.proxy);
      }
    },

    value() {
      if (this.value !== this.proxy) {
        this.proxy = this.value;
      }
    }
  },

  methods: {
    allTractsSelected(fips) {
      const countyTracts = this.tractOptions.filter((o) => o.countyFips === fips).map((o) => o.fips);
      const selectedCountyTracts = this.proxy.filter((o) => o.slice(0, 5) === fips);
      return selectedCountyTracts.length === countyTracts.length;
    },

    someTractsSelected(fips) {
      const selectedCountyTracts = this.proxy.filter((o) => o.slice(0, 5) === fips);
      return !this.allTractsSelected(fips) && selectedCountyTracts.length > 0;
    },

    async toggleTracts(fips) {
      let selection = this.proxy;
      if (this.allTractsSelected(fips)) {
        selection = this.proxy.filter((o) => o.slice(0, 5) !== fips);
      } else {
        const countyTracts = this.tractOptions.filter((o) => o.countyFips === fips).map((o) => o.fips);
        selection = uniq(countyTracts.concat(this.proxy));
      }
      this.proxy = selection;
    },

    clearSearch() {
      this.tractFilter = '';
    },

    focusSearch(event, timeout = 250) {
      this.$emit('focus');
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      if (this.$refs.tractSelect) {
        this.$refs.tractSelect.blur();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list.v-sheet.v-sheet--tile[role="listbox"] {
  padding-top: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}

.county-item {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 64px;
  background: #FFFFFF;
  z-index: 5;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  border-right: none;
}
</style>
