<template>
  <v-card class="mb-5" outlined>
    <v-card-title class="mb-4 study-name">
      <v-text-field class="rename-study-input text-body-1" v-model="studyName" v-if="renaming" append-icon="mdi-check"
        clearable outlined hide-details dense @click:clear="cancelRename" @click:append="rename" />
      <div class="display-1" v-else>{{ study.name }}</div>
      <v-btn class="rename-icon" icon @click="startRenaming" v-show="!renaming">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer />
      <study-home-location-select />
      <study-date-select />
    </v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="3">
          <div class="text-center">
            <v-icon color="primary" x-large>mdi-account-group-outline</v-icon>
          </div>
          <div class="headline text-center">{{ totals.visitor_count | mkF }}</div>
          <div class="body-1 text-center">Estimated Visitors</div>
        </v-col>
        <v-col cols="3">
          <div class="text-center">
            <v-icon color="primary" x-large>mdi-weather-night</v-icon>
          </div>
          <div class="headline text-center">{{ totals.percent_overnight | formatPercentF }}%</div>
          <div class="body-1 text-center">Overnight Stay</div>
        </v-col>
        <v-col cols="3">
          <div class="text-center">
            <v-icon color="primary" x-large>mdi-clock-outline</v-icon>
          </div>
          <div class="headline text-center">{{ totals.length_of_stay | round25F }} days</div>
          <div class="body-1 text-center">Length of Stay</div>
        </v-col>
      </v-row>
      <v-row class="mt-5" v-if="allStudyDates.length > 1">
        <v-col>
          <data-fetcher height="250" report="getStudyAreaVisitsByMonth" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }"
            v-slot="{ data }">
            <trend-sparkline title="Visits By Month" height="250" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { mk, round25, formatPercent } from '@/lib/utils';
import alert from '@/mixins/alert';
import DataFetcher from '@/components/charts/DataFetcher';
import TrendSparkline from '@/components/charts/TrendSparkline';
import StudyHomeLocationSelect from '@/components/StudyHomeLocationSelect';
import StudyDateSelect from '@/components/StudyDateSelect';

export default {
  mixins: [alert],

  components: {
    DataFetcher,
    TrendSparkline,
    StudyHomeLocationSelect,
    StudyDateSelect
  },

  data() {
    return {
      totals: {},
      studyName: '',
      renaming: false
    };
  },

  computed: {
    ...mapState('visualize', ['study', 'dates', 'activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredHomeLocations', 'allStudyDates'])
  },

  watch: {
    filteredDateRange() {
      this.fetchTotals();
    },

    filteredHomeLocations() {
      this.fetchTotals();
    },

    activeIso() {
      this.fetchTotals();
    },

    activeLevel() {
      this.fetchTotals();
    }
  },

  mounted() {
    try {
      this.fetchTotals();
    } catch (error) {
      this.alertError(error, false);
    }
  },

  methods: {
    async fetchTotals() {
      const { id } = this.study;
      const { data } = await this.$services.orders.visualize(id, { params: { report: 'getStudyAreaTotals', studyDates: this.filteredDateRange } });
      this.totals = data;
    },

    startRenaming() {
      this.studyName = this.study.name;
      this.renaming = true;
    },

    async rename() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.studyName });
        this.alertSuccess(`${name} renamed to ${this.studyName}`);
        this.setStudyName(this.studyName);
        this.setOrderProperty({ id, key: 'name', value: this.studyName });
        this.cancelRename();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    cancelRename() {
      this.renaming = false;
      this.studyName = this.study.name;
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['setStudyName'])
  },

  filters: {
    mkF: mk,
    round25F: round25,
    formatPercentF: formatPercent
  }
};
</script>

<style lang="scss">
.rename-icon {
  visibility: hidden;
}
.study-name:hover {
  .rename-icon {
    visibility: visible;
  }
}
.rename-study-input {
  max-width: 33%;
}
</style>
