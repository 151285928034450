import Service from '@/services/service';

export default class UserService extends Service {
  constructor() {
    super('users');
  }

  listOrganizations(uid, options) {
    return this.service.get(`/${uid}/organizations`, options);
  }

  addToOrganization(uid, oid, role = 'member', options) {
    return this.service.post(`/${uid}/organizations/${oid}`, { role }, options);
  }

  removeFromOrganization(uid, oid, options) {
    return this.service.delete(`/${uid}/organizations/${oid}`, options);
  }
}
