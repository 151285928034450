<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import omit from 'lodash/omit';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import mapKeys from 'lodash/mapKeys';
import sum from 'lodash/sum';
import reduce from 'lodash/reduce';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: [Array, Object],
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    aggregations() {
      let aggregations;
      switch (this.activeIso) {
        case 'is':
          aggregations = {
            age_0_14: ['0_5', '5_9', '10_14'],
            age_15_29: ['15_19', '20_24', '25_29'],
            age_30_44: ['30_34', '35_39', '40_44'],
            age_45_59: ['45_49', '50_54', '55_59'],
            age_60_74: ['60_64', '65_69', '70_74'],
            age_75_over: ['75_over']
          };
          break;

        case 'us': default:
          aggregations = {
            age_0_14: ['0_5', '5_9', '10_14'],
            age_15_29: ['15_17', '18_19', '20_20', '21_21', '22_24', '25_29'],
            age_30_44: ['30_34', '35_39', '40_44'],
            age_45_59: ['45_49', '50_54', '55_59'],
            age_60_74: ['60_61', '62_64', '65_66', '67_69', '70_74'],
            age_75_over: ['75_79', '80_84', 'over_85']
          };
      }
      return aggregations;
    },

    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          stacked: true
        },
        colors: ['#247BA0', '#FF1654'],
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter(v) {
            return v ? `${(Math.abs(v) * 100).toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}%` : '0%';
          }
        },
        stroke: {
          width: 1
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter(v) {
              return v ? `${v.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}` : '';
            }
          },
          y: {
            formatter(v) {
              return v ? `${(Math.abs(v) * 100).toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}%` : '0%';
            }
          }
        },
        yaxis: [{
          labels: {
            formatter(v) {
              return v ? `${v.toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}` : '';
            }
          }
        }],
        xaxis: {
          categories: Object.keys(this.aggregations).reverse().map((k) => k.replace('age_', '').replace('_', '-').replace('-over', '+')),
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
    },

    series() {
      const series = [];
      if (!isEmpty(this.data)) {
        let total;
        let male;
        let female;
        if (Array.isArray(this.data)) {
          total = sum(Object.values(omit(first(this.data), ['poi'])).map((v) => parseInt(v, 10)));
          male = mapKeys(pickBy(first(this.data), (v, k) => startsWith(k, 'male_')), (v, k) => {
            return k.replace('male_', '');
          });
          female = mapKeys(pickBy(first(this.data), (v, k) => startsWith(k, 'female_')), (v, k) => {
            return k.replace('female_', '');
          });
        } else {
          total = sum(Object.values(omit(this.data, ['poi'])).map((v) => parseInt(v, 10)));
          male = mapKeys(pickBy(this.data, (v, k) => startsWith(k, 'male_')), (v, k) => {
            return k.replace('male_', '');
          });
          female = mapKeys(pickBy(this.data, (v, k) => startsWith(k, 'female_')), (v, k) => {
            return k.replace('female_', '');
          });
        }
        series.push({
          name: 'Male',
          data: reduce(this.aggregations, (result, value, key) => {
            result.push(sum(Object.values(pickBy(male, (v, k) => value.includes(k))).map((v) => parseInt(v, 10))) / total);
            return result;
          }, []).reverse()
        });
        series.push({
          name: 'Female',
          data: reduce(this.aggregations, (result, value, key) => {
            result.push(sum(Object.values(pickBy(female, (v, k) => value.includes(k))).map((v) => parseInt(v, 10) * -1)) / total);
            return result;
          }, []).reverse()
        });
      }
      return series;
    },

    ...mapState('visualize', ['activeIso'])
  }
};
</script>
