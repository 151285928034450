<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="bar" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import first from 'lodash/first';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import sum from 'lodash/sum';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: [Array, Object],
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 100
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          stacked: true,
          stackType: '100%'
        },
        colors: ['#2691D8', '#2487C9', '#217DBB', '#1F74AD', '#1C6A9E', '#1C6A9E', '#1A6090', '#175781', '#144D73', '#124365', '#0F3A56', '#0D3048', '#0A273A', '#081D2B', '#05131D', '#030A0E', '#000000'],
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: true,
          formatter(value, { seriesIndex, dataPointIndex, w }) {
            return w.config.series[seriesIndex].name.replace('Income ', '');
          }
        },
        stroke: {
          width: 1
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        tooltip: {
          shared: false,
          y: {
            formatter(v) {
              return v ? `${(Math.abs(v) * 100).toLocaleString('en-US', { useGrouping: true, maximumFractionDigits: 0 })}%` : '0%';
            }
          }
        },
        yaxis: [{
          labels: {
            show: false
          }
        }],
        xaxis: {
          categories: ['Income'],
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
    },

    series() {
      const series = [];
      if (!isEmpty(this.data)) {
        if (Array.isArray(this.data)) {
          const total = sum(Object.values(omit(first(this.data), ['poi'])).map((v) => parseInt(v, 10)));
          switch (this.activeIso) {
            case 'is':
              Object.entries(pickBy(first(this.data), (v, k) => startsWith(k, 'socioeconomic_class_'))).forEach(([k, v]) => {
                series.push({
                  name: k.replace('socioeconomic_class_', 'Socioeconomic Class '),
                  data: [parseInt(v, 10) / total]
                });
              });
              break;

            case 'us': default:
              Object.entries(pickBy(first(this.data), (v, k) => startsWith(k, 'income_'))).forEach(([k, v]) => {
                series.push({
                  name: k.replace('income_', 'Income ').replace('over_200', '200+').replace('_', '-'),
                  data: [parseInt(v, 10) / total]
                });
              });
          }
        } else {
          const total = sum(Object.values(omit(this.data, ['poi'])).map((v) => parseInt(v, 10)));
          switch (this.activeIso) {
            case 'is':
              Object.entries(pickBy(this.data, (v, k) => startsWith(k, 'income_'))).forEach(([k, v]) => {
                series.push({
                  name: k.replace('socioeconomic_class_', 'Socioeconomic Class '),
                  data: [parseInt(v, 10) / total]
                });
              });
              break;

            case 'us': default:
              Object.entries(pickBy(this.data, (v, k) => startsWith(k, 'income_'))).forEach(([k, v]) => {
                series.push({
                  name: k.replace('income_', 'Income ').replace('over_200', '200+').replace('_', '-'),
                  data: [parseInt(v, 10) / total]
                });
              });
          }
        }
      }
      return series;
    },

    ...mapState('visualize', ['activeIso'])
  }
};
</script>
