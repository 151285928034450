<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="line" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import chart from '@/mixins/chart';
import { mk, round25 } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [0, 6]
        },
        grid: {
          show: false
        },
        colors: ['#0DAADD', '#2F3B44'],
        legend: {
          show: false
        },
        xaxis: {
          categories: this.data.length ? this.data.map((d) => format(parseISO(d.study_date), 'MMM yyyy')) : [],
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: [{
          labels: {
            show: false,
            formatter(v) {
              return v ? mk(v) : '';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }, {
          labels: {
            show: false,
            formatter(v) {
              return v ? round25(v) : '';
            }
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }]
      };
    },

    series() {
      return [{
        name: 'Visitor Count',
        type: 'column',
        data: this.data.map((d) => d.extrapolated_devices)
      }, {
        name: 'Average Duration of Devices in Days',
        type: 'line',
        data: this.data.map((d) => d.average_days_seen)
      }];
    }
  }
};
</script>
