<template>
  <div ref="map" id="create-study-map" class="map" :style="`width: ${width}; height: ${height}`"></div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { buffer, getCoord, center, difference } from '@turf/turf';
import { Marker, Popup } from 'mapbox-gl';
import Map from '@/lib/map';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    studyArea: {
      type: [String, Array],
      default: ''
    },

    pois: {
      type: Array,
      default: () => []
    },

    minBuffer: {
      type: Number,
      default: 0
    },

    maxBuffer: {
      type: Number,
      default: 0
    },

    width: {
      type: String,
      default: '100%'
    },

    height: {
      type: String,
      default: '100%'
    },

    product: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      map: null,
      loading: false,
      call: null,
      bufferTimeoutHandler: null,
      hoverStateId: null,
      geojson: {
        studyArea: {},
        pois: {},
        buffer: {}
      },
      poiMarkers: []
    };
  },

  watch: {
    studyArea() {
      this.loadStudyArea();
    },

    pois() {
      this.loadPointsOfInterest();
    },

    minBuffer() {
      if (this.bufferTimeoutHandler) {
        clearTimeout(this.bufferTimeoutHandler);
      }
      this.bufferTimeoutHandler = setTimeout(this.loadBuffer, 500);
    },

    maxBuffer() {
      if (this.bufferTimeoutHandler) {
        clearTimeout(this.bufferTimeoutHandler);
      }
      this.bufferTimeoutHandler = setTimeout(this.loadBuffer, 500);
    }
  },

  async mounted() {
    await this.$nextTick();
    this.init();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

  methods: {
    init() {
      this.map = new Map(this.$refs.map, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad'
      });
    },

    async loadStudyArea() {
      if (this.map) {
        const { CancelToken, isCancel } = this.$services.geometry;
        try {
          this.loading = true;
          if (this.call) {
            this.call.cancel();
          }
          this.call = CancelToken.source();
          if (Array.isArray(this.studyArea)) {
            if (this.product === 'nwtm') {
              // NWTM studyarea is counties
              const { data } = await this.$services.geometry.getCollection({
                params: { type: 'county', fips: this.studyArea },
                cancelToken: this.call.token
              });
              if (data.features.length) {
                this.geojson.studyArea = data;
              }
            } else {
              const { data } = await this.$services.geometry.all({
                params: { ids: this.studyArea },
                cancelToken: this.call.token
              });
              if (data.features.length) {
                this.geojson.studyArea = data;
              }
            }
          } else if (this.product === 'nwtm') {
            // NWTM studyarea is counties
            const { data } = await this.$services.geometry.getCollection({
              params: { type: 'county', fips: this.studyArea },
              cancelToken: this.call.token
            });
            if (data) {
              this.geojson.studyArea = data;
            }
          } else {
            const { data } = await this.$services.geometry.read(this.studyArea, {
              cancelToken: this.call.token
            });
            if (data) {
              this.geojson.studyArea = data;
            }
          }

          // remove existing shapes from the map
          ['studyareaShape', 'studyareaLine'].forEach((layerId) => {
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
          });
          if (this.map.getSource('STUDYAREA')) {
            this.map.removeSource('STUDYAREA');
          }

          this.map.addSource('STUDYAREA', {
            type: 'geojson',
            data: this.geojson.studyArea
          });

          this.map.addLayer({
            id: 'studyareaShape',
            type: 'fill',
            source: 'STUDYAREA',
            paint: {
              'fill-antialias': true,
              'fill-color': '#00DB4A',
              'fill-opacity': 0.5
            }
          });

          this.map.addLayer({
            id: 'studyareaLine',
            type: 'line',
            source: 'STUDYAREA',
            paint: {
              'line-color': '#00A237',
              'line-width': 2,
              'line-opacity': 0.75
            }
          });

          if (this.product === 'dla' && (this.minBuffer > 0 || this.maxBuffer > 0)) {
            // For DLA, if a buffer is specified, update and center on it instead
            this.loadBuffer();
          } else if (['dla', 'nwtm', 'ad', 'pp'].includes(this.product)) {
            // For DLA, AD, and NWTM center on the study area
            this.map.centerOn(this.geojson.studyArea);
          }
        } catch (error) {
          if (!isCancel(error)) {
            this.alertError(error);
          }
        } finally {
          this.loading = false;
        }
      }
    },

    loadBuffer() {
      if (this.map && !isEmpty(this.geojson.studyArea)) {
        let includePoints = false;
        if (this.minBuffer > 0 && this.maxBuffer === 0) {
          this.geojson.buffer = buffer(this.geojson.studyArea, this.minBuffer, { units: 'miles' });
          includePoints = false;
        } else if (this.minBuffer === 0 && this.maxBuffer > 0) {
          this.geojson.buffer = buffer(this.geojson.studyArea, this.maxBuffer, { units: 'miles' });
          includePoints = true;
        } else if (this.minBuffer > 0 && this.maxBuffer > 0) {
          const minBuffer = buffer(this.geojson.studyArea, this.minBuffer, { units: 'miles' });
          const maxBuffer = buffer(this.geojson.studyArea, this.maxBuffer, { units: 'miles' });
          this.geojson.buffer = difference(maxBuffer, minBuffer);
          includePoints = true;
        }

        // remove existing shapes from the map
        ['bufferShape', 'bufferLine'].forEach((layerId) => {
          if (this.map.getLayer(layerId)) {
            this.map.removeLayer(layerId);
          }
        });
        if (this.map.getSource('BUFFER')) {
          this.map.removeSource('BUFFER');
        }

        this.map.addSource('BUFFER', {
          type: 'geojson',
          data: this.geojson.buffer
        });

        let fillColor = '#1675D1';
        let fillLineColor = '#10579C';
        if (!includePoints) {
          fillColor = '#FFFFFF';
          fillLineColor = '#FEFEFE';
        }

        this.map.addLayer({
          id: 'bufferShape',
          type: 'fill',
          source: 'BUFFER',
          paint: {
            'fill-antialias': true,
            'fill-color': fillColor,
            'fill-opacity': 0.5
          }
        });

        this.map.addLayer({
          id: 'bufferLine',
          type: 'line',
          source: 'BUFFER',
          paint: {
            'line-color': fillLineColor,
            'line-width': 1,
            'line-opacity': 0.75
          }
        });

        // if (this.product === 'dla') {
        //   // For DLA center on the residency buffer
        //   this.map.centerOn(this.geojson.buffer);
        // }
      }
    },

    async loadPointsOfInterest() {
      if (this.map) {
        try {
          this.loading = true;
          const { CancelToken } = this.$services.geometry;
          if (this.cancel) {
            this.cancel();
          }
          const { data } = await this.$services.geometry.all({ params: { ids: this.pois, summary: false, limit: 0 } });
          this.geojson.pois = data;

          // remove existing shapes from the map
          ['poiShapes', 'poiLines'].forEach((layerId) => {
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
          });
          if (this.map.getSource('POIS')) {
            this.map.removeSource('POIS');
          }
          this.poiMarkers.forEach((m) => m.remove());
          this.poiMarkers = [];

          this.map.addSource('POIS', {
            type: 'geojson',
            data: this.geojson.pois,
            promoteId: 'id'
          });

          this.map.addLayer({
            id: 'poiShapes',
            type: 'fill',
            source: 'POIS',
            paint: {
              'fill-antialias': true,
              'fill-color': '#FF8606',
              'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5]
            }
          });

          this.map.addLayer({
            id: 'poiLines',
            type: 'line',
            source: 'POIS',
            paint: {
              'line-color': '#DE7200',
              'line-width': 1,
              'line-opacity': 0.75
            }
          });

          this.geojson.pois.features.forEach((poi) => {
            const { geometry, properties: { id, name, label } } = poi;
            const centerOfPoi = getCoord(center(geometry));
            const popup = new Popup({
              closeButton: false,
              closeOnClick: true
            }).setHTML(`
              <div class="v-card v-card--flat v-sheet">
                <div class="v-card__text pa-0">
                  <div class="headline">${name}</div>
                  <div class="subheading mb-1">${label}</div>
                </div>
              </div>
            `);
            const marker = new Marker({ color: '#0DAADD' }).setLngLat(centerOfPoi).setPopup(popup).addTo(this.map);
            const markerDiv = marker.getElement();
            markerDiv.addEventListener('mouseenter', () => {
              if (this.hoveredStateId) {
                this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
              }
              this.map.setFeatureState({ source: 'POIS', id }, { hover: true });
              this.hoveredStateId = id;
            });
            markerDiv.addEventListener('mouseleave', () => {
              if (this.hoveredStateId) {
                this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
              }
              this.hoveredStateId = null;
            });
            this.poiMarkers.push(marker);
          });

          if (this.product === 'tla') {
            // For TLA, center on the POIs
            this.map.centerOn(this.geojson.pois);
          }
        } catch (error) {
          if (!this.$services.geometry.isCancel(error)) {
            this.alertError(error);
          }
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
  height: 400px;
}
</style>
