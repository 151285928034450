<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-btn icon @click="exportPng">
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>
    </v-card-title>
    <apexchart ref="chart" type="polarArea" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import chart from '@/mixins/chart';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: [String, Number],
      default: 250
    }
  },

  computed: {
    options() {
      const options = {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        labels: ['15min - 2hr', '2hr - 3hr', '3hr - 4hr', '4hr - 5hr', '5hr+'],
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 0.8
        },
        stroke: {
          width: 1,
          colors: ['#FFFFFF']
        },
        grid: {
          show: false
        },
        legend: {
          show: true
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value}%`
          }
        },
        yaxis: {
          show: false
        }
      };
      return options;
    },

    series() {
      let series = [];
      if (!isEmpty(this.data)) {
        const data = this.data.map(d => {
          d.count = Number(d.count);
          return d;
        }).reduce((data, { count, visit_duration: visitDuration }) => {
          const range = Math.floor(visitDuration);
          if (range < 2) {
            data.one += count;
          } else if (range < 3) {
            data.two += count;
          } else if (range < 4) {
            data.three += count;
          } else if (range < 5) {
            data.four += count;
          } else {
            data.fivePlus += count;
          }
          return data;
        }, { one: 0, two: 0, three: 0, four: 0, fivePlus: 0 });
        let { one, two, three, four, fivePlus } = data;
        const total = sum([one, two, three, four, fivePlus]);
        [one, two, three, four, fivePlus] = [one, two, three, four, fivePlus].map((x) => Math.round(x / total * 100));
        series = [one, two, three, four, fivePlus];
      }
      return series;
    }
  }
};
</script>
