<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" max-height="400"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="mr-3">
        <v-icon left>mdi-circle-double</v-icon>
        <span>{{ selectedPointTypes }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="item in studyPointTypes" :key="item.value" @click="selectPointType(item.value)">
        <v-list-item-action>
          <v-checkbox color="primary" :value="item.value" :value-comparator="pointTypeItemSelected" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      menu: false,
      pointTypes: [{
        text: 'EP',
        value: 1
      }, {
        text: 'TP',
        value: 2
      }, {
        text: 'HP',
        value: 4
      }, {
        text: 'WP',
        value: 8
      }, {
        text: 'HWP',
        value: 16
      }]
    };
  },

  computed: {
    selectedPointTypes() {
      return this.pointTypes.filter(({ text, value }) => this.value & value).map(({ text }) => text).join(', ');
    },

    studyPointTypes() {
      return this.pointTypes.filter(pt => this.study.config.pointTypes.includes(pt.text));
    },

    ...mapState('visualize', ['study'])
  },

  methods: {
    closeSelect() {
      this.menu = false;
    },

    selectPointType(pt) {
      let proxy;
      if (this.value & pt) {
        proxy = this.value ^ pt;
      } else {
        proxy = this.value | pt;
      }
      this.$emit('input', proxy);
      // this.closeSelect();
    },

    pointTypeItemSelected(pt) {
      return !!(this.value & pt);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
