<template>
  <v-card outlined>
    <v-card-title class="headline">POI Summary</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <data-fetcher height="350" report="getPoiVisitsSummaryByLabel" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <top-chart height="350" title="Visits By POI Label" :data="data" name="label" />
          </data-fetcher>
        </v-col>
        <v-col>
          <data-fetcher height="350" report="getPoiVisitsByMonthByLabel" :filter="{ studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
            <origin-trend title="Visit Trend" height="350" group="label" :data="data" />
          </data-fetcher>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>
          <v-chip-group v-model="activeLabelIndex" mandatory active-class="primary--text">
            <v-chip v-for="label in labels" :key="`${label}-chip`" outlined>{{ label }}</v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-window style="width: 100%" v-model="activeLabelIndex">
          <v-window-item v-for="(label, index) in labels" :value="index" :key="`${label}-window`">
            <poi-label-summary :label="label" />
          </v-window-item>
        </v-window>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DataFetcher from '@/components/charts/DataFetcher';
import TopChart from '@/components/charts/TopChart';
import OriginTrend from '@/components/charts/OriginTrend';
import PoiLabelSummary from '@/components/studies/dla/reports/PoiLabelSummary';

export default {
  components: {
    DataFetcher,
    TopChart,
    OriginTrend,
    PoiLabelSummary
  },

  data() {
    return {
      activeLabelIndex: 0
    };
  },

  computed: {
    ...mapState('visualize', ['dates', 'activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['labels', 'filteredDateRange', 'filteredHomeLocations'])
  }
};
</script>
