<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="line" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    numOfMonths() {
      return !isEmpty(this.data) ? uniq(concat(...Object.values(this.data).map((resultSet) => resultSet.map((rs) => format(parseISO(rs.date), 'MMyyyy'))))).length : 0;
    },

    showMonths() {
      return this.numOfMonths > 3;
    },

    options() {
      const dates = uniq(concat(...Object.values(this.data).map((resultSet) => resultSet.map((rs) => format(parseISO(rs.date), this.showMonths ? 'MMM yyyy' : 'MMM dd yyyy')))));
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 4,
          curve: 'smooth'
        },
        grid: {
          show: false
        },
        colors: Object.values(this.comparisonColors),
        legend: {
          show: false
        },
        xaxis: {
          categories: dates,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: true,
            formatter(v) {
              return v ? mk(v) : '';
            }
          },
          min: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        }
      };
    },

    series() {
      const totals = [];
      Object.entries(this.data).sort((a, b) => {
        const keyA = a[0].toLowerCase();
        const keyB = b[0].toLowerCase();
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }).forEach(([dataSet, d]) => {
        if (this.showMonths) {
          const grouped = groupBy(d, (r) => format(parseISO(r.date), 'MMM yyyy'));
          totals.push({
            name: this.comparisonNames[dataSet],
            data: Object.values(grouped).map((data) => sumBy(data, (d1) => d1.extrapolated_devices))
          });
        } else {
          totals.push({
            name: this.comparisonNames[dataSet],
            data: d.map((r) => r.extrapolated_devices)
          });
        }
      });
      return totals;
    },

    ...mapGetters('visualize', ['comparisonColors', 'comparisonNames'])
  }
};
</script>
