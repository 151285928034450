<template>
  <v-container>
    <v-row>
      <div class="headline pl-1 pr-1">{{ title }}</div>
      <v-spacer />
      <v-btn-toggle v-model="level" color="primary" group mandatory>
        <v-btn :value="l" v-for="l in levels" :key="l">{{ l }}</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row>
      <div ref="map" :id="`map-${studyId}`" class="map">
        <v-progress-linear class="map-loader ma-0" indeterminate v-show="loading" />
        <v-card class="infobox" v-show="hoverStateId">
          <v-card-title>{{ activeCounty }}</v-card-title>
          <v-card-subtitle v-if="level === 'blockgroup'">{{ activeBlockgroup }}</v-card-subtitle>
          <v-card-text v-html="tripInfo"></v-card-text>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get, concat, uniq, uniqBy, reduce, isEmpty, zipObject, upperFirst } from 'lodash';
import { scaleQuantile } from 'd3-scale';
import { ArcLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import { featureCollection, feature } from '@turf/turf';
import { tileSets, promoteIdMap } from '@/config';
import Map from '@/lib/map';
import alert from '@/mixins/alert';
import { mk, getBlockGroupNameFromFips, getTractNameFromFips } from '@/lib/utils';

const generateExpression = (data, dataKey, tileKey, { property = 'trips' } = {}) => {
  const colors = ['#F2F6FA', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B', '#04193A'];
  const initialScalescale = scaleQuantile().domain(uniq(data.map((d) => d[property]))).range(colors);
  const colorMap = zipObject([0].concat(initialScalescale.quantiles().map((q) => Math.trunc(q))), colors);
  let expression = [];
  data.forEach((row) => {
    const dataNumber = Math.round(parseInt(row[property], 10));
    const thresholds = Object.keys(colorMap);
    const highest = thresholds.pop();
    const d = thresholds.find((k) => dataNumber < k);
    const color = colorMap[d || highest];
    expression.push(row[dataKey], color);
  });
  expression = ['match', ['get', tileKey]].concat(expression);
  expression.push('rgba(0,0,0,0)');
  return expression;
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
};

const getColor = (colorMap, count, returnRgb = true) => {
  let key;
  Object.keys(colorMap).forEach((k) => {
    if (count >= k) {
      key = k;
    }
  });
  if (returnRgb) {
    return hexToRgb(colorMap[key]);
  }
  return colorMap[key];
};

export default {
  mixins: [alert],

  props: {
    title: {
      type: String,
      default: 'Trip Map'
    }
  },

  data() {
    return {
      map: null,
      ready: false,
      mounted: false,
      initialized: false,
      activeLevel: null,
      loading: false,
      countryCode: 'us',
      level: 'county',
      levels: ['county', 'blockgroup'],
      colors: ['#F2F6FA', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B', '#04193A'],
      call: null,
      hoverStateId: null,
      activeCounty: null,
      activeBlockgroup: null,
      tripInfo: null
    };
  },

  computed: {
    studyId() {
      return get(this.study, 'id', '');
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredPois', 'filteredAggregation', 'pois'])
  },

  watch: {
    study: {
      immediate: true,
      handler() {
        this.ready = !isEmpty(this.study) && !isEmpty(this.pois);
      }
    },

    pois: {
      immediate: true,
      handler() {
        this.ready = !isEmpty(this.study) && !isEmpty(this.pois);
      }
    },

    ready: {
      immediate: true,
      handler() {
        if (this.ready && this.mounted && !this.initialized) {
          this.init();
        }
      }
    },

    mounted: {
      immediate: true,
      handler() {
        if (this.ready && this.mounted && !this.initialized) {
          this.init();
        }
      }
    },

    filteredDateRange() {
      if (this.ready) {
        this.fetchData();
      }
    },

    filteredPois() {
      if (this.ready) {
        this.fetchData();
      }
    },

    filteredAggregation() {
      if (this.ready) {
        this.fetchData();
      }
    },

    level() {
      if (this.ready) {
        this.fetchData();
      }
    }
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }

    if (this.call) {
      this.call.cancel();
    }
  },

  async mounted() {
    this.mounted = true;
  },

  methods: {
    init() {
      this.initialized = true;
      this.map = new Map(this.$refs.map, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        dark: this.dark,
        eventHandlers: {
          'style.load': async () => {
            await this.loadTileSets();
            this.fetchData();
            const counties = featureCollection(this.pois.map(({ geom, ...properties }) => feature(geom, properties)));
            this.map.centerOn(counties, { padding: 10, easing: () => 1 });
          }
        }
      });
    },

    async loadTileSets() {
      try {
        this.levels.forEach((level) => {
          const url = tileSets[this.countryCode][level];
          const key = `${this.countryCode}${upperFirst(level)}`;

          if (this.map && !this.map.getSource(key)) {
            let promoteId = JSON.parse(`{ "${level}": "${promoteIdMap[key]}" }`);
            this.map.addSource(key, {
              type: 'vector',
              url,
              promoteId
            });
          }
        });
      } catch (error) {
        this.alertError(error);
      }
    },

    async fetchData() {
      if (this.map) {
        await this.loadData();
      }
    },

    removeAllOriginLayers() {
      try {
        if (this.map) {
          let layerId;
          this.levels.forEach((level) => {
            const key = `${this.countryCode}${upperFirst(level)}`;
            layerId = `${key}Shapes`;
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
            layerId = `${key}Lines`;
            if (this.map.getLayer(layerId)) {
              this.map.removeLayer(layerId);
            }
          });
          layerId = 'arc-layer';
          if (this.map.getLayer(layerId)) {
            this.map.removeLayer(layerId);
          }
        }
      } catch (error) {
        this.alertError(error);
      }
    },

    async loadData() {
      const { id: studyId } = this.$route.params;
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        this.loading = true;
        if (this.call) {
          this.call.cancel();
        }
        this.call = CancelToken.source();
        let { data } = await this.$services.orders.visualize(studyId, {
          params: {
            report: `get${upperFirst(this.level)}MatrixWithCentroids`,
            studyDates: this.filteredDateRange,
            aggregation: this.filteredAggregation
          },
          cancelToken: this.call.token
        });

        const key = `${this.countryCode}${upperFirst(this.level)}`;
        const dataKey = 'fips';
        const tileKey = promoteIdMap[key];
        const origins = uniqBy(concat(data.map((d) => ({ id: d.origin, name: d.origin_name })), data.map((d) => ({ id: d.destination, name: d.destination_name }))), 'id');
        const tripData = reduce(origins, (tripData, origin) => {
          data.forEach((d) => {
            if (d.origin === origin.id) {
              const row = tripData.find((cd) => cd.fips === origin.id);
              if (row) {
                row.inbound += d.inbound;
                row.outbound += d.outbound;
              }
            }
            if (d.destination === origin.id) {
              const row = tripData.find((cd) => cd.fips === origin.id);
              if (row) {
                row.inbound += d.outbound;
                row.outbound += d.inbound;
              }
            }
          });
          return tripData;
        }, origins.map((c) => ({ fips: c.id, inbound: 0, outbound: 0 })));
        const expression = generateExpression(tripData, dataKey, tileKey, { property: 'inbound' });

        const shapesFill = {
          id: `${key}Shapes`,
          type: 'fill',
          source: key,
          'source-layer': this.level,
          paint: {
            'fill-color': expression,
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.92, 0.67]
          }
        };

        const shapesLine = {
          id: `${key}Lines`,
          type: 'line',
          source: key,
          'source-layer': this.level,
          paint: {
            'line-color': expression,
            'line-width': 1,
            'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.8]
          }
        };

        this.removeAllOriginLayers();
        this.map.addLayer(shapesFill);
        this.map.setFilter(`${key}Shapes`, ['any', ['in', tileKey, ...data.map((d) => d.origin)], ['in', tileKey, ...data.map((d) => d.destination)]]);
        this.map.addLayer(shapesLine);
        this.map.setFilter(`${key}Lines`, ['any', ['in', tileKey, ...data.map((d) => d.origin)], ['in', tileKey, ...data.map((d) => d.destination)]]);

        const initialScalescale = scaleQuantile().domain(data.reduce((counts, d) => {
          const { inbound, outbound } = d;
          counts.push(inbound);
          counts.push(outbound);
          return counts;
        }, [])).range(this.colors);
        const colorMap = zipObject([0].concat(initialScalescale.quantiles().map((q) => Math.trunc(q))), this.colors);

        const renderArcLayer = (data, width = 1) => {
          if (this.map.getLayer('arc-layer')) {
            this.map.removeLayer('arc-layer');
          }

          const arcLayer = new MapboxLayer({
            id: 'arc-layer',
            type: ArcLayer,
            data,
            getWidth: width,
            getSourcePosition: (d) => d.origin_centroid,
            getTargetPosition: (d) => d.destination_centroid,
            getSourceColor: (d) => getColor(colorMap, d.inbound),
            getTargetColor: (d) => getColor(colorMap, d.outbound)
          });
          this.map.addLayer(arcLayer);
        };

        const createInfoBoxHTML = (origin) => {
          this.activeCounty = origins.find((c) => c.id === origin).name;
          if (this.level === 'blockgroup') {
            this.activeBlockgroup = `${getBlockGroupNameFromFips(origin)}, ${getTractNameFromFips(origin)}`;
          }
          const info = tripData.find((cd) => cd.fips === origin);
          if (info) {
            this.tripInfo = `
              <div><strong>Inbound Trips: </strong><span>${mk(info.inbound)}</span></div>
              <div><strong>Outbound Trips: </strong><span>${mk(info.outbound)}</span></div>
            `;
          } else {
            this.tripInfo = null;
          }
        };

        this.map.on('mousemove', `${key}Shapes`, (e) => {
          const [feature] = e.features;
          if (feature) {
            const { id } = feature;
            if (id) {
              if (this.hoverStateId) {
                this.map.setFeatureState({ source: key, sourceLayer: this.level, id: this.hoverStateId }, { hover: false });
              }
              this.hoverStateId = id;
              this.map.setFeatureState({ source: key, sourceLayer: this.level, id: this.hoverStateId }, { hover: true });
              createInfoBoxHTML(id);
              renderArcLayer(data.filter((d) => d.origin === id || d.destination === id), 2);
            }
          }
        });

        this.map.on('mouseleave', `${key}Shapes`, (e) => {
          if (this.hoverStateId) {
            this.map.setFeatureState({ source: key, sourceLayer: this.level, id: this.hoverStateId }, { hover: false });
          }
          this.hoverStateId = null;
          if (this.map.getLayer('arc-layer')) {
            this.map.removeLayer('arc-layer');
          }
        });
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
  height: 400px;
}

.infobox {
  position: absolute;
  z-index: 1;
  top: 1em;
  left: 1em;
}

.map-loader {
  z-index: 1;
}
</style>
