<template>
  <v-card flat>
    <v-card-title>
      <div class="headline" v-if="title">{{ title }}</div>
      <v-spacer />
      <v-speed-dial v-model="fab" direction="bottom" transition="slide-y-transition">
        <template v-slot:activator>
          <v-btn v-model="fab" icon>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <v-btn fab small elevation="0" @click="exportPng">
          <v-icon small>mdi-image</v-icon>
        </v-btn>
        <v-btn fab small elevation="0" @click="exportCsv">
          <v-icon small>mdi-file-document</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-title>
    <apexchart ref="chart" type="heatmap" :height="height" :options="options" :series="series" />
  </v-card>
</template>

<script>
import groupBy from 'lodash/groupBy';
import chart from '@/mixins/chart';
import { mk } from '@/lib/utils';

const sorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};

function sortByDay(a, b) {
  let day1 = a.weekday.toLowerCase();
  let day2 = b.weekday.toLowerCase();
  return sorter[day1] - sorter[day2];
}

export default {
  mixins: [chart],

  props: {
    title: String,

    data: {
      type: Array,
      default: () => []
    },

    height: {
      type: [String, Number],
      default: 500
    }
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(v) {
              return v ? mk(v) : '';
            },
            title: {
              formatter(hour) {
                if (hour) {
                  hour = parseInt(hour, 10);
                  if (hour === 0) {
                    hour = '12a';
                  } else if (hour === 12) {
                    hour = '12p';
                  } else if (hour > 12) {
                    hour = `${hour - 12}p`;
                  } else {
                    hour = `${hour}a`;
                  }
                  return hour;
                }
                return '';
              }
            }
          }
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        colors: ['#0DAADD'],
        legend: {
          show: false
        },
        xaxis: {
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter(hour) {
              if (hour) {
                hour = parseInt(hour, 10);
                if (hour === 0) {
                  hour = '12a';
                } else if (hour === 12) {
                  hour = '12p';
                } else if (hour > 12) {
                  hour = `${hour - 12}p`;
                } else {
                  hour = `${hour}a`;
                }
                return hour;
              }
              return '';
            }
          }
        }
      };
    },

    series() {
      const totals = [];
      if (this.data.length) {
        const data = this.data.reduce((data, { poi, weekday, ...hours }) => {
          Object.entries(hours).forEach(([hour, extrapolated_devices]) => { // eslint-disable-line
            hour = hour.slice(-2);
            data.push({ poi, weekday, hour, extrapolated_devices });
          });
          return data;
        }, []);
        Object.entries(groupBy(data, 'hour')).forEach(([hour, data]) => {
          const counts = data.sort(sortByDay).reduce((counts, d) => {
            counts.push({
              x: d.weekday,
              y: d.extrapolated_devices
            });
            return counts;
          }, []);
          totals.push({
            name: hour,
            data: counts
          });
        });
      }
      return totals.sort((a, b) => {
        return parseInt(b.name, 10) - parseInt(a.name, 10);
      });
    }
  }
};
</script>
