<template>
  <div style="height: 100%">
    <v-card :height="height" v-if="pending" flat>
      <v-container fluid fill-height>
        <v-row justify="center" fill-height>
          <v-progress-circular indeterminate color="primary" size="64" />
        </v-row>
      </v-container>
    </v-card>
    <div v-else-if="hasData">
      <slot v-bind:data="data"/>
    </div>
    <v-container fluid fill-height v-else>
      <v-row justify="center" fill-height>
        <div class="text-h5">No Data Available</div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    report: {
      type: String,
      validator: (value) => value.length > 0
    },

    limit: {
      type: Number,
      default: 0
    },

    filter: {
      type: Object,
      default: () => {}
    },

    mustHavePois: {
      type: Boolean,
      default: false
    },

    height: {
      type: [String, Number],
      default: 'initial'
    },

    defaultData: {
      type: [Array, Object, String],
      default: () => []
    }
  },

  data() {
    return {
      pending: false,
      call: null,
      data: this.defaultData
    };
  },

  computed: {
    hasData() {
      return !this.pending && !isEmpty(this.data);
    }
  },

  mounted() {
    this.fetchData();
  },

  beforeDestroy() {
    if (this.call) {
      this.call.cancel();
    }
  },

  watch: {
    filter: {
      deep: true,
      handler(newValue, oldValue) {
        if (!isEqual(oldValue, newValue)) {
          this.fetchData();
        }
      }
    },

    report(newValue, oldValue) {
      if (!isEqual(oldValue, newValue)) {
        this.fetchData();
      }
    }
  },

  methods: {
    async fetchData() {
      const { id } = this.$route.params;
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        if (!isEmpty(this.report) && (!this.mustHavePois || (this.mustHavePois && !isEmpty(this.filter.poiIds)))) {
          if (this.call) {
            this.call.cancel();
          }
          this.call = CancelToken.source();
          this.pending = true;
          const { data } = await this.$services.orders.visualize(id, {
            params: { report: this.report, limit: this.limit, ...this.filter },
            cancelToken: this.call.token
          });
          this.data = data;
          this.pending = false;
        }
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      }
    }
  }
};
</script>
