<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="400" max-height="400" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" outlined v-bind="attrs" v-on="on" :style="isLast ? 'margin-right: 0;' : 'margin-right: 12px !important;'">
        <v-icon left>{{ icon }}</v-icon>
        <span>{{ selectedPoiText }}</span>
      </v-btn>
      <!-- <help-card :subject="$refs.button" title="POI Select" description="This is a Test" /> -->
    </template>
    <v-list>
      <!-- Rename Group -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field v-model="groupName" prepend-icon="mdi-rename-box" placeholder="Group Name" dark hide-details dense single-line filled rounded clearable @input="emitRename" />
        </v-list-item-content>
      </v-list-item>
      <!-- POI Search -->
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="poiFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 0)" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="toggleFiltered" v-if="filteredPoiOptions.length">
        <v-list-item-action>
          <v-checkbox color="primary darken-2" :value="allFilteredSelected()" :value-comparator="allFilteredSelected" :indeterminate="someFilteredSelected()" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="title font-weight-regular">Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <!-- No POIs Found -->
      <template v-if="filteredPoiOptions.length === 0">
        <v-list-item>
          <v-list-item-content>
            <div class="text-center font-italic font-weight-light">No POIs Found</div>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- POI Item -->
      <template v-else>
        <v-list-item v-for="item in filteredPoiOptions" :key="item.id" @click="selectPoi(item.id)">
          <v-list-item-action>
            <v-checkbox color="primary" :value="item.id" :value-comparator="poiItemSelected"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="caption">{{ item.label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import { mapGetters } from 'vuex';
// import HelpCard from '@/components/HelpCard';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: Array,
      default: () => []
    },

    limit: {
      type: Number,
      default: 0
    },

    icon: {
      type: String,
      default: 'mdi-map-marker-outline'
    },

    color: {
      type: String,
      default: 'primary'
    },

    isLast: {
      type: Boolean,
      default: false
    }
  },

  // components: {
  //   HelpCard
  // },

  data() {
    return {
      menu: false,
      poiFilter: '',
      proxy: [],
      groupName: '',
      renameTimeout: null
    };
  },

  computed: {
    selectedPoiText() {
      let selectedPoiText = 'Start Group';
      if (!isEmpty(this.groupName)) {
        selectedPoiText = this.groupName;
      } else {
        const firstPoiId = first(this.proxy);
        if (firstPoiId) {
          const firstPoiName = this.pois.find((p) => p.id === firstPoiId).name;
          selectedPoiText = `${firstPoiName} ${this.proxy.length > 1 ? ` +${this.proxy.length - 1}` : ''}`;
        }
      }
      return selectedPoiText;
    },

    filteredPoiOptions() {
      if (!isEmpty(this.poiFilter)) {
        return this.pois.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.label.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.tags.map((t) => t.toLowerCase()).includes(this.poiFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.pois;
    },

    ...mapGetters('visualize', ['pois'])
  },

  watch: {
    proxy() {
      if (!isEqual(this.value, this.proxy)) {
        this.$emit('input', this.proxy);
      }
    },

    value: {
      immediate: true,
      handler() {
        if (!isEqual(this.value, this.proxy)) {
          this.proxy = this.value.slice(0);
        }
      }
    }
  },

  methods: {
    selectPoi(id) {
      const index = this.proxy.findIndex((poi) => poi === id);
      if (~index) {
        this.proxy.splice(index, 1);
      } else if (this.limit === 0 || (this.limit > 0 && this.proxy.length < this.limit)) {
        this.proxy.push(id);
      }
    },

    allFilteredSelected() {
      return this.filteredPoiOptions.length > 0 && this.filteredPoiOptions.every((fo) => this.proxy.includes(fo.id));
    },

    someFilteredSelected() {
      return !this.allFilteredSelected() && this.filteredPoiOptions.some((fo) => this.proxy.includes(fo.id));
    },

    async toggleFiltered() {
      let selection = this.proxy;
      if (!this.allFilteredSelected()) {
        selection = selection.concat(this.filteredPoiOptions.map((p) => p.id));
      } else {
        selection = difference(selection, this.filteredPoiOptions.map((p) => p.id));
      }
      selection = uniq(selection);
      this.proxy = selection;
    },

    poiItemSelected(id) {
      return this.proxy.includes(id);
    },

    clearSearch() {
      this.poiFilter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    emitRename(event, timeout = 750) {
      this.renameTimeout = setTimeout(() => {
        this.$emit('rename', this.groupName);
      }, timeout);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
