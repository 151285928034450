<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="300px" eager>
    <template v-slot:activator="{ attrs, on }">
      <v-combobox v-model="selectDatesText" multiple chips small-chips label="Dates" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" @focus="openMenu($event, on)">
        <template v-slot:selection="data">
          <v-chip v-bind="data.attrs" close @click:close="removeDates(data.index)">{{ data.item }}</v-chip>
        </template>
      </v-combobox>
    </template>
    <v-date-picker ref="picker" v-model="newDates" :min="earliestDate" :max="latestDate" :allowed-dates="allowedDates" :events="events" event-color="transparent" no-title :type="pickerType" width="300" range>
      <v-container pt-3>
        <v-row>
          <v-btn color="accent" outlined @click="newDates = []">Clear</v-btn>
          <v-spacer/>
          <v-btn color="primary" depressed @click="addDates(newDates)">Ok</v-btn>
        </v-row>
        <v-divider class="mt-5" v-show="canSelectFuture || existingDates.length" />
        <v-row no-gutter class="mt-2" v-show="canSelectFuture || existingDates.length">
          <div v-show="canSelectFuture">
            <v-icon color="success" small>mdi-checkbox-blank-circle</v-icon>
            <span class="overline"> = available for immediate processing</span>
          </div>
          <div v-show="existingDates.length">
            <v-icon color="secondary" small>mdi-checkbox-blank-circle</v-icon>
            <span class="overline"> = currently included in study</span>
          </div>
        </v-row>
      </v-container>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import flatten from 'lodash/flatten';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import alert from '@/mixins/alert';
import { unfurlNestedDates, isConsecutative } from '@/lib/utils';

const INGESTED_COLOR = '#4CAF50';
const INCLUDED_COLOR = '#1675D1';

export default {
  mixins: [alert],

  props: {
    value: {
      type: [Array, String],
      default: () => []
    },

    limit: {
      type: Number,
      default: 0
    },

    studyProduct: {
      type: String,
      default: 'tla'
    },

    existingDates: {
      type: Array,
      default: () => []
    },

    organization: {
      type: [String, Object],
      default: ''
    },

    canSelectFuture: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      menu: false,
      ingestedMonths: [],
      loading: false,
      newDates: []
    };
  },

  watch: {
    latestDate() {
      let formatString;
      if (['tla', 'ad'].includes(this.product)) {
        formatString = 'yyyy-M';
      } else if (['dla', 'nwtm'].includes(this.product)) {
        formatString = 'yyyy';
      } else {
        formatString = 'yyyy-M';
      }
      if (this.latestDate) {
        this.$refs.picker.tableDate = formatDate(parseISO(this.latestDate), formatString);
      }
    },

    limit() {
      if (this.limit > 0) {
        this.selectedDates = this.getLimitedDates(this.selectedDates);
      }
    }
  },

  computed: {
    isMonthly() {
      return ['dla', 'nwtm'].includes(this.studyProduct);
    },

    dateFormatString() {
      return this.isMonthly ? 'MMM yyyy' : 'MMM dd yyyy';
    },

    pickerType() {
      return this.isMonthly ? 'month' : 'date';
    },

    organizationId() {
      let oid = null;
      if (!isEmpty(this.organization)) {
        oid = this.organization;
        if (isObject(this.organization)) {
          oid = this.organization.id;
        }
      }
      return oid;
    },

    selectedDates: {
      get() {
        return this.value;
      },

      set(value) {
        if (this.limit > 0) {
          value = this.getLimitedDates(value);
        }
        this.$emit('input', value);
      }
    },

    selectDatesText() {
      if (isArray(this.selectedDates)) {
        return this.selectedDates.map(([start, end]) => {
          start = formatDate(parseISO(start), this.dateFormatString);
          if (end) {
            end = formatDate(parseISO(end), this.dateFormatString);
            return `${start} - ${end}`;
          }
          return start;
        });
      }
      return formatDate(parseISO(this.selectedDates), this.dateFormatString);
    },

    earliestDate() {
      // get latest of earliest ingested and allowed dates
      if (!isEmpty(this.ingestedMonths)) {
        const earliestIngested = minDate(this.ingestedMonths.map((d) => parseISO(d)));
        if (!isEmpty(this.allowedMonths)) {
          const earliestAllowed = minDate(this.allowedMonths.map((d) => parseISO(d)));
          return formatDate(maxDate([earliestIngested, earliestAllowed]), 'yyyy-MM-dd');
        }
        return formatDate(earliestIngested, 'yyyy-MM-dd');
      }
      return undefined;
    },

    latestDate() {
      if (this.canSelectFuture) {
        if (!isEmpty(this.allowedMonths)) {
          return formatDate(lastDayOfMonth(maxDate(this.allowedMonths.map((d) => parseISO(d)))), 'yyyy-MM-dd');
        }
        return undefined;
      }
      if (!isEmpty(this.ingestedMonths)) {
        // get earlier of latest ingested and allowed dates
        const latestIngested = maxDate(this.ingestedMonths.map((d) => parseISO(d)));
        if (!isEmpty(this.allowedMonths)) {
          const latestAllowed = maxDate(this.allowedMonths.map((d) => parseISO(d)));
          return formatDate(lastDayOfMonth(minDate([latestIngested, latestAllowed])), 'yyyy-MM-dd');
        }
        return formatDate(lastDayOfMonth(latestIngested), 'yyyy-MM-dd');
      }
      return undefined;
    },

    allowedMonths() {
      let allowedMonths = [];
      const orgInfo = this.organizations.find((o) => o.id === this.organizationId);
      if (orgInfo) {
        const { months = [], negate = false } = get(orgInfo, `products.${this.studyProduct}.allowedMonths`, {});
        allowedMonths = unfurlNestedDates(months).map((m) => formatDate(m, 'yyyy-MM'));

        if (negate) {
          allowedMonths = difference(this.ingestedMonths, allowedMonths);
        }
      }
      return allowedMonths;
    },

    ...mapState(['organizations'])
  },

  async mounted() {
    try {
      const { data } = await this.$services.ingest.all();
      this.ingestedMonths = data.map((d) => formatDate(parseISO(d), 'yyyy-MM'));
    } catch (error) {
      this.alertError(error);
    }
  },

  methods: {
    openMenu(event, on) {
      setTimeout(() => {
        on.click(event);
      }, 0);
    },

    addDates() {
      if (this.newDates.length) {
        const proxy = this.selectedDates.slice(0);
        if (this.newDates.length > 1) {
          let [start, end] = this.newDates.map((d) => parseISO(d));
          if (isBefore(end, start)) {
            const start2 = start;
            start = end;
            end = start2;
            this.newDates = [start, end].map((d) => formatDate(d, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd'));
          }
        }
        proxy.push(this.newDates);
        this.selectedDates = proxy;
      }
      this.menu = false;
      this.newDates = [];
    },

    removeDates(index) {
      this.selectedDates.splice(index, 1);
    },

    getLimitedDates(dates) {
      let unfurledDates = unfurlNestedDates(dates, { interval: this.isMonthly ? 'month' : 'day', returnFormatted: true });
      if (unfurledDates.length > 1 && this.limit > 0) {
        unfurledDates = unfurledDates.slice(0, this.limit);
        const limitedRange = [];
        let start = unfurledDates.shift();
        let prev = start;
        while (!isEmpty(unfurledDates)) {
          let date = unfurledDates.shift();
          if (isConsecutative(prev, date, { monthly: this.isMonthly })) {
            prev = date;
          } else {
            limitedRange.push(uniq([start, prev]));
            start = date;
            prev = start;
          }
        }
        limitedRange.push(uniq([start, prev]));
        return limitedRange;
      }
      return dates;
    },

    isIngested(date) {
      return this.ingestedMonths.includes(formatDate(parseISO(date), 'yyyy-MM'));
    },

    alreadyIncludes(date) {
      if (isEmpty(this.existingDates)) {
        return false;
      }
      const existingDates = unfurlNestedDates(this.existingDates, { interval: this.isMonthly ? 'month' : 'day' }).map((d) => formatDate(d, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd'));
      return existingDates.includes(date);
    },

    currentlyPicking(date) {
      if (isEmpty(this.selectedDates)) {
        return false;
      }

      const selectedDates = flatten(this.selectedDates.map((dr) => {
        let [start, end] = dr.map((d) => parseISO(d));
        date = formatDate(parseISO(date), this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd');
        if (!end) {
          return formatDate(start, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd');
        }
        let pickedDates = this.isMonthly ? eachMonthOfInterval({ start, end }) : eachDayOfInterval({ start, end });
        pickedDates = pickedDates.map((d) => formatDate(d, this.isMonthly ? 'yyyy-MM' : 'yyyy-MM-dd'));
        return pickedDates;
      }));
      return selectedDates.includes(date);
    },

    events(date) {
      const events = [];
      if (this.canSelectFuture) {
        if (this.isIngested(date)) {
          events.push(INGESTED_COLOR);
        }
      }
      if (this.alreadyIncludes(date)) {
        events.push(INCLUDED_COLOR);
      }
      return !isEmpty(events) ? events : false;
    },

    allowedDates(date) {
      return (!isEmpty(this.existingDates) ? !this.alreadyIncludes(date) : true)
          && (!isEmpty(this.selectedDates) ? !this.currentlyPicking(date) : true)
          && (!isEmpty(this.allowedMonths) ? this.allowedMonths.includes(formatDate(parseISO(date), 'yyyy-MM')) : true);
    }
  }
};
</script>
