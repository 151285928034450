import axios from 'axios';
import Service from '@/services/service';

export default class OrganizationService extends Service {
  constructor() {
    super('organizations');
    this.CancelToken = axios.CancelToken;
    this.isCancel = axios.isCancel;
  }

  search(options) {
    return this.service.get('search', options);
  }

  listOrders(oid, options) {
    return this.service.get(`${oid}/orders`, options);
  }

  listTags(oid, options) {
    return this.service.get(`${oid}/tags`, options);
  }

  getCredits(oid, options) {
    return this.service.get(`${oid}/credits`, options);
  }

  listPois(oid, options) {
    return this.service.get(`${oid}/pois`, options);
  }

  listPoiTags(oid, options) {
    return this.service.get(`${oid}/pois/tags`, options);
  }

  listPoiLabels(oid, options) {
    return this.service.get(`${oid}/pois/labels`, options);
  }
}
