<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="400" max-height="400" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined v-bind="attrs" v-on="on" class="mr-3">
        <v-icon left>mdi-map-marker-outline</v-icon>
        <span>{{ selectedPoiName }}</span>
      </v-btn>
      <!-- <help-card :subject="$refs.button" title="POI Select" description="This is a Test" /> -->
    </template>
    <v-list>
      <v-list-item class="search-input pb-0">
        <v-list-item-content>
          <v-text-field ref="searchInput" v-model="poiFilter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 0)" />
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <template v-if="filteredPoiOptions.length === 0">
        <v-list-item>
          <v-list-item-content>
            <div class="text-center font-italic font-weight-light">No POIs Found</div>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-for="item in filteredPoiOptions" :key="item.id" @click="selectPoi(item.id)">
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="caption">{{ item.label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
// import HelpCard from '@/components/HelpCard';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  // components: {
  //   HelpCard
  // },

  data() {
    return {
      menu: false,
      poiFilter: ''
    };
  },

  computed: {
    selectedPoiName() {
      const poi = this.pois.find((p) => p.id === this.value);
      if (poi) {
        return poi.name;
      }
      return '';
    },

    filteredPoiOptions() {
      if (!isEmpty(this.poiFilter)) {
        return this.pois.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.label.toLowerCase().includes(this.poiFilter.toLowerCase())
                 || item.tags.map((t) => t.toLowerCase()).includes(this.poiFilter.toLowerCase());
          }
          return found;
        });
      }
      return this.pois;
    },

    ...mapGetters('visualize', ['pois'])
  },

  methods: {
    clearSearch() {
      this.poiFilter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    closeSelect() {
      this.poiFilter = '';
      this.menu = false;
    },

    selectPoi(poiId) {
      this.$emit('input', poiId);
      this.closeSelect();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
