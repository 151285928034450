<template>
  <v-card flat>
    <v-card-title class="pb-0 pt-2">
      <div class="headline">{{ title }}</div>
      <v-spacer />
      <v-btn-toggle v-model="currentLayer" color="primary" group mandatory>
        <v-btn value="winner">
          <v-icon left>mdi-poll</v-icon>
          <span class="hidden-sm-and-down">Trade Area</span>
        </v-btn>
        <v-btn value="distance">
          <v-icon left>mdi-map-marker-distance</v-icon>
          <span class="hidden-sm-and-down">Distance</span>
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <div ref="map" id="trade-area-map" class="map">
        <v-progress-linear class="map-loader ma-0" indeterminate v-show="loading" />
        <v-speed-dial class="export-button" v-model="fab" direction="bottom" transition="slide-y-transition">
          <template v-slot:activator>
            <v-btn v-model="fab" icon>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-export-variant</v-icon>
            </v-btn>
          </template>
          <v-btn fab small elevation="0" @click="exportPng">
            <v-icon small>mdi-image</v-icon>
          </v-btn>
          <v-btn fab small elevation="0" @click="exportCsv">
            <v-icon small>mdi-file-document</v-icon>
          </v-btn>
        </v-speed-dial>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { parse } from 'json2csv';
import omitBy from 'lodash/omitBy';
import snakeCase from 'lodash/snakeCase';
import mapKeys from 'lodash/mapKeys';
import startsWith from 'lodash/startsWith';
import upperFirst from 'lodash/upperFirst';
import concat from 'lodash/concat';
import Map from '@/lib/map';
import TradeAreaWinnerLayer from '@/lib/layerManagers/tradeAreaWinner';
import TradeAreaDistanceLayer from '@/lib/layerManagers/tradeAreaDistance';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    title: {
      type: String,
      default: 'Trade Area'
    }
  },

  data() {
    return {
      fab: false,
      map: null,
      data: [],
      ready: false,
      loading: false,
      winnerLayer: null,
      distanceLayer: null,
      currentLayer: 'winner',
      cancel: {
        winner: null,
        distance: null
      }
    };
  },

  computed: mapGetters('visualize', ['pois', 'filteredPois', 'filteredDateRange', 'comparisonColors', 'comparisonNames']),

  async mounted() {
    this.init();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }

    Object.values(this.cancel).forEach((call) => {
      if (call) {
        call.cancel();
      }
    });
  },

  watch: {
    filteredPois: {
      deep: true,
      handler() {
        this.renderActiveLayer();
      }
    },

    filteredDateRange: {
      deep: true,
      handler() {
        this.renderActiveLayer();
      }
    },

    currentLayer() {
      this.toggleLayer();
    },

    comparisonNames: {
      deep: true,
      handler() {
        if (this.currentLayer === 'winner') {
          setTimeout(() => {
            this.winnerLayer.removeLayer();
            this.renderActiveLayer();
          }, 500);
        }
      }
    }
  },

  methods: {
    init() {
      this.map = new Map(this.$refs.map, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        dark: this.dark,
        preserveDrawingBuffer: true,
        maxZoom: 12,
        eventHandlers: {
          'style.load': async () => {
            this.ready = true;
            this.winnerLayer = new TradeAreaWinnerLayer(this.map);
            this.distanceLayer = new TradeAreaDistanceLayer(this.map);
            this.renderActiveLayer();
          }
        }
      });
    },

    async addWinnerLayer() {
      const { id: studyId } = this.$route.params;
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        this.loading = true;
        if (this.cancel.winner) {
          this.cancel.winner.cancel();
        }
        this.cancel.winner = CancelToken.source();
        const { data } = await this.$services.orders.visualize(studyId, {
          params: {
            report: 'getSetTradeArea',
            poiIds: this.filteredPois,
            studyDates: this.filteredDateRange
          },
          cancelToken: this.cancel.winner.token
        });
        this.data = data;
        this.winnerLayer.renderLayer(data, this.pois.filter((p) => concat(...Object.values(this.filteredPois)).includes(p.id)), this.comparisonColors, this.comparisonNames);
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    async addDistanceLayer() {
      const { id: studyId } = this.$route.params;
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        this.loading = true;
        if (this.cancel.distance) {
          this.cancel.distance.cancel();
        }
        this.cancel.distance = CancelToken.source();
        const { data } = await this.$services.orders.visualize(studyId, {
          params: {
            report: 'getSetDistances',
            poiIds: this.filteredPois,
            studyDates: this.filteredDateRange
          },
          cancelToken: this.cancel.distance.token
        });
        this.data = data;
        this.distanceLayer.renderLayer(data, this.pois.filter((p) => concat(...Object.values(this.filteredPois)).includes(p.id)), this.comparisonColors);
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    toggleLayer() {
      if (this.map) {
        this.winnerLayer.removeLayer();
        this.distanceLayer.removeLayer();
        this.renderActiveLayer();
      }
    },

    renderActiveLayer() {
      this[`add${upperFirst(this.currentLayer)}Layer`]();
    },

    async exportPng(title) {
      const blob = await new Promise((resolve) => this.map.getCanvas().toBlob(resolve));
      const filename = `${(this.title || title).replace(/\s/g, '')}.png`;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        document.body.append(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },

    async exportCsv(title) {
      const filename = `${(this.title || title).replace(/\s/g, '')}.csv`;

      // convert data to csv
      const csvData = parse(this.data.map((d) => {
        return mapKeys(omitBy(d, (v, k) => startsWith(k.toLowerCase(), 'raw') || startsWith(k.toLowerCase(), 'sample')), (v, k) => {
          if (startsWith(k.toLowerCase(), 'extrapolated_devices_')) {
            const [e, d, g] = k.split('_');
            k = snakeCase(this.comparisonNames[g]);
          }
          return k;
        });
      }));
      const blob = new Blob([csvData], { type: 'text/csv' });

      // download file
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        document.body.append(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
  height: 400px;

  .map-loader {
    z-index: 1;
  }
}
</style>
