<template>
  <v-card class="mb-5" outlined>
    <v-card-title class="study-name">
      <v-text-field class="rename-study-input text-body-1" v-model="studyName" v-if="renaming" append-icon="mdi-check"
        clearable outlined hide-details dense @click:clear="cancelRename" @click:append="rename" />
      <div class="display-1" v-else>{{ study.name }}</div>
      <v-btn class="rename-icon" icon @click="startRenaming" v-show="!renaming">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer />
      <single-origin-select v-model="selectedOrigin" />
      <study-aggregation-select v-model="selectedAggregation" />
      <study-date-select />
    </v-card-title>
    <comparison-map ref="comparisonMap" :loading="pending" @ready="mapReady = true" />
    <data-fetcher height="120" report="getTripsByHour" :filter="summaryFilter" v-slot="{ data }">
      <comparison-summary-chart :height="120" :data="data" @selection="setTimesOfDayFilter" />
    </data-fetcher>
    <v-row>
      <v-col>
        <data-fetcher height="350" :report="inOutReport" :filter="filter" :limit="5" v-slot="{ data }">
          <simple-bar-chart :title="`Top 5 ${pluralLevel}`" :height="350" :data="data" :x="level" y="outbound"
            y-name="Outbound" y2="inbound" y2-name="Inbound" horizontal hide-export />
        </data-fetcher>
      </v-col>
      <v-col>
        <data-fetcher height="350" report="getTripPurpose" :filter="filter" v-slot="{ data }">
          <simple-bar-chart title="Trip Purpose" :height="350" :data="data" x="purpose" y="outbound" y-name="Outbound"
            y2="inbound" y2-name="Inbound" hide-export />
        </data-fetcher>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-fetcher height="250" report="getTripPurposeByTimeOfDay" :filter="filter" v-slot="{ data }">
          <simple-area-chart title="Trip Purpose By Time of Day" :data="data" x="hour" :x-formatter="formatHour"
            group-by="purpose" format-legend />
        </data-fetcher>
      </v-col>
      <v-col>
        <data-fetcher height="250" report="getTripPurposeByDayOfWeek" :filter="filter" v-slot="{ data }">
          <simple-column-chart title="Trip Purpose By Day of Week" :data="data"
            :x="['Mon_Tue_Wed_Thu', 'Fri', 'Sat', 'Sun']" group-by="purpose" :x-formatter="formatDayOfWeek"
            format-legend />
        </data-fetcher>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { first, upperFirst } from 'lodash';
import StudyDateSelect from '@/components/StudyDateSelect';
import SingleOriginSelect from '@/components/SingleOriginSelect';
import StudyAggregationSelect from '@/components/StudyAggregationSelect';
import ComparisonMap from '@/components/studies/nwtm/reports/ComparisonMap';
import ComparisonSummaryChart from '@/components/studies/nwtm/reports/ComparisonSummaryChart';
import DataFetcher from '@/components/charts/DataFetcher';
import SimpleBarChart from '@/components/charts/SimpleBarChart';
import SimpleAreaChart from '@/components/charts/SimpleAreaChart';
import SimpleColumnChart from '@/components/charts/SimpleColumnChart';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  components: {
    SingleOriginSelect,
    StudyDateSelect,
    StudyAggregationSelect,
    ComparisonMap,
    ComparisonSummaryChart,
    DataFetcher,
    SimpleBarChart,
    SimpleAreaChart,
    SimpleColumnChart
  },

  data() {
    return {
      pending: false,
      studyName: '',
      renaming: false,
      data: { inbound: [], outbound: [] },
      mapReady: false,
      call: null,
      formatHour(hour) {
        if (hour) {
          hour = parseInt(hour, 10);
          if (hour === 0) {
            hour = '12a';
          } else if (hour === 12) {
            hour = '12p';
          } else if (hour > 12) {
            hour = `${hour - 12}p`;
          } else {
            hour = `${hour}a`;
          }
          return hour;
        }
        return '';
      },

      formatDayOfWeek(day) {
        if (day) {
          return day.replaceAll('_', ', ');
        }
        return '';
      }
    };
  },

  computed: {
    selectedOrigin: {
      get() {
        return first(this.filteredPois);
      },

      set(value) {
        this.setPoiFilter([value]);
      }
    },

    selectedAggregation: {
      get() {
        return this.filteredAggregation;
      },

      set(value) {
        this.setAggregationFilter(value);
      }
    },

    filter() {
      return {
        studyDates: this.filteredDateRange,
        poiIds: this.selectedOrigin,
        aggregation: this.filteredAggregation,
        timesOfDay: this.filteredTimesOfDay
      };
    },

    summaryFilter() {
      return {
        studyDates: this.filteredDateRange,
        poiIds: this.selectedOrigin,
        aggregation: this.filteredAggregation
      };
    },

    inOutReport() {
      return `get${upperFirst(this.level)}InOut`;
    },

    level() {
      let level;
      switch (this.selectedOrigin.length) {
        case 5:
          level = 'county';
          break;

        case 12:
          level = 'blockgroup';
          break;

        default:
          throw new Error('Could not determine selected origin level');
      }
      return level;
    },

    pluralLevel() {
      if (this.level === 'county') {
        return 'Counties';
      }
      if (this.level === 'blockgroup') {
        return 'Block Groups';
      }
      return '';
    },

    ...mapState('visualize', ['study']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredPois', 'filteredAggregation', 'filteredTimesOfDay', 'pois'])
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        this.loadData();
      }
    },

    mapReady() {
      this.loadData();
    }
  },

  beforeDestroy() {
    if (this.call) {
      this.call.cancel();
    }
  },

  methods: {
    loadData() {
      if (this.mapReady) {
        this.fetchData();
      }
    },

    async fetchData() {
      const { id } = this.$route.params;
      const { CancelToken, isCancel } = this.$services.orders;
      try {
        if (this.call) {
          this.call.cancel();
        }
        this.call = CancelToken.source();
        this.pending = true;
        let level;
        switch (this.selectedOrigin.length) {
          case 5:
            level = 'county';
            break;

          case 12:
            level = 'blockgroup';
            break;

          default:
            throw new Error('Could not determine selected origin level');
        }
        const report = `get${upperFirst(level)}Trips`;
        const { data } = await this.$services.orders.visualize(id, {
          params: { report, limit: this.limit, ...this.filter },
          cancelToken: this.call.token
        });
        this.data = data;
        this.pending = false;
        this.$refs.comparisonMap.renderData(this.data);
      } catch (error) {
        if (!isCancel(error)) {
          this.alertError(error);
        }
      }
    },

    startRenaming() {
      this.studyName = this.study.name;
      this.renaming = true;
    },

    async rename() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.studyName });
        this.alertSuccess(`${name} renamed to ${this.studyName}`);
        this.setStudyName(this.studyName);
        this.setOrderProperty({ id, key: 'name', value: this.studyName });
        this.cancelRename();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    cancelRename() {
      this.renaming = false;
      this.studyName = this.study.name;
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['setPoiFilter', 'setAggregationFilter', 'setDateFilter', 'setTimesOfDayFilter', 'setStudyName'])
  }
};
</script>

<style lang="scss">
.rename-icon {
  visibility: hidden;
}
.study-name:hover {
  .rename-icon {
    visibility: visible;
  }
}
.rename-study-input {
  max-width: 33%;
}
</style>
