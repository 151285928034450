<template>
  <v-row>
    <v-spacer />
    <v-col>
      <div id="chord-chart" :style="`height: ${this.size * 1.5}px; width: ${this.size * 1.5}px`"></div>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import mapValues from 'lodash/mapValues';
import d3 from '@/lib/d3';

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },

    size: {
      type: Number,
      default: 600
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const innerRadius = this.size * 0.5 - 20;
      const outerRadius = innerRadius + 8;
      let matrix = mapValues(groupBy(this.data, 'origin'), (g) => sortBy(g, 'destination'));
      const counties = Object.keys(matrix);
      matrix = Object.values(matrix).map((g) => g.map((t) => t.count));
      const svg = d3.select('#chord-chart').append('svg').attr('viewBox', [(-this.size / 2 - 100), (-this.size / 2 - 100), this.size + 200, this.size + 200]);
      const res = d3.chord().padAngle(12 / innerRadius)(matrix);
      const colors = ['#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948', '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'];
      const textId = 'text-arc';

      // invisible arc for county names to follow
      svg.append('path').attr('id', textId).attr('fill', 'none').attr('d', d3.arc()({ outerRadius, startAngle: 0, endAngle: 2 * Math.PI }));

      // outer arc
      svg.datum(res).append('g').attr('font-size', 12)
        .selectAll('g').data((d) => d.groups).enter().append('g')
        .call((g) => g.append('path')
          .style('fill', (d, i) => colors[i]).style('stroke', (d, i) => colors[i])
          .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(outerRadius)))
        .call((g) => g.append('g')
          .attr('transform', (d) => `rotate(${(d.startAngle * 180 / Math.PI - 90)}) translate(${this.size / 2},0)`)
          .append('text').attr('dy', (d) => d.startAngle > Math.PI ? -2 : 8)
          .attr('startOffset', (d) => d.startAngle * outerRadius)
          .attr('transform', (d) => d.startAngle > Math.PI ? 'rotate(180)' : null)
          .style('text-anchor', (d) => d.startAngle > Math.PI ? 'end' : null)
          .text((d) => counties[d.index]));

      // chords
      svg.datum(res).append('g').attr('fill-opacity', 0.75).selectAll('path').data((d) => d).enter()
        .append('path')
        .attr('d', d3.ribbon().radius(innerRadius))
        .style('fill', ({ source, target }) => {
          if (source.value > target.value) {
            return colors[source.index];
          }
          return colors[target.index];
        }).style('stroke', ({ source, target }) => {
          if (source.value > target.value) {
            return colors[source.index];
          }
          return colors[target.index];
        });
    }
  }
};
</script>
