<template>
  <v-container>
    <v-row>
      <div class="headline pl-1 pr-1">{{ title }}</div>
      <v-spacer />
      <v-btn-toggle v-model="activeCountry" color="primary" group mandatory>
        <v-btn :value="iso" v-for="iso in studyCountries" :key="iso">{{ iso }}</v-btn>
      </v-btn-toggle>
      <v-divider vertical />
      <v-btn-toggle v-model="activeLevel" color="primary" group mandatory>
        <v-btn :value="level" v-for="level in studyGeolevels" :key="level">{{ level }}</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row>
      <div ref="map" :id="`map-${mapId}`" class="map">
        <v-progress-linear class="map-loader ma-0" indeterminate v-show="loading" />
        <v-speed-dial class="export-button" v-model="fab" direction="bottom" transition="slide-y-transition">
          <template v-slot:activator>
            <v-btn v-model="fab" icon>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-export-variant</v-icon>
            </v-btn>
          </template>
          <v-btn fab small elevation="0" @click="exportPng">
            <v-icon small>mdi-image</v-icon>
          </v-btn>
          <v-btn fab small elevation="0" @click="exportCsv">
            <v-icon small>mdi-file-document</v-icon>
          </v-btn>
        </v-speed-dial>
        <map-legend :color-map="colorMap" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { parse } from 'json2csv';
import omitBy from 'lodash/omitBy';
import first from 'lodash/first';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Map from '@/lib/map';
import MapLegend from '@/components/charts/MapLegend';
import StudyAreaLayerManager from '@/lib/layerManagers/studyarea';
import PoiLayerManager from '@/lib/layerManagers/poi';
import ChoroplethLayerManager from '@/lib/layerManagers';
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    title: {
      type: String,
      default: 'Visitor Home Locations'
    }
  },

  components: {
    MapLegend
  },

  data() {
    return {
      fab: false,
      map: null,
      data: [],
      studyCountries: [],
      activeCountry: null,
      activeLevel: null,
      loading: false,
      ready: false,
      mounted: false,
      initialized: false,
      originLayer: null,
      shapeLayer: null,
      poiLayer: null,
      colors: ['#F2F6FA', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B', '#04193A'],
      call: {
        state: null,
        county: null,
        msa: null,
        tract: null
      },
      colorMap: {}
    };
  },

  computed: {
    studyId() {
      return get(this.study, 'id', '');
    },

    studyGeolevels() {
      return this.getIsoLevels(this.activeCountry);
    },

    subjectShape() {
      let subjectShape = {};
      if (!isEmpty(this.study)) {
        const { product } = this.study;
        if (product === 'dla' && !isEmpty(this.studyArea)) {
          subjectShape = this.studyArea;
        } else if (product === 'tla' && !isEmpty(this.activePoi)) {
          subjectShape = this.activePoi;
        }
      }
      return subjectShape;
    },

    mapId() {
      let mapId = {};
      if (!isEmpty(this.study)) {
        const { product } = this.study;
        if (product === 'dla' && !isEmpty(this.studyArea)) {
          mapId = get(this.studyArea, 'id', '');
        } else if (product === 'tla' && !isEmpty(this.activePoi)) {
          mapId = get(this.activePoi, 'id', '');
        }
      }
      return mapId;
    },

    ...mapState('visualize', ['study', 'activePoi']),
    ...mapGetters('visualize', ['filteredDateRange', 'filteredHomeLocations', 'studyArea', 'allowedCountries'])
  },

  watch: {
    study: {
      immediate: true,
      handler() {
        this.ready = !isEmpty(this.study) && !isEmpty(this.subjectShape);
      }
    },

    subjectShape: {
      immediate: true,
      handler() {
        this.ready = !isEmpty(this.study) && !isEmpty(this.subjectShape);
      }
    },

    ready: {
      immediate: true,
      handler() {
        if (this.ready && this.mounted && !this.initialized) {
          this.init();
        }
      }
    },

    mounted: {
      immediate: true,
      handler() {
        if (this.ready && this.mounted && !this.initialized) {
          this.init();
        }
      }
    },

    activeCountry(newIso, oldIso) {
      if (this.ready && this.mounted && this.initialized) {
        if (this.activeLevel) {
          const newActiveLevel = this.getIsoLevels(newIso)[this.getIsoLevels(oldIso).indexOf(this.activeLevel)];
          if (this.activeLevel === newActiveLevel) { // if the old and new levels have the same name force a data fetch
            this.fetchData();
          }
          this.activeLevel = newActiveLevel;
        }
        this.$emit('iso', newIso);
      }
    },

    activeLevel() {
      if (this.ready && this.mounted && this.initialized) {
        this.fetchData();
        this.$emit('level', this.activeLevel);
      }
    },

    filteredDateRange() {
      if (this.ready && this.mounted && this.initialized) {
        this.fetchData();
      }
    },

    filteredHomeLocations() {
      if (this.ready && this.mounted && this.initialized) {
        this.fetchData();
      }
    }
  },

  async mounted() {
    this.mounted = true;
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
      Object.values(this.call).forEach((call) => {
        if (call) {
          call.cancel();
        }
      });
    }
  },

  methods: {
    getIsoLevels(iso) {
      let geolevels = [];
      if (iso === 'us') {
        geolevels = ['county', 'state', 'msa'];
        if (this.study.product === 'tla') {
          geolevels.unshift('tract');
          geolevels.unshift('blockgroup');
        }
      } else if (iso === 'ca') {
        geolevels = ['county', 'province', 'cma'];
        if (this.study.product === 'tla') {
          geolevels.unshift('disseminationarea');
        }
      } else if (iso === 'is') {
        geolevels = ['town', 'subdistrict', 'district'];
        if (this.study.product === 'tla') {
          geolevels.unshift('statisticalarea');
        }
      }
      return geolevels;
    },

    init() {
      this.studyCountries = this.allowedCountries;
      this.activeCountry = first(this.studyCountries);
      this.activeLevel = 'state';
      this.map = new Map(this.$refs.map, {
        style: 'mapbox://styles/jhawlwut/cjlgsmfk101j82rplywpfhvjv',
        darkStyle: 'mapbox://styles/jhawlwut/cjpd3dvez0a1f2tko5vbfxtad',
        dark: this.dark,
        preserveDrawingBuffer: true,
        eventHandlers: {
          'style.load': async () => {
            this.initialized = true;
            if (this.study.product === 'dla') {
              this.shapeLayer = new StudyAreaLayerManager(this.map, this.subjectShape);
            } else if (this.study.product === 'tla') {
              this.shapeLayer = new PoiLayerManager(this.map, this.subjectShape);
            }
            this.originLayer = new ChoroplethLayerManager(this.map);
            this.fetchData();
            this.map.centerOn(this.subjectShape.geom, { padding: 100, easing: () => 1 });
          }
        }
      });
    },

    async fetchData() {
      if (this.map) {
        await this.loadData(this.activeLevel, this.activeCountry);
      }
    },

    async loadData(level, iso) {
      if (level && iso) {
        const { id: studyId } = this.$route.params;
        const { CancelToken, isCancel } = this.$services.orders;
        try {
          this.loading = true;
          if (this.call[level]) {
            this.call[level].cancel();
          }
          this.call[level] = CancelToken.source();
          const subjectLevel = this.study.product === 'dla' ? 'StudyArea' : 'Poi';
          const report = `get${subjectLevel}Visits`;
          const { data } = await this.$services.orders.visualize(studyId, {
            params: {
              report,
              pois: [get(this.activePoi, 'id', '')],
              studyDates: this.filteredDateRange,
              level,
              iso,
              homeLocations: this.filteredHomeLocations
            },
            cancelToken: this.call[level].token
          });
          this.data = data;
          this.colorMap = this.originLayer.renderLayer(iso, level, data, { previousLayer: `${subjectLevel.toLowerCase()}Shape` });
        } catch (error) {
          if (!isCancel(error)) {
            this.alertError(error);
          }
        } finally {
          this.loading = false;
        }
      }
    },

    async exportPng(title) {
      const blob = await new Promise((resolve) => this.map.getCanvas().toBlob(resolve));
      const filename = `${(this.title || title).replace(/\s/g, '')}.png`;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        document.body.append(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },

    async exportCsv(title) {
      const filename = `${(this.title || title).replace(/\s/g, '')}.csv`;

      // convert data to csv
      const csvData = parse(this.data.map((d) => omitBy(d, (v, k) => startsWith(k.toLowerCase(), 'raw') || startsWith(k.toLowerCase(), 'sample'))));
      const blob = new Blob([csvData], { type: 'text/csv' });

      // download file
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
        downloadLink.setAttribute('download', filename);
        downloadLink.setAttribute('target', '_blank');
        document.body.append(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }
};
</script>

<style lang="scss">
.map {
  width: 100%;
  position: relative;
  padding-bottom: 2em;
  height: 400px;

  .export-button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .map-loader {
    z-index: 1;
  }
}
</style>
