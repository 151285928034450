<template>
  <v-list class="select-list" ref="list" v-scroll.self="onScroll">
    <v-list-item class="search-input pb-0">
      <v-list-item-content>
        <v-text-field ref="searchInput" v-model="filter" prepend-icon="mdi-magnify" placeholder="Search" dark hide-details dense single-line filled rounded clearable @click:prepend="focusSearch($event, 0)" />
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <template v-if="filteredItems.length === 0">
      <v-list-item>
        <v-list-item-content>
          <div class="text-center font-italic font-weight-light">No Counties Found</div>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-for="item in virtualizedItems">
      <v-list-item :key="item.fips" @click="selectItem(item.fips)" two-line>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.fips }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="primary" v-if="value.length === 5 && value.includes(item.fips)">mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import alert from '@/mixins/alert';

export default {
  mixins: [alert],

  props: {
    value: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      filter: '',
      lastItem: 20
    };
  },

  computed: {
    filteredItems() {
      if (this.filter !== null && this.filter.length) {
        return this.items.filter((item) => {
          let found = false;
          if (item.name) {
            found = item.name.toLowerCase().includes(this.filter.toLowerCase())
                 || item.fips.toString().includes(this.filter.toLowerCase());
          }
          return found;
        });
      }
      return this.items;
    },

    virtualizedItems() {
      return this.filteredItems.slice(0, this.lastItem);
    }
  },

  methods: {
    clearSearch() {
      this.filter = '';
    },

    focusSearch(event, timeout = 250) {
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, timeout);
    },

    selectItem(fips) {
      this.$emit('input', fips);
    },

    onScroll() {
      if (this.lastItem > this.items.length) return;
      const showMoreItems = (this.$refs.list.$el.scrollHeight - (this.$refs.list.$el.scrollTop + this.$refs.list.$el.clientHeight)) < 200;
      if (showMoreItems) {
        this.lastItem += 20;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0;
}

.search-input {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  background: #2F3B44;
  z-index: 5;
}
</style>
