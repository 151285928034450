import axios from 'axios';
import merge from 'lodash/merge';
import Service from '@/services/service';

export default class GeometryService extends Service {
  constructor() {
    super('geometry');
    this.CancelToken = axios.CancelToken;
    this.isCancel = axios.isCancel;
  }

  processFile(formData, options) {
    options = merge(options, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return this.service.post('process', formData, options);
  }

  search(options) {
    return this.service.get('search', options);
  }

  getCollection(options) {
    return this.service.get('collection', options);
  }

  listOrders(id, options) {
    return this.service.get(`${id}/orders`, options);
  }
}
