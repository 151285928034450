import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [{
  name: 'main',
  path: '/',
  component: () => import(/* webpackChunkName: "shop" */ '@/views/Main'),
  meta: {
    requiresAuth: true
  }
}, {
  path: '/account',
  name: 'account',
  component: () => import(/* webpackChunkName: "account" */ '@/views/Account'),
  meta: {
    requiresAuth: true
  }
}, {
  path: '/pois',
  name: 'pois',
  component: () => import(/* webpackChunkName: "pois" */ '@/views/Pois'),
  meta: {
    requiresAuth: true
  }
}, {
  path: '/pois/:id',
  name: 'poi',
  component: () => import(/* webpackChunkName: "poi" */ '@/views/Poi'),
  meta: {
    requiresAuth: true
  }
}, {
  path: '/study/:id',
  name: 'study',
  component: () => import(/* webpackChunkName: "study" */ '@/views/Study'),
  meta: {
    requiresAuth: true
  }
}, {
  path: '/callback',
  name: 'callback',
  component: () => import(/* webpackChunkName: "callback" */ '@/components/Callback')
}, {
  path: '*',
  redirect: '/'
}];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// check auth
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = Vue.prototype.$auth.isAuthenticated();
  if (requiresAuth && !isAuthenticated) {
    Vue.prototype.$auth.login();
  } else {
    next();
  }
});

export default router;
