<template>
  <v-card :height="height" outlined>
    <v-card-title class="upgrade-title text--disabled" v-if="title">{{ title }}</v-card-title>
    <v-container fluid fill-height>
      <v-spacer/>
      <v-btn color="primary" outlined @click="upgrade">Upgrade to Reveal Insight</v-btn>
      <v-spacer/>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    title: String,
    height: {
      type: Number,
      default: 100,
      validator: value => value > 0
    }
  },

  computed: mapGetters('visualize', ['parentOrganization']),

  methods: {
    upgrade() {
      window.open(`mailto:support@airsage.com?subject=${this.parentOrganization.name} - Upgrade From Freemium&body=Hello,%0d%0aWhen can we discuss upgrading our account?%0d%0a%0d%0a`);
    }
  }
};
</script>

<style lang="scss">
.upgrade-title {
  position: absolute;
}
</style>
