var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline text-center"},[_vm._v("Study Area Visitor Insights")]),_c('v-card-text',[(_vm.isTrial)?_c('trial-upgrade',{attrs:{"title":"Visitor Home Locations","height":"400"}}):_c('choropleth-map',{on:{"level":_vm.setActiveLevel,"iso":_vm.setActiveIso}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('data-fetcher',{attrs:{"height":"350","report":"getStudyAreaVisits","filter":{ studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations },"limit":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('top-chart',{attrs:{"title":"Top 10","height":"350","data":data,"name":_vm.activeLevel}})]}}])})],1),_c('v-col',[_c('data-fetcher',{attrs:{"height":"350","report":"getStudyAreaVisitsByMonth","filter":{ studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations },"limit":10},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('origin-trend',{attrs:{"title":"Top 10 Visit Trend","height":"350","group":_vm.activeLevel,"data":data}})]}}])})],1)],1),_c('v-row',{staticClass:"mt-5"},[(_vm.isTrial)?_c('v-col',[_c('trial-upgrade',{attrs:{"title":"Ethnicity","height":"250"}})],1):(_vm.hasDemographics)?_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getStudyAreaEthnicityDemographics","filter":{ studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('ethnicity-chart',{attrs:{"title":"Ethnicity","height":"250","data":data}})]}}])})],1):_vm._e(),(_vm.isTrial)?_c('v-col',[_c('trial-upgrade',{attrs:{"title":"Gender & Age","height":"250"}})],1):(_vm.hasDemographics)?_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getStudyAreaGenderAgeDemographics","filter":{ studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('gender-age-chart',{attrs:{"title":"Gender & Age","height":"250","data":data}})]}}])})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-5"},[(_vm.isTrial)?_c('v-col',[_c('trial-upgrade',{attrs:{"title":"Income","height":"250"}})],1):(_vm.hasDemographics)?_c('v-col',[_c('data-fetcher',{attrs:{"height":"250","report":"getStudyAreaIncomeDemographics","filter":{ studyDates: _vm.filteredDateRange, level: _vm.activeLevel, iso: _vm.activeIso, homeLocations: _vm.filteredHomeLocations }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('income-chart',{attrs:{"title":"Income","data":data}})]}}])})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }