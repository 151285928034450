import { name, version } from '../../package';

const {
  VUE_APP_AUTH0_CLIENT_ID: clientID,
  VUE_APP_AUTH0_DOMAIN: domain,
  VUE_APP_AUTH0_API_IDENTIFIER: audience,
  VUE_APP_AUTH0_CONNECTION: connection,
  VUE_APP_API_URL: apiURL,
  VUE_APP_SENTRY_DSN: dsn,
  VUE_APP_TILE_SET_US_MSA: usMsa,
  VUE_APP_TILE_SET_US_STATE: usState,
  VUE_APP_TILE_SET_US_COUNTY: usCounty,
  VUE_APP_TILE_SET_US_TRACT: usTract,
  VUE_APP_TILE_SET_US_BLOCKGROUP: usBlockgroup,
  VUE_APP_TILE_SET_CA_CMA: caCma,
  VUE_APP_TILE_SET_CA_PROVINCE: caProvince,
  VUE_APP_TILE_SET_CA_COUNTY: caCounty,
  VUE_APP_TILE_SET_CA_DISSEMINATION_AREA: caDa,
  VUE_APP_TILE_SET_IS_STATISTICAL_AREA: isSa,
  VUE_APP_TILE_SET_IS_TOWN: isT,
  VUE_APP_TILE_SET_IS_SUBDISTRICT: isSd,
  VUE_APP_TILE_SET_IS_DISTRICT: isD
} = process.env;

export const app = {
  name,
  version,
  environment: apiURL.slice(-3) === 'com' ? 'production' : 'development'
};
export const sentry = {
  dsn
};
export const auth0Config = {
  domain,
  clientID,
  audience,
  connection
};
export const apiUrl = apiURL;
export const tileSets = {
  us: {
    msa: usMsa,
    state: usState,
    county: usCounty,
    tract: usTract,
    blockgroup: usBlockgroup
  },
  ca: {
    cma: caCma,
    province: caProvince,
    county: caCounty,
    disseminationarea: caDa
  },
  is: {
    statisticalarea: isSa,
    town: isT,
    subdistrict: isSd,
    district: isD
  }
};
export const promoteIdMap = {
  usState: 'fips',
  usCounty: 'fips',
  usTract: 'fips',
  usBlockgroup: 'fips',
  usMsa: 'geoid',
  caProvince: 'PRUID',
  caCounty: 'CDUID',
  caDisseminationarea: 'DAUID',
  caCma: 'CMAPUID',
  isStatisitcalarea: 'id',
  isTown: 'id',
  isSubdistrict: 'id',
  isDistrict: 'id'
};
