<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-row>
          <div class="headline">{{ label }}</div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div ref="poiMap" class="label-map"></div>
          </v-col>
          <v-col cols="6">
            <data-fetcher height="350" report="getPoiVisitsSummary" :filter="{ label, studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" :limit="10" v-slot="{ data }">
              <top-chart height="350" title="Top 10 By Visits" :data="data" name="name" />
            </data-fetcher>
          </v-col>
          <v-col cols="6">
            <data-fetcher height="350" report="getPoiVisitsByMonth" :filter="{ label, studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" :limit="10" v-slot="{ data }">
              <origin-trend height="350" title="Monthly Visits (Top 10)" :data="data" group="name" />
            </data-fetcher>
          </v-col>
        </v-row>
        <v-row class="mt-5" v-if="hasDemographics">
          <v-col>
            <data-fetcher height="250" report="getPoiEthnicityDemographics" :filter="{ label, studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
              <ethnicity-chart title="Ethnicity" height="250" :data="data" />
            </data-fetcher>
          </v-col>
          <v-col>
            <data-fetcher height="250" report="getPoiGenderAgeDemographics" :filter="{ label, studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
              <gender-age-chart title="Gender & Age" height="250" :data="data" />
            </data-fetcher>
          </v-col>
        </v-row>
        <v-row class="mt-5" v-if="hasDemographics">
          <v-col>
            <data-fetcher height="250" report="getPoiIncomeDemographics" :filter="{ label, studyDates: filteredDateRange, level: activeLevel, iso: activeIso, homeLocations: filteredHomeLocations }" v-slot="{ data }">
              <income-chart title="Income" :data="data" />
            </data-fetcher>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Marker, Popup } from 'mapbox-gl';
import { featureCollection, feature, center, getCoord } from '@turf/turf';
import Map from '@/lib/map';
import DataFetcher from '@/components/charts/DataFetcher';
import TopChart from '@/components/charts/TopChart';
import OriginTrend from '@/components/charts/OriginTrend';
import EthnicityChart from '@/components/charts/EthnicityChart';
import GenderAgeChart from '@/components/charts/GenderAgeChart';
import IncomeChart from '@/components/charts/IncomeChart';

export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  },

  components: {
    DataFetcher,
    TopChart,
    OriginTrend,
    EthnicityChart,
    GenderAgeChart,
    IncomeChart
  },

  data() {
    return {
      map: null,
      hoverStateId: null
    };
  },

  computed: {
    poiCollection() {
      return featureCollection(this.pois.filter((p) => p.label === this.label).map((p) => {
        const { geom, ...properties } = p;
        return feature(geom, properties);
      }));
    },

    hasDemographics() {
      return ['us', 'is'].includes(this.activeIso);
    },

    ...mapState('visualize', ['dates', 'activeIso', 'activeLevel']),
    ...mapGetters('visualize', ['pois', 'filteredDateRange', 'filteredHomeLocations'])
  },

  mounted() {
    this.initMap();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

  methods: {
    initMap() {
      this.map = new Map(this.$refs.poiMap, {
        draw: false,
        eventHandlers: {
          'style.load': async () => {
            this.loadBaseMetaLayers();
          }
        }
      });
    },

    loadBaseMetaLayers() {
      if (this.map) {
        ['poiShapes', 'poiLines'].forEach((layerId) => {
          if (this.map.getLayer(layerId)) {
            this.map.removeLayer(layerId);
          }
        });
        if (this.map.getSource('POIS')) {
          this.map.removeSource('POIS');
        }

        this.map.addSource('POIS', {
          type: 'geojson',
          data: this.poiCollection,
          promoteId: 'id'
        });

        this.map.addLayer({
          id: 'poiShapes',
          type: 'fill',
          source: 'POIS',
          paint: {
            'fill-antialias': true,
            'fill-color': '#FF8606',
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5]
          }
        });

        this.map.addLayer({
          id: 'poiLines',
          type: 'line',
          source: 'POIS',
          paint: {
            'line-color': '#DE7200',
            'line-width': 1,
            'line-opacity': 0.75
          }
        });

        this.poiCollection.features.forEach((poi) => {
          const { geometry, properties: { id, name, label } } = poi;
          const centerOfPoi = getCoord(center(geometry));
          const popup = new Popup({
            closeButton: false,
            closeOnClick: true
          }).setHTML(`
          <div class="v-card v-card--flat v-sheet">
            <div class="v-card__text pa-0">
              <div class="headline">${name}</div>
              <div class="subheading mb-1">${label}</div>
            </div>
          </div>
        `);
          const marker = new Marker({ color: '#0DAADD' }).setLngLat(centerOfPoi).setPopup(popup).addTo(this.map);
          const markerDiv = marker.getElement();
          markerDiv.addEventListener('mouseenter', () => {
            if (this.hoveredStateId) {
              this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
            }
            this.map.setFeatureState({ source: 'POIS', id }, { hover: true });
            this.hoveredStateId = id;
          });
          markerDiv.addEventListener('mouseleave', () => {
            if (this.hoveredStateId) {
              this.map.setFeatureState({ source: 'POIS', id: this.hoveredStateId }, { hover: false });
            }
            this.hoveredStateId = null;
          });
        });

        this.map.centerOn(this.poiCollection, { easing: () => 1 });
      }
    }
  }
};
</script>

<style lang="scss">
  .label-map {
    width: 100%;
    height: 350px;
  }

  .mapboxgl-control-container {
    display: none;
  }

  .mapboxgl-marker:hover {
    cursor: pointer;
  }

  .mapboxgl-marker:hover svg > g > g:nth-child(2) {
    fill: #2F3B44;
  }
</style>
