<template>
  <v-card flat>
    <v-card-title class="headline" v-if="title">{{ title }}</v-card-title>
    <div id="trip-chart" :style="`height: ${this.height}px; width: 100%`"></div>
  </v-card>
</template>

<script>
import d3 from '@/lib/d3';
import { mk } from '@/lib/utils';

const NODE_WIDTH = 24;
const COUNT_SPACE = 50;
const COLOR = '#4E79A7';

export default {
  props: {
    title: String,

    data: {
      type: Object,
      default: () => {}
    },

    width: {
      type: Number,
      default: 600
    },

    height: {
      type: Number,
      default: 300
    }
  },

  data() {
    return {
      svg: null
    };
  },

  computed: {
    labelWidth() {
      return this.width / 6;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const { prior, post } = this.graph();
      this.svg = d3.select('#trip-chart').append('svg').attr('viewBox', [0, 0, this.width, this.height]);
      this.svg.append('svg:defs').selectAll('marker').data(['arrow']).enter().append('svg:marker').attr('id', String).style('fill', COLOR).attr('viewBox', '-5 -5 10 10').attr('refX', -3).attr('refY', 0).attr('markerWidth', 3).attr('markerHeight', 3).attr('orient', 'auto').append('svg:path').attr('d', 'M 0,0 m -5,-5 L 5,0 L -5,5 Z');

      this.drawLinks(prior);
      this.drawLinks(post);
      this.drawPoiNode();
      this.drawNodes(prior);
      this.drawNodes(post);
    },

    drawNodes(nodes) {
      // add in the nodes
      const node = this.svg.append('g').selectAll('.node').data(nodes).enter()
        .append('g')
        .attr('class', 'node');

      // add the circles for the nodes
      node.append('circle')
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => d.y)
        .attr('r', NODE_WIDTH / 2)
        .style('fill', (d) => d.count === '?' ? '#FFFFFF' : COLOR)
        .style('stroke', COLOR);

      // add titles for the nodes
      node.append('foreignObject')
        .attr('width', this.labelWidth)
        .attr('height', 25)
        .attr('x', (d) => d.alignRight ? d.x + NODE_WIDTH : d.x - NODE_WIDTH - this.labelWidth)
        .attr('y', (d) => d.y - (NODE_WIDTH / 2))
        .append('xhtml:body').append('div')
        .attr('style', (d) => `text-align: ${d.alignRight ? 'left' : 'right'}`)
        .attr('class', 'poi-name')
        .text((d) => d.name);
    },

    drawPoiNode() {
      const node = this.svg.append('g').attr('class', 'poi-node');

      node.append('circle')
        .attr('cx', this.width / 2)
        .attr('cy', this.height / 2)
        .attr('r', 36)
        .style('fill', COLOR)
        .style('stroke', COLOR);
    },

    drawLinks(nodes) {
      const link = d3.linkHorizontal()
        .source((d) => d.alignRight ? [this.width / 2, this.height / 2] : [d.x + COUNT_SPACE, d.y])
        .target((d) => d.alignRight ? [d.x - COUNT_SPACE, d.y] : [this.width / 2, this.height / 2]);

      const countSpaceLink = d3.linkHorizontal()
        .source((d) => d.alignRight ? [d.x - COUNT_SPACE, d.y] : [d.x, d.y])
        .target((d) => d.alignRight ? [d.x, d.y] : [d.x + COUNT_SPACE, d.y]);

      this.svg.append('g').selectAll('.main-link').data(nodes).enter()
        .append('path')
        .attr('class', 'main-link')
        .attr('d', link)
        .style('stroke', COLOR)
        .style('fill', 'none')
        .style('stroke-width', 3);

      const countSpace = this.svg.append('g').selectAll('.link').data(nodes).enter().append('g');
      countSpace.append('path')
        .attr('class', 'link')
        .attr('marker-start', (d) => d.alignRight ? 'url(#arrow)' : '')
        .attr('marker-end', (d) => !d.alignRight ? 'url(#arrow)' : '')
        .attr('d', countSpaceLink)
        .style('stroke', COLOR)
        .style('fill', 'none')
        .style('stroke-width', 3);
      countSpace.append('text')
        .attr('x', (d) => d.alignRight ? d.x - ((NODE_WIDTH / 2) + 6) : d.x + ((NODE_WIDTH / 2) + 6))
        .attr('y', (d) => d.y)
        .attr('dy', '-0.5em')
        .attr('text-anchor', (d) => d.alignRight ? 'end' : 'start')
        .attr('class', 'count-text')
        .text((d) => mk(d.count));
    },

    graph() {
      const { prior = [], post = [] } = this.data;
      if (!prior.length) {
        prior.push({
          name: 'No Prior POIs Detected',
          count: '?'
        });
      }
      if (!post.length) {
        post.push({
          name: 'No Post POIs Detected',
          count: '?'
        });
      }
      const PADDING_X = this.labelWidth + NODE_WIDTH;
      const calcPos = (data, { alignRight = false } = {}) => {
        const hasOddNum = data.length % 2 > 0;
        const middleIndex = Math.floor(data.length / 2);
        return data.map((d, i) => {
          const x = alignRight ? this.width - PADDING_X : PADDING_X;
          let y = this.height / 2;
          if (hasOddNum) {
            if (i < middleIndex) {
              y -= NODE_WIDTH * 2 * (middleIndex - i);
            } else if (i > middleIndex) {
              y += NODE_WIDTH * 2 * (i - middleIndex);
            }
          } else if (i < middleIndex) {
            y -= (NODE_WIDTH * 2 * (middleIndex - i)) - NODE_WIDTH;
          } else if (i >= middleIndex) {
            y += (NODE_WIDTH * 2 * (i - 1 || 1)) - NODE_WIDTH;
          }
          return { ...d, x, y, alignRight };
        });
      };
      return {
        prior: calcPos(prior),
        post: calcPos(post, { alignRight: true })
      };
    }
  }
};
</script>

<style lang="scss">
.poi-name {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.0071428571em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.count-text {
  fill: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  letter-spacing: 0.0071428571em;
}
</style>
