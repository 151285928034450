<template>
  <v-container pt-0>
    <v-toolbar flat>
      <v-btn text @click="close">
        <v-icon left>mdi-arrow-left</v-icon>
        <span>{{ $tc('back') }}</span>
      </v-btn>
      <v-spacer />
      <v-btn text :loading="exporting" @click="exportOrder">
        <v-icon left>mdi-download</v-icon>
        <span>{{ $tc('download') }}</span>
      </v-btn>
    </v-toolbar>
    <v-card :loading="pending" outlined>
      <v-card-title class="study-name">
        <v-text-field class="rename-study-input text-body-1" v-model="studyName" v-if="renaming" append-icon="mdi-check" clearable outlined hide-details dense @click:clear="cancelRename" @click:append="rename" />
        <div class="display-1" v-else>{{ study.name }}</div>
        <v-btn class="rename-icon" icon @click="startRenaming" v-show="!renaming">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <heat-map height="525px" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import alert from '@/mixins/alert';
import HeatMap from '@/components/studies/ad/reports/HeatMap';

export default {
  mixins: [alert],

  components: {
    HeatMap
  },

  data() {
    return {
      pending: false,
      exporting: false,
      studyName: '',
      renaming: false
    };
  },

  computed: mapState('visualize', ['study']),

  methods: {
    close() {
      this.reset();
      this.$router.push({ name: 'main' });
    },

    async exportOrder() {
      try {
        const { id, downloadUrl } = this.study;
        if (downloadUrl) {
          window.location = downloadUrl;
        } else {
          this.exporting = true;
          const { data } = await this.$services.orders.export(id);
          window.location = data;
          this.study.downloadUrl = data;
          this.setOrderProperty({ id, key: 'downloadUrl', value: data });
        }
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.exporting = false;
      }
    },

    startRenaming() {
      this.studyName = this.study.name;
      this.renaming = true;
    },

    async rename() {
      this.pending = true;
      try {
        const { id, name } = this.study;
        await this.$services.orders.update(id, { name: this.studyName });
        this.alertSuccess(`${name} renamed to ${this.studyName}`);
        this.setStudyName(this.studyName);
        this.setOrderProperty({ id, key: 'name', value: this.studyName });
        this.cancelRename();
      } catch (error) {
        this.alertError(error, false);
      } finally {
        this.pending = false;
      }
    },

    cancelRename() {
      this.renaming = false;
      this.studyName = this.study.name;
    },

    ...mapActions(['setOrderProperty']),
    ...mapActions('visualize', ['reset', 'setStudyName'])
  }
};
</script>

<style lang="scss">
.rename-icon {
  visibility: hidden;
}
.study-name:hover {
  .rename-icon {
    visibility: visible;
  }
}
.rename-study-input {
  max-width: 33%;
}
</style>
